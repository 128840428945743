import React, { useCallback } from 'react';
import { useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ThumbUp from '@material-ui/icons/ThumbUp';
import { useTranslate, useUpdate, useNotify, useRefresh } from 'react-admin';

/**
 * This custom button demonstrate using useUpdate to update data
 */
const AcceptButton = ({ record, action = 'accept' }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const formState = useFormState();

    const [approve, { loading }] = useUpdate(
      'complaints',
      record.id,
      {
          comment: formState.values.comment,
          status: action === 'close' ? 'resolved' : 'in_progress',
          complaint_manager: formState.values.complaint_manager
      },
      record,
      {
          undoable: true,
          onSuccess: () => {
              notify(
                  'resources.complaints.notification.approved_success',
                  'info',
                  {},
                  true
              );
              refresh();
          },
          onFailure: () => {
              notify(
                  'resources.complaints.notification.approved_error',
                  'warning'
              );
          },
      }
    );

    const handleClick = useCallback(() => {
        if (!formState.valid) {
            if (!formState.values.comment) {
                notify(
                    'pos.comment_required',
                    'warning'
                );
            }
            return;
        }
        approve();
    }, [
        formState.valid,
        approve,
        formState.values.comment,
        notify
    ]);


    return record && (
                record.status === 'new' || record.status === 'in_progress'
        ) ? (
        <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            disabled={loading}
        >
            <ThumbUp
                style={{ paddingRight: '0.5em', color: 'white' }}
            />
            {
                translate('resources.complaints.action.accept')
            }

        </Button>
    ) : (
        <span />
    );
};

AcceptButton.propTypes = {
    record: PropTypes.object,
};

export default AcceptButton;
