import React, { Fragment } from 'react';
import MuiToolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import { SaveButton } from 'react-admin';
import AcceptButton from './AcceptButton';
import RejectButton from './RejectButton';

const useStyles = makeStyles({
    root: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const InvoiceEditToolbar = ({
    basePath,
    handleSubmitWithRedirect,
    invalid,
    record,
    resource,
    saving,
    permissions,
    changed
}) => {
    const classes = useStyles();

    if (permissions.role.type === 'manager' || permissions.role.type === 'validator') {
      return (
          <MuiToolbar className={classes.root}>
              { (record.status === 'pending' || record.status === 'delivery_note_pending' || record.status === 'rejectadmin') && !changed ? (
                  <Fragment>
                      <AcceptButton record={record} resource={'invoices'} />
                      <RejectButton record={record} resource={'invoices'} />
                  </Fragment>
              ) : (record.status === 'accepted' && permissions.role.type === 'manager') && !changed ? (
                  <Fragment>
                      <AcceptButton record={record} action={'account'} resource={'invoices'} />
                      <RejectButton record={record} resource={'invoices'} />
                  </Fragment>
            //   ) : (record.status === 'payed' && permissions.role.type === 'manager') && !changed ? (
            //     <Fragment>
            //         <AcceptButton record={record} action={'account'} resource={'invoices'} />
            //     </Fragment>
              ) : (record.status === 'accounted' && permissions.invoicePayer) && !changed ? (
                <Fragment>
                    <AcceptButton record={record} action={'pay'} resource={'invoices'} />
                </Fragment>
              ) : record.status === 'pending' || record.status === 'delivery_note_pending' ?
              (
                  <Fragment>
                      <SaveButton
                          handleSubmitWithRedirect={handleSubmitWithRedirect}
                          invalid={invalid}
                          saving={saving}
                          redirect="list"
                          submitOnEnter={true}
                      />
                  </Fragment>
              ) : <></>}
          </MuiToolbar>
      );
    } else {
      return <></>;
    }


};

export default InvoiceEditToolbar;
