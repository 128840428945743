import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import {
    Select,
    Checkbox
} from 'final-form-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';

import URL from '../URL';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import Check from '@material-ui/icons/CheckCircle';

import { Notification, useTranslate, useLogin, useNotify, useRedirect } from 'react-admin';

import ValidTaxId from '../components/ValidTaxId';
import ValidBic from '../components/ValidBic';

import Logo from './logos/black/Logo';
import LogoDemios from './logos/black/LogoDemios';
import { lightTheme } from './themes';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'url(/img/fondo1.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    select: {
        marginTop: '1em',
        width: '100%'
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    logo: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    }
}));

const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const Login = ({ location }) => {
    const [loading, setLoading] = useState(false);
    const [signup, setSingup] = useState(false);
    const [organizations, setOrganizations] = useState();
    const [registered, setRegistered] = useState(false);
    const [emailed, setEmailed] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(
        location && (location.hash === '#reset-password') ? true : false
    );

    const [country, setCountry] = React.useState("Spain");
    // const [addressSearch, setAddressSearch] = React.useState();
    // const [address, setAddress] = React.useState();
    const [countries, setCountries] = React.useState();
    const [zipCode, setZipCode] = React.useState();
    const [city, setCity] = React.useState();
    const [province, setProvince] = React.useState();

    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();
    const redirectTo = useRedirect();

    useEffect(() => {
        if (countries) return;
        axios.get(`/regions.json`).then(({ data }) => setCountries(data));
    });

    useEffect(() => {
        if (country && zipCode /*&& addressSearch*/) {
            const countryData = countries.find(c => c.name === country);
            if (!countryData) {
                // setAddress();
                return;
            }
            const getData = setTimeout(() => {
                axios
                // .get(`${URL}/addresses?region=${countryData.code}&postalCode=${zipCode}&address=${addressSearch}`)
                .get(`${URL}/addresses?region=${countryData.code}&postalCode=${zipCode}&address=null`)
                .then(({ data }) => {    
                    if (data && data.result) {
                        if (data.result.verdict && data.result.address && data.result.address.postalAddress) {
                            setCity(data.result.address.postalAddress.locality ?  data.result.address.postalAddress.locality : data.result.address.postalAddress.administrativeArea);
                            setProvince(data.result.address.postalAddress.administrativeArea);
                        }
                        // if (data.result.verdict && data.result.verdict.addressComplete) {
                        //     setCity(data.result.address.postalAddress.locality);
                        //     setProvince(data.result.address.postalAddress.administrativeArea);
                        //     setAddress(data.result.address.formattedAddress);
                        // } else {
                        //     setAddress();
                        // }
                    }
                });
            }, 1000)
    
            return () => clearTimeout(getData);
        }
    }, [country, zipCode, /*addressSearch,*/ countries]);

    const fetchOrganizations = useCallback(async () => {
        // Request organizations.
        axios.get(`${URL}/organizations`)
          .then(function (response) {
            // handle success
            setOrganizations(response.data);
          })
          .catch(function (error) {
            // handle error
          });
    }, []);


    useEffect(() => {
        if (!organizations) {
          fetchOrganizations();
        }
    }, [organizations, fetchOrganizations]);

    const handleSubmit = auth => {
        setLoading(true);
        if (signup) {
          // Request API.
          axios.post(`${URL}/suppliers`, {
                email: auth.email,
                password: auth.password,
                name: auth.name,
                phone: auth.phone,
                business: auth.business,
                taxid: auth.taxid,
                // address: address ? address : "",
                address: auth.address,
                city: city ? city.toUpperCase() : "",
                zipcode: zipCode ? zipCode : "",
                province: province ? province.toUpperCase() : "",
                country: auth.country.toUpperCase(),
                employee: auth.employee,
                payment: auth.payment,
                days: auth.days,
                terms: auth.terms,
                status: "payment_confirm",
                organization: auth.organization,
                bic: auth.bic,
                swift: auth.swift,
                type: auth.type
            })
            .then(response => {
                setLoading(false);
                setRegistered(true);
            })
            .catch(error => {
                if (error.message === 'Request failed with status code 403') {
                    notify(
                        translate('pos.email_exists'),
                        'warning'
                    );
                } else {
                    notify(
                        typeof error === 'string'
                            ? error
                                : typeof error === 'undefined' || !error.message
                                    ? 'ra.auth.sign_in_error'
                                        : error.message,
                        'warning'
                    );
                }
                setTimeout(() => {
                    setLoading(false);
                }, 3000);
            });
        } else if (forgotPassword) {
            if (!emailed) {
                // Request API.
                axios.post(`${URL}/auth/forgot-password`, {
                    email: auth.username,
                }).then(response => {
                    setLoading(false);
                    setEmailed(true);
                }).catch(error => {
                    notify(
                        typeof error === 'string' ? error : typeof error === 'undefined' || !error.message ? 'ra.auth.sign_in_error' : error.message,
                        'warning'
                    );
                    setTimeout(() => setLoading(false), 3000);
                });
            } else if (auth.code) {
                // Request API.
                axios.post(`${URL}/auth/reset-password`, {
                    code: auth.code,
                    password: auth.password,
                    passwordConfirmation: auth.passwordConfirmation,
                }).then(response => {
                    setLoading(false);
                    setTimeout(() => {
                        redirectTo('/');
                    }, 1000);
                }).catch(error => {
                    notify(
                        typeof error === 'string' ? error : typeof error === 'undefined' || !error.message ? 'ra.auth.sign_in_error' : error.message,
                        'warning'
                    );
                    setTimeout(() => setLoading(false), 3000);
                });

            }
        } else {
          login(auth, location.state ? location.state.nextPathname : '/')
              .then(() => setLoading(false))
              .catch(error => {
                    console.log(error);
                  setLoading(false);
                  notify(
                      'ra.auth.sign_in_error',
                      'warning'
                  );
              });
        }
    };

    const validate = values => {
        const errors = {};
        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    const validateForgot = values => {
        const errors = {};
        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        return errors;
    };

    const validateResetPassword = values => {
        const errors = {};
        if (!values.code) {
            errors.code = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        if (!values.passwordConfirmation) {
            errors.passwordConfirmation = translate('ra.validation.required');
        }
        return errors;
    };

    const validateRegister = values => {
        const errors = {};
        if (!values.name) {
            errors.name = translate('ra.validation.required');
        }
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }
        if (!values.phone) {
            errors.phone = translate('ra.validation.required');
        }
        if (!values.business) {
            errors.business = translate('ra.validation.required');
        }
        if (!values.taxid) {
            errors.taxid = translate('ra.validation.required');
        } else if (!ValidTaxId(values.taxid)) {
            errors.taxid = translate('pos.invalidTaxId');
        }
        // if (!address) {
        //     errors.address = translate('ra.validation.required');
        // }
        if (!values.address) {
            errors.address = translate('ra.validation.required');
        }
        if (!city) {
            errors.city = translate('ra.validation.required');
        }
        if (!zipCode) {
            errors.zipcode = translate('ra.validation.required');
        }
        if (!values.country) {
            errors.country = translate('ra.validation.required');
        }
        if (!values.organization) {
            errors.organization = translate('ra.validation.required');
        }
        if (!values.employee) {
            errors.employee = translate('ra.validation.required');
        }
        if (!values.payment) {
            errors.payment = translate('ra.validation.required');
        }
        if (!values.days) {
            errors.days = translate('ra.validation.required');
        }
        if (!values.bic) {
            errors.bic = translate('ra.validation.required');
        } else {
          if (ValidBic(values.bic)) {
            errors.bic = translate('pos.invalid_bic');
          }
        }
        if (!values.swift) {
            errors.swift = translate('ra.validation.required');
        }
        if (!values.terms) {
            errors.terms = translate('ra.validation.required');
        }
        if (!values.type) {
            errors.terms = translate('ra.validation.required');
        }
        return errors;
    };

    // render
    return registered ?
        <div className={classes.main}>
            <Card className={classes.card}>
                <div className={classes.avatar}>
                    <Avatar className={classes.icon}>
                        <Check />
                    </Avatar>
                </div>
                <div className={classes.avatar}>
                    {translate('pos.success')}
                </div>
                <div className={classes.avatar}>
                    <Link href="/">
                        {translate('pos.login_link')}
                    </Link>
                </div>
            </Card>
        </div>
    : emailed ? 
    <Form
        onSubmit={handleSubmit}
        validate={validateResetPassword}
        render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
                <div className={classes.main}>
                    <Card className={classes.card}>
                        <div className={classes.logo}><Logo /></div>
                        <div className={classes.logo}><LogoDemios /></div>
                        <div className={classes.avatar}>
                            <Avatar className={classes.icon}>
                                <LockIcon />
                            </Avatar>
                        </div>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    autoFocus
                                    name="code"
                                    component={renderInput}
                                    label={translate('pos.code')}
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="password"
                                    component={renderInput}
                                    label={translate('pos.choosepassword')}
                                    type="password"
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="passwordConfirmation"
                                    component={renderInput}
                                    label={translate('pos.repassword')}
                                    type="password"
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <CardActions className={classes.actions}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                className={classes.button}
                                fullWidth
                            >
                                {loading && (
                                    <CircularProgress
                                        size={25}
                                        thickness={2}
                                    />
                                )}
                                {translate('pos.submit')}
                            </Button>
                        </CardActions>
                    </Card>
                    <Notification />
                </div>
            </form>
        )}
    />
    : signup ?
    <Form
        onSubmit={handleSubmit}
        validate={validateRegister}
        render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} noValidate>
                <div className={classes.main}>
                    <Card className={classes.card}>
                        <div className={classes.logo}><Logo /></div>
                        <div className={classes.logo}><LogoDemios /></div>
                        <div className={classes.avatar}>
                            <Avatar className={classes.icon}>
                                <LockIcon />
                            </Avatar>
                        </div>
                        <div className={classes.hint}>
                            {translate('pos.signup_link')} {'|'}&nbsp;
                            <Link href="#" onClick={(e) => {
                                  e.preventDefault()
                                  setSingup(false)
                                  setForgotPassword(false)
                                }}
                            >
                                {translate('pos.login_link')}
                            </Link>
                        </div>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    autoFocus
                                    name="email"
                                    component={renderInput}
                                    label={translate('resources.suppliers.fields.email')}
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="name"
                                    component={renderInput}
                                    label={translate('resources.suppliers.fields.name')}
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="phone"
                                    component={renderInput}
                                    label={translate('resources.suppliers.fields.phone')}
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="business"
                                    component={renderInput}
                                    label={translate('resources.suppliers.fields.business')}
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="taxid"
                                    component={renderInput}
                                    label={translate('resources.suppliers.fields.taxid')}
                                    disabled={loading}
                                />
                            </div>

                            <div className={classes.input}>
                                  <Field
                                      name="country"
                                      label={translate('resources.suppliers.fields.country')}
                                      formControlProps={{className: classes.select}}
                                      component={Select}
                                      defaultValue="Spain"
                                  >
                                    {
                                        countries && countries.map(o => {
                                            return (
                                            <MenuItem key={o.code} value={o.name}>
                                                {o.name}
                                            </MenuItem>
                                            )
                                        })
                                    }
                                  </Field>
                            </div>

                            <div className={classes.input}>
                                <TextField
                                    name="zipcode"
                                    label={translate('resources.suppliers.fields.zipcode')}
                                    onChange={event => {
                                        setCountry(values.country)
                                        setZipCode(event.target.value)
                                    }}
                                    fullWidth
                                />
                            </div>

                            <div className={classes.input}>
                                <TextField
                                    name="city"
                                    label={translate('resources.suppliers.fields.city')}
                                    value={city ? city : ""}
                                    disabled
                                    fullWidth
                                />
                            </div>

                            <div className={classes.input}>
                                <Field
                                    name="address"
                                    component={renderInput}
                                    label={translate('resources.suppliers.fields.address')}
                                    disabled={loading}
                                />
                            </div>

                            {/* <div className={classes.input}>
                                <TextField
                                    name="addressSearch"
                                    label={translate('resources.suppliers.fields.addressSearch')}
                                    onChange={event => {
                                        setCountry(values.country)
                                        setAddressSearch(event.target.value)
                                    }}
                                    fullWidth
                                />
                            </div>
                            <div className={classes.input}>
                                <TextField
                                    name="address"
                                    label={translate('resources.suppliers.fields.address')}
                                    value={address ? address : ""}
                                    disabled
                                    fullWidth
                                />
                            </div> */}

                            {/* <div className={classes.input}>
                                  <Field
                                      name="country"
                                      label={translate('resources.suppliers.fields.country')}
                                      formControlProps={{className: classes.select}}
                                      component={Select}
                                  >
                                  {
                                      [
                                          <MenuItem key="es" value="España">
                                              España
                                          </MenuItem>,
                                          <MenuItem key="eu" value="Unión Europea">
                                              Unión Europea
                                          </MenuItem>,
                                          <MenuItem key="other" value="Otro">
                                              Otro
                                          </MenuItem>
                                      ]
                                  }
                                  </Field>
                            </div> */}
                            {/* {
                                values.country === 'España' ?
                                <>
                                    <div className={classes.input}>
                                        <TextField
                                            name="zipcode"
                                            label={translate('resources.suppliers.fields.zipcode')}
                                            onChange={(event) => setZipCode(event.target.value)}
                                            disabled={values.country !== 'España'}
                                            fullWidth
                                        />
                                    </div>
                                    <div className={classes.input}>
                                        <TextField
                                            name="city"
                                            label={translate('resources.suppliers.fields.city')}
                                            value={zipData ? zipData.municipio_nombre : ""}
                                            disabled
                                            fullWidth
                                        />
                                    </div>
                                </> :
                                <>
                                
                                </>
                            } */}
                            <div className={classes.input}>
                                  <Field
                                      name="type"
                                      label={translate('resources.suppliers.fields.type')}
                                      formControlProps={{className: classes.select}}
                                      component={Select}
                                  >
                                  {
                                      [
                                          <MenuItem key="supplier" value="supplier">
                                              {translate('resources.suppliers.fields.supplier')}
                                          </MenuItem>,
                                          <MenuItem key="creditor" value="creditor">
                                              {translate('resources.suppliers.fields.creditor')}
                                          </MenuItem>
                                      ]
                                  }
                                  </Field>
                            </div>
                            <div className={classes.input}>
                                  <Field
                                      name="organization"
                                      label={translate('resources.organizations.fields.organization')}
                                      formControlProps={{className: classes.select}}
                                      component={Select}
                                  >
                                  {
                                      organizations && organizations.map(o => {
                                        return (<MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)
                                      })
                                  }
                                  </Field>
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="employee"
                                    component={renderInput}
                                    label={translate('resources.suppliers.fields.employeeRegister')}
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="payment"
                                    label={translate('resources.suppliers.fields.paymentRegister')}
                                    formControlProps={{className: classes.select}}
                                    component={Select}
                                >
                                {
                                    ['confirming','transfer','note','bill','order'].map((type, index) => {
                                    return (
                                        <MenuItem key={index} value={type}>
                                            {translate(`resources.suppliers.payments.${type}`)}
                                        </MenuItem>
                                    )
                                    })
                                }
                                </Field>
                            </div>
                            <div className={classes.input}>
                            <Field
                                    name="days"
                                    label={translate('resources.suppliers.fields.days')}
                                    formControlProps={{className: classes.select}}
                                    component={Select}
                                >
                                {
                                    ['30', '45', '60', '90', '120', '150', '180'].map((day, index) => {
                                    return (
                                        <MenuItem key={index} value={day}>
                                            {day}
                                        </MenuItem>
                                    )
                                    })
                                }
                                </Field>
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="bic"
                                    component={renderInput}
                                    label={translate('resources.suppliers.fields.bic')}
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="swift"
                                    component={renderInput}
                                    label={translate('resources.suppliers.fields.swift')}
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <FormControlLabel
                                    control={
                                      <Field
                                          name="terms"
                                          type="checkbox"
                                          component={Checkbox}
                                      />
                                    }
                                    label={
                                        <Link
                                            href="https://camposcorporacion.com/politica-de-privacidad"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            {translate('resources.suppliers.fields.terms')}
                                        </Link>

                                     }
                                />
                            </div>
                        </div>
                        <CardActions className={classes.actions}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                className={classes.button}
                                fullWidth
                            >
                                {loading && (
                                    <CircularProgress
                                        size={25}
                                        thickness={2}
                                    />
                                )}
                                {translate('pos.signup_link')}
                            </Button>
                        </CardActions>
                    </Card>
                    <Notification />
                </div>
            </form>
        )}
    />
    : forgotPassword ? 
    <Form
        onSubmit={handleSubmit}
        validate={validateForgot}
        render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
                <div className={classes.main}>
                    <Card className={classes.card}>
                        <div className={classes.logo}><Logo /></div>
                        <div className={classes.logo}><LogoDemios /></div>
                        <div className={classes.avatar}>
                            <Avatar className={classes.icon}>
                                <LockIcon />
                            </Avatar>
                        </div>
                        <div className={classes.hint}>
                            <Link href="#" onClick={(e) => {
                                    e.preventDefault()
                                    setSingup(false)
                                    setForgotPassword(false)
                                }}
                            >
                                {translate('pos.login_link')}
                            </Link>
                            &nbsp;{'|'}&nbsp;
                            <Link href="#" onClick={(e) => {
                                    e.preventDefault()
                                    setSingup(true)
                                    setForgotPassword(false)
                                }}
                            >
                                {translate('pos.signup_link')}
                            </Link>
                        </div>
                        <div className={classes.hint}>
                            <small>{translate('pos.forgot_password')}</small>
                        </div>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    autoFocus
                                    name="username"
                                    component={renderInput}
                                    label={translate('pos.email')}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <CardActions className={classes.actions}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                className={classes.button}
                                fullWidth
                            >
                                {loading && (
                                    <CircularProgress
                                        size={25}
                                        thickness={2}
                                    />
                                )}
                                {translate('pos.submit')}
                            </Button>
                        </CardActions>
                    </Card>
                    <Notification />
                </div>
            </form>
        )}
    /> :
    <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
                <div className={classes.main}>
                    <Card className={classes.card}>
                        <div className={classes.logo}><Logo /></div>
                        <div className={classes.logo}><LogoDemios /></div>
                        <div className={classes.avatar}>
                            <Avatar className={classes.icon}>
                                <LockIcon />
                            </Avatar>
                        </div>
                        <div className={classes.hint}>
                            {translate('pos.login_link')} {'|'}&nbsp;
                            <Link href="#" onClick={(e) => {
                                  e.preventDefault()
                                  setSingup(true)
                                  setForgotPassword(false)
                                }}
                            >
                                {translate('pos.signup_link')}
                            </Link>
                        </div>
                        <div className={classes.hint}>
                            <Link href="#" onClick={(e) => {
                                  e.preventDefault()
                                  setForgotPassword(true)
                                }}
                            >
                                <small>{translate('pos.forgot_password')}</small>
                            </Link>
                        </div>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    autoFocus
                                    name="username"
                                    component={renderInput}
                                    label={translate('pos.email')}
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="password"
                                    component={renderInput}
                                    label={translate('ra.auth.password')}
                                    type="password"
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <CardActions className={classes.actions}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                className={classes.button}
                                fullWidth
                            >
                                {loading && (
                                    <CircularProgress
                                        size={25}
                                        thickness={2}
                                    />
                                )}
                                {translate('ra.auth.sign_in')}
                            </Button>
                        </CardActions>
                    </Card>
                    <Notification />
                </div>
            </form>
        )}
    />

};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = props => (
    <ThemeProvider theme={createTheme(lightTheme)}>
        <Login {...props} />
    </ThemeProvider>
);

export default LoginWithTheme;
