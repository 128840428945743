import React, { useState, useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { SaveButton, useCreate, useRedirect, useNotify } from 'react-admin';
import axios from 'axios';

import URL from '../URL';

const SaveAndUploadButton = props => {
    const [loading, setLoading] = useState(false);
    const redirectTo = useRedirect();
    const notify = useNotify();
    // get the form state
    const formState = useFormState();

    const upload = async (recordId) => {
        return new Promise((resolve, reject) => {
            // create an HTML standar Form Data
            const formData = new FormData();
            // append avatar
            formData.append('files', formState.values.avatar.rawFile);
            formData.append('ref', "organization");
            formData.append('refId', recordId);
            formData.append('source', "organizations");
            formData.append('field', "avatar");

            // make the request
            axios
                .post(`${URL}/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                })
                .then(response => {
                    notify(
                        'ra.notification.created',
                        'info',
                        {},
                        true
                    );
                    setLoading(false);
                    redirectTo('/organizations');
                })
                .catch(error => {
                    notify(
                        `pos.upload_failed`,
                        'warning'
                    );
                    setLoading(false);
                    resolve();
                });
        });
    }

    const [ create ] = useCreate(
      'organizations',
      { ...formState.values },
      {
          undoable: false,
          onSuccess: ({ data: newRecord }) => {
              if (formState.values.avatar) {
                notify(
                    'pos.uploading',
                    'info',
                    {},
                    true
                );
                upload(newRecord.id);
              } else {
                notify(
                    'ra.notification.created',
                    'info',
                    {},
                    true
                );
                setLoading(false);
                redirectTo('/organizations');
              }
          },
          onFailure: () => {
              notify(
                  'resources.organizations.notification.created_error',
                  'warning'
              );
              setLoading(false);
          },
      }
    );

    const handleClick = useCallback( () => {
        if (formState.valid) {
            setLoading(true);
            create();
        }
    }, [
        formState.valid,
        create,
    ]);

    return <SaveButton {...props} saving={loading} handleSubmitWithRedirect={handleClick} />;
};


export default SaveAndUploadButton;
