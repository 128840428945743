import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import axios from 'axios';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
// import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
// import Link from '@material-ui/core/Link';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';

import { Notification, useTranslate, useNotify, useLogout, useRedirect } from 'react-admin';

import { lightTheme } from '../layout/themes';

import URL from '../URL';


const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        // background: 'url(https://source.unsplash.com/random/1600x900)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 400,
        maxWidth: 400,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
        padding: '10px'
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    select: {
        marginTop: '1em',
        width: '100%'
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
}));

const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const ResetPassword = ({ location }) => {
    const [loading, setLoading] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const logout = useLogout();
    const redirectTo = useRedirect();

    const sendCode = auth => {
        setLoading(true);

        // Request API.
        axios.post(`${URL}/auth/forgot-password`, {
              email: auth.email,
          })
          .then(response => {
              setLoading(false);
              setCodeSent(true);
              notify(
                  `pos.code_sent`,
                  'info',
                  {},
                  true
              );
          })
          .catch(error => {
              setLoading(false);
              notify(
                  typeof error === 'string'
                      ? error
                      : typeof error === 'undefined' || !error.message
                      ? 'ra.auth.sign_in_error'
                      : error.message,
                  'warning'
              );
          });

    };

    const handleSubmit = auth => {
        setLoading(true);

        // Request API.
        axios.post(`${URL}/auth/reset-password`, {
              code: auth.code,
              password: auth.password,
              passwordConfirmation: auth.repassword
          })
          .then(response => {
              setLoading(false);
              notify(
                  `pos.password_changed`,
                  'info',
                  {},
                  true
              );
              setTimeout(() => {
                  redirectTo('/');
                  logout();
              }, 3000);
          })
          .catch(error => {
              setLoading(false);
              notify(
                  typeof error === 'string'
                      ? error
                      : typeof error === 'undefined' || !error.message
                      ? 'ra.auth.sign_in_error'
                      : error.message,
                  'warning'
              );
          });

    };

    const validateEmail = values => {
        const errors = {};
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }
        return errors;
    };

    const validatePasswords = values => {
        const errors = {};
        if (!values.code) {
            errors.code = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        if (values.password && values.password.length < 6) {
          errors.password = translate('pos.tooshort');
        }
        if (values.password !== values.repassword) {
            errors.repassword = translate('pos.notmatch');
        }
        return errors;
    };

    // render
    return !codeSent ? (
      <Form
          onSubmit={sendCode}
          validate={validateEmail}
          render={({ handleSubmit: sendCode }) => (
              <form onSubmit={sendCode} noValidate>
                  <div className={classes.main}>
                    <Card className={classes.card}>
                          <div className={classes.avatar}>
                              <Avatar className={classes.icon}>
                                  <LockIcon />
                              </Avatar>
                          </div>
                          <div className={classes.hint}>
                              {translate('pos.send_code_desc')}
                          </div>
                          <div className={classes.form}>
                            <div className={classes.input}>
                                  <Field
                                      name="email"
                                      component={renderInput}
                                      label={translate('pos.email')}
                                      type="email"
                                      disabled={loading}
                                  />
                              </div>
                          </div>
                          <CardActions className={classes.actions}>
                              <Button
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  disabled={loading}
                                  className={classes.button}
                                  fullWidth
                              >
                                  {translate('pos.send_code')}
                              </Button>
                          </CardActions>
                      </Card>
                      <Notification />
                  </div>
              </form>
          )}
      />
    ) : (
        <Form
        onSubmit={handleSubmit}
        validate={validatePasswords}
        render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
                <div className={classes.main}>
                    <Card className={classes.card}>
                        <div className={classes.avatar}>
                            <Avatar className={classes.icon}>
                                <LockIcon />
                            </Avatar>
                        </div>
                        <div className={classes.hint}>
                            {translate('pos.reset_password')}
                        </div>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    name="code"
                                    component={renderInput}
                                    label={translate('pos.email_code')}
                                    type="text"
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="password"
                                    component={renderInput}
                                    label={translate('ra.auth.password')}
                                    type="password"
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="repassword"
                                    component={renderInput}
                                    label={translate('pos.repassword')}
                                    type="password"
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <CardActions className={classes.actions}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                className={classes.button}
                                fullWidth
                            >
                                {translate('pos.submit')}
                            </Button>
                        </CardActions>
                    </Card>
                    <Notification />
                </div>
            </form>
        )}
      />
    );
};

ResetPassword.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in ResetPassword won't get
// the right theme
const ResetPasswordWithTheme = props => (
    <ThemeProvider theme={createTheme(lightTheme)}>
        <ResetPassword {...props} />
    </ThemeProvider>
);

export default ResetPasswordWithTheme;
