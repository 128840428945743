import React from 'react';
import {
    AutocompleteInput,
    DateInput,
    Filter,
    ReferenceInput,
    TextInput
} from 'react-admin';

const AlbaranFilter = props => {
    return (
        <Filter {...props}>
            <TextInput source="q" label="resources.albaranes.filters.ot" alwaysOn />
            <ReferenceInput
              source="user"
              reference="users"
              filter={{ foreman: true }}
            >
                <AutocompleteInput
                    optionText={choice =>
                        choice && choice.id ? `${choice.name}` : ""
                    }
                />
            </ReferenceInput>
            <ReferenceInput
              source="organization"
              reference="organizations"
            >
                <AutocompleteInput
                    optionText={choice =>
                        choice && choice.id ? `${choice.name}` : ""
                    }
                />
            </ReferenceInput>
            <DateInput source="createdAt_gte" label="resources.albaranes.filters.createdAt_gte" />
            <DateInput source="createdAt_lte" label="resources.albaranes.filters.createdAt_lte" />
        </Filter>
    );
};

export default AlbaranFilter;
