import React from 'react';
import { ReferenceField } from 'react-admin';

import FullNameField from '../components/FullNameField';

const UserReferenceField = props => {
  return (
    props.record.approvedBy && props.record.approvedBy.id ?
        <ReferenceField source="approvedBy.id" reference="users" {...props}>
            <FullNameField />
        </ReferenceField>
    : <>-</>
)};

UserReferenceField.defaultProps = {
    source: 'approvedBy',
    label: 'resources.suppliers.fields.approvedBy',
    addLabel: true,
};

export default UserReferenceField;
