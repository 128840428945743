import React from 'react';

const LogoDemios = props => (
    <svg
        width={76}
        height={30}
        viewBox="0 0 42.333 10.319"
        {...props}
    >
            <g
                aria-label="~DEMOS~"
                style={{
                    lineHeight: 1.25,
                }}
                fontWeight={400}
                fontSize={7.056}
                fontFamily="Verdana"
                letterSpacing={0}
                wordSpacing={0}
                strokeWidth={0.265}
                fill="#000"
            >
                <path
                    d="m 2.2708247,7.1381534 h 1.5240384 q 0.582098,0 1.0795272,-0.2010884 Q 5.3718195,6.725393 5.7422455,6.3655506 6.1232551,5.9951246 6.3243435,5.508279 6.5360156,5.0108498 6.5360156,4.4287518 q 0,-0.5715144 -0.2116721,-1.0689436 Q 6.1232551,2.862379 5.7422455,2.5025366 5.3718195,2.1321106 4.8743903,1.9310222 4.3769611,1.7193502 3.7948631,1.7193502 H 2.2708247 Z M 1.1595467,8.1330118 V 0.72449179 h 2.6141492 q 0.8361044,0 1.5452056,0.28575721 0.7091012,0.2751736 1.2382813,0.7726028 0.52918,0.4974292 0.8255208,1.1747796 0.2963408,0.6773504 0.2963408,1.4711204 0,0.8043536 -0.2963408,1.481704 Q 7.0969464,6.5772226 6.5677664,7.0746518 6.0385863,7.572081 5.3189015,7.8578382 4.6098003,8.1330118 3.7736959,8.1330118 Z"
                    id="path8484" />
                <path
                    d="M 8.8538164,8.1330118 V 0.72449179 H 14.314954 V 1.698183 H 9.9650944 V 3.920739 H 12.822666 V 4.8626794 H 9.9650944 v 2.2966412 h 4.3921936 v 0.9736912 z"
                    id="path8486" />
                <path
                    d="M 15.5003,8.1330118 V 0.72449179 h 1.492288 l 2.317808,4.74145281 2.317809,-4.74145281 h 1.481704 V 8.1330118 H 22.062132 V 2.0791926 l -2.762319,5.5246392 -2.76232,-5.5246392 v 6.0538192 z"
                    id="path8488" />
                <path
                    d="M 24.612759,8.1330118 V 0.72449179 h 1.111278 V 8.1330118 Z"
                    id="path8490" />
                <path
                    d="m 30.772407,8.2494314 q -0.804353,0 -1.502871,-0.2857572 Q 28.571018,7.6673334 28.031255,7.148737 27.502075,6.619557 27.205734,5.931623 26.919977,5.2331054 26.919977,4.4287518 q 0,-0.8043536 0.285757,-1.4922876 0.296341,-0.6985176 0.825521,-1.217114 0.539763,-0.52918 1.238281,-0.81493721 0.698518,-0.2963408 1.502871,-0.2963408 0.804354,0 1.502871,0.2963408 0.709102,0.28575721 1.238282,0.81493721 0.52918,0.5185964 0.82552,1.217114 0.296341,0.687934 0.296341,1.4922876 0,0.8043536 -0.296341,1.5028712 -0.29634,0.687934 -0.82552,1.217114 -0.52918,0.5185964 -1.238282,0.8149372 -0.698517,0.2857572 -1.502871,0.2857572 z m 0.01058,-1.0266092 q 0.571514,0 1.05836,-0.211672 0.497429,-0.211672 0.867855,-0.582098 0.370426,-0.3810096 0.582098,-0.8890224 0.211672,-0.5185964 0.211672,-1.111278 0,-0.6032652 -0.211672,-1.111278 Q 33.079632,2.809461 32.709206,2.439035 32.33878,2.0580254 31.841351,1.8463534 q -0.486846,-0.211672 -1.05836,-0.211672 -0.582098,0 -1.079527,0.211672 -0.49743,0.211672 -0.867856,0.5926816 -0.370426,0.370426 -0.582098,0.8784388 -0.201088,0.5080128 -0.201088,1.1006944 0,0.6032652 0.201088,1.1218616 0.211672,0.5080128 0.582098,0.8890224 0.370426,0.370426 0.867856,0.582098 0.497429,0.211672 1.079527,0.211672 z"
                    id="path8492" />
                <path
                    d="m 38.318506,8.2388478 q -0.889022,0 -1.714543,-0.3492588 -0.825521,-0.3492588 -1.460537,-0.9842748 l 0.677351,-0.7831864 q 0.603265,0.5926816 1.227697,0.8784388 0.635016,0.2857572 1.32295,0.2857572 0.539764,0 0.941941,-0.1481704 0.41276,-0.158754 0.635016,-0.4445112 0.222255,-0.2857572 0.222255,-0.6455996 0,-0.4974292 -0.349259,-0.7620192 -0.349258,-0.26459 -1.153612,-0.4021768 L 37.429484,4.6827582 Q 36.413458,4.502837 35.905445,4.026575 35.408016,3.550313 35.408016,2.756543 q 0,-0.635016 0.338675,-1.111278 0.338676,-0.4868456 0.952524,-0.75143561 0.624433,-0.26459 1.43937,-0.26459 0.804354,0 1.576956,0.26459 0.783187,0.25400641 1.407619,0.74085201 l -0.613849,0.846688 q -1.195946,-0.9101896 -2.423644,-0.9101896 -0.486846,0 -0.846688,0.1375868 -0.359842,0.1375868 -0.560931,0.3915932 -0.201088,0.2434228 -0.201088,0.5715144 0,0.4550948 0.306924,0.6985176 0.306925,0.2328392 1.016026,0.3386752 l 1.195947,0.2010884 q 1.174779,0.1905048 1.725126,0.687934 0.550348,0.4974292 0.550348,1.3652844 0,0.6773504 -0.370426,1.1959468 -0.370426,0.5080128 -1.037193,0.79377 -0.666767,0.2857572 -1.545206,0.2857572 z"
                    id="path8494" />
            </g>
    </svg>
);

export default LogoDemios;
