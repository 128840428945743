import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    DateInput,
    Filter,
    SearchInput,
    useTranslate,
    SelectInput,
    NumberInput,
} from 'react-admin';
import { makeStyles, Chip } from '@material-ui/core';
import URL from '../URL';

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};


const TicketFilter = props => {
    const user = JSON.parse(localStorage.getItem('user'));
    const translate = useTranslate();
    const [organizations, setOrganizations] = useState();

    useEffect(() => {
        axios.get(`${URL}/organizations/`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
          }).then( res => {
            setOrganizations(res.data.map(o => {
                    return { id: o.id, name: o.name };
                }));
          });
    }, [])


    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <SelectInput
                source="status"
                choices={[
                    { id: 'accepted', name: translate("resources.tickets.status.accepted") },
                    { id: 'pending', name: translate("resources.tickets.status.pending") },
                    { id: 'rejected', name: translate("resources.tickets.status.rejected") },
                    { id: 'paid', name: translate("resources.tickets.status.paid") },
                    { id: 'telematel', name: translate("resources.tickets.status.telematel") },
                ]}
            />
            <QuickFilter source="employee" label="resources.tickets.my_tickets" defaultValue={user.name} />
            <QuickFilter source="user" label="resources.tickets.validate" defaultValue={user.id} />
            <QuickFilter source="registrar" label="resources.tickets.filter.registrar" defaultValue={user.id} />
            <QuickFilter source="payer" label="resources.tickets.not_payed" defaultValue={user.id} />

            <SelectInput
                source="paymentMethod"
                choices={[
                    { id: 'card_company', name: translate("resources.tickets.paymentMethod.card_company") },
                    { id: 'card_personal', name: translate("resources.tickets.paymentMethod.card_personal") },
                    { id: 'card_cepsa', name: translate("resources.tickets.paymentMethod.card_cepsa") },
                    { id: 'invoice_hotel', name: translate("resources.tickets.paymentMethod.invoice_hotel") },
                ]}
            />

            <SelectInput
                source="expenseType"
                choices={[
                    { id: 'company_invoice', name: translate("resources.tickets.expenseType.company_invoice") },
                    { id: 'other', name: translate("resources.tickets.expenseType.other") },
                ]}
            />

            <SelectInput
                source="organization"
                choices={organizations}
                label="resources.tickets.fields.organization"
            />

            <NumberInput source="amount_gte" label="resources.tickets.amount_start" />
            <NumberInput source="amount_lte" label="resources.tickets.amount_end" />

            <DateInput source="invoiceDate_gte" label="resources.tickets.invoice_date_start" />
            <DateInput source="invoiceDate_lte" label="resources.tickets.invoice_date_end" />
            <DateInput source="date_gte" label="resources.tickets.date_start" />
            <DateInput source="date_lte" label="resources.tickets.date_end" />
        </Filter>
    );
};

export default TicketFilter;
