import React from 'react';
import { Datagrid, DateField, TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ComplaintStatusField from './ComplaintStatusField';
import ManagerReferenceField from './ManagerReferenceField';
import ComplaintCategoryField from './ComplaintCategoryField';

import rowStyle from '../components/rowStyle';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const ComplaintListDesktop = ({ permissions, ...props }) => {
    const classes = useListStyles();
    
    return (
        <Datagrid
            rowClick="edit"
            rowStyle={rowStyle}
            classes={{ headerRow: classes.headerRow }}
            optimized
            {...props}
        >
            <TextField source='id'/>
            <DateField source="date" />
            <ComplaintStatusField source="status" />
            <ComplaintCategoryField source="category" />
            <ManagerReferenceField link={false} />
        </Datagrid>
    );
};

export default ComplaintListDesktop;
