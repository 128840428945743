import React from 'react';
import { ReferenceField } from 'react-admin';

import FullNameField from '../components/FullNameField';

const UserReferenceField = props => {
  return (
    props.record.user ?
        <ReferenceField source="user.id" reference="users" {...props}>
            <FullNameField />
        </ReferenceField>
    : <>-</>
)};

UserReferenceField.defaultProps = {
    source: 'user.id',
    label: 'resources.budgets.fields.user',
    addLabel: true,
};

export default UserReferenceField;
