// Colores Campos
// 221C35 oscuro
// 00A98F verde claro

export const darkTheme = {
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
        primary: {
            light: '#07A990',
            main: '#07A990',
            dark: '#221C35',
            contrastText: '#fff',
        },
        secondary: {
            light: '#07A990',
            main: '#221C35',
            dark: '#221C35',
            contrastText: '#fff',
        },
    },
    overrides: {
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiTableSortLabel: {
            root: {
                color: '#07A990',
            },
        },

    },

};

export const lightTheme = {
    palette: {
        primary: {
            light: '#07A990',
            main: '#07A990',
            dark: '#221C35',
            contrastText: '#fff',
        },
        secondary: {
            light: '#07A990',
            main: '#221C35', // Original(283593) 221C35
            dark: '#221C35',
            contrastText: '#fff',
        },
    },
    overrides: {
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiTableSortLabel: {
            root: {
                color: '#07A990',
            },
        },
        MuiTableHead: {
            root: {
                backgroundColor: '#07A990'
            },
        },
    },
};
