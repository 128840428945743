import React from 'react';
import {
    useEditController,
    useTranslate,
    SimpleForm,
    TextField,
    required,
    ReferenceInput,
    AutocompleteInput,
    RichTextField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {
    Typography,
    IconButton,
    Card,
    CardHeader,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import ComplaintEditToolbar from './ComplaintEditToolbar';
import ComplaintCategoryField from './ComplaintCategoryField';
import ComplaintStatusField from './ComplaintStatusField';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
    card: {
        margin: '0 0 0em 0em',
        width: '100%',
    },
    cardHeader: {
        alignItems: 'flex-start',
    },
    clamp: {
        // display: '-webkit-box',
        // '-webkit-line-clamp': 3,
        // '-webkit-box-orient': 'vertical',
        // overflow: 'hidden',
    },
}));

const ComplaintEdit = ({ onCancel, permissions, ...props }) => {
    const classes = useStyles();
    const controllerProps = useEditController(props);
    const translate = useTranslate();
    if (!controllerProps.record) {
        return null;
    }
    
    const record = controllerProps.record;

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6">
                    {translate('resources.invoices.detail')}
                </Typography>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <SimpleForm
                className={classes.form}
                basePath={controllerProps.basePath}
                record={record}
                save={controllerProps.save}
                version={controllerProps.version}
                redirect="list"
                resource="complaints"
                toolbar={<ComplaintEditToolbar permissions={permissions}/>}
            >
                <ComplaintCategoryField formClassName={classes.inlineField} source="category" />
                <ComplaintStatusField formClassName={classes.inlineField} source="status" />
                <TextField formClassName={classes.inlineField} source="date" />
                {
                    permissions.complaintManager && record.complaint_manager && record.status !== 'resolved' ?
                        <ReferenceInput
                            source="complaint_manager.id"
                            reference="users"
                            label="resources.users.fields.user"
                            filterToQuery={ () => {
                                return ({ complaintManager: true });
                            } }
                            fullWidth
                        >
                            <AutocompleteInput
                                optionText={choice =>
                                    choice && choice.id ? `${choice.name}` : ""
                                }
                            />
                        </ReferenceInput>
                    : <></>
                }
                <TextField source="description" />
                {
                    record.status !== 'resolved' ?
                        <>
                            <TextField source="" label={translate('resources.complaints.fields.commento')} />
                            <RichTextInput
                                source="comment"
                                label={translate('resources.complaints.fields.comment')}
                                maxRows={25}
                                multiline
                                fullWidth
                                validate={required()}
                            />
                        </>
                    : <></>

                }
            </SimpleForm>
            {
                record.comments.length ?
                    <div className={classes.title}>
                        <Typography variant="h6">
                            {translate('resources.complaints.fields.comments')}
                        </Typography>
                    </div>
                : <></>
            }

            {record.comments
                .sort((d, f) => d.createdAt < f.createdAt)
                .map(comment =>
                    (
                        <Card className={classes.card} key={comment.id} >
                            <CardHeader
                                className={classes.cardHeader}
                                title={
                                    <Typography variant="h6">
                                        {comment.author ? record.complaint_manager?.name : translate('pos.roles.employee')}
                                    </Typography>
                                }
                                subheader={
                                    <>
                                        <Typography variant="body2">{new Date(comment.createdAt).toLocaleString()}</Typography>
                                        <RichTextField record={comment} source="comment" />
                                    </>
                                }
                            />
                        </Card>
                    )
            )}
        </div>
    );
};


export default ComplaintEdit;
