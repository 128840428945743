import React from 'react';
import { ReferenceField } from 'react-admin';

import FullNameField from '../components/FullNameField';

const ManagerReferenceField = props => {
  return (
    props.record.complaint_manager ?
        <ReferenceField source="complaint_manager.id" reference="users" {...props}>
            <FullNameField />
        </ReferenceField>
    : <>-</>
)};

ManagerReferenceField.defaultProps = {
    source: 'complaint_manager.id',
    label: 'resources.complaints.fields.complaint_manager',
    addLabel: true,
};

export default ManagerReferenceField;
