import React from 'react';
import { Datagrid, TextField, DateField, NumberField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import rowStyle from '../components/rowStyle';
import UserReferenceField from '../users/UserReferenceField';
// import SupplierReferenceField from '../suppliers/SupplierReferenceField';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const OfferListDesktop = props => {
    const classes = useListStyles();
    return (
        <Datagrid
            rowClick="edit"
            rowStyle={rowStyle}
            classes={{ headerRow: classes.headerRow }}
            optimized
            {...props}
        >
            <DateField source="createdAt" locales="es-ES" />
            <UserReferenceField label="resources.budgets.fields.user" link={false} />
            {/* <SupplierReferenceField link={false} /> */}
            <TextField source="supplierName" />
            <NumberField source="amount" options={{ style: 'currency', currency: 'EUR' }} />
        </Datagrid>
    );
};

export default OfferListDesktop;
