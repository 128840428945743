import React, { useState, useCallback, useEffect, Fragment } from 'react';
import axios from 'axios';
import {
    Create,
    NumberInput,
    TextInput,
    SelectInput,
    useTranslate,
    RadioButtonGroupInput,
    required,
    SimpleForm
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import OfferReferenceInput from './OfferReferenceInput';

import URL from '../URL';

const devOrg = {"key": "5e315dfb4a3c051fbd845829","id": "5e315dfb4a3c051fbd845829","name": "Campos Obras y Proyectos - Pruebas","nameNumber": 3,"officeNumber": 0,"purchaseLessThan": 2000,"purchaseMoreThan": 10000};
const manualItems = [{"key": "STOCK PROYECTO","id": "STOCK PROYECTO","name": "STOCK PROYECTO","coste": 0,"venta": 0}];

const styles = { radio: { width: '20em' } };
const useStyles = makeStyles(styles);

const PurchaseCreate = ({permissions, ...props}) => {
    const dev = window.location.origin === 'http://localhost:3000';
    const classes = useStyles();
    const translate = useTranslate();
    const [loading, setLoading] = useState(true);
    const [organizations, setOrganizations] = useState();
    const [organization, setOrganization] = useState();
    const [ot, setOt] = useState();
    const [items, setItems] = useState();
    const [item, setItem] = useState();
    const [type, setType] = useState();

    const validatePurchase = values => {
        const errors = {};
        return errors;
    };

    useEffect(() => {
        if (dev) {
            setOrganizations([devOrg]);
            return;
        }
        async function fetchData() {
            // Request organizations
            const { data } = await axios.get(`${URL}/organizations/`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            });
            setOrganizations(data.map(o => ({
                    key: o.id, id: o.id, name: `${o.name} - ${o.office}`,
                    nameNumber: o.nameNumber, officeNumber: o.officeNumber,
                    purchaseLessThan: o.purchaseLessThan,
                    purchaseMoreThan: o.purchaseMoreThan
                })
            ));
            setLoading(false)
          }
          fetchData();
    }, [dev]);

    const fetchItems = useCallback( async (organization, cod_obra) => {
        if (dev) {
            setItems(manualItems);
            return;
        }
        let data;
        setOt(null);
        setItems(null);
        if (organization && (cod_obra.length === 7 || cod_obra.length === 14)) {
            setOt(cod_obra);
            try {
                const response = await axios.get(`${URL}/items?cod_ent=${organization.nameNumber}&cod_del=${organization.officeNumber}&cod_obra=${cod_obra}`, {
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }});
                
                // get max for simulating a tree using spaces
                const max = Math.max.apply(Math, response.data.map(o => parseInt(o.niv_capit)));
                data = response.data
                .sort((a, b) => a.niv_capit >= b.niv_capit ? 1 : -1)
                .sort((a, b) => a.cod_capit >= b.cod_capit ? 1 : -1)
                .map(d => {
                  const ss = ' '.repeat(parseInt(d.niv_capit));
                  const se = ' '.repeat(max - parseInt(d.niv_capit));
                  return {
                    key: `${d.cod_capit}_${d.des_capit}`,
                    id: `${d.cod_capit}_${d.des_capit}`,
                    name: `${ss}${d.cod_capit}${se} - ${d.des_capit}`,
                    coste: parseFloat(d.imp_coste.toFixed(2)),
                    venta: parseFloat(d.imp_venta.toFixed(2))
                  };
                })
                setItems(data);
            } catch (error) {
                alert(`${error}`);
                return;
            }
        }/* else if (cod_obra.length > 14) {
            setOt(cod_obra);
            manualItems[0].name = cod_obra;
            manualItems[0].key = cod_obra;
            manualItems[0].id = cod_obra;
            setItems(manualItems);
        }*/
    }, [dev]);

    if (permissions && organizations && (permissions.role.type === 'manager' || permissions.foreman || permissions.role.type === 'employee')) {
        return <Create {...props}>
            <SimpleForm
                validate={validatePurchase}
            >
                <Fragment>
                    <Typography variant="h6">
                        {translate('resources.purchases.new')}
                    </Typography>
                </Fragment>
                <SelectInput
                    label="resources.organizations.fields.organization"
                    source="organization._id"
                    optionText="name"
                    choices={organizations || []}
                    onChange={(e) => {
                        setOt(null);
                        setItem(null);
                        setItems(null);
                        setType(null);
                        setOrganization(organizations.find(o => o.id === e.target.value));
                    }}
                    fullWidth
                    required
                />
                { organization ?
                    <TextInput
                        source="ot"
                        label="resources.invoices.fields.ot"
                        required
                        onChange={(e) => fetchItems(organization, e.target.value)}
                    /> : <></>
                }
                { items && items.length ?
                    <SelectInput
                        source="item"
                        onChange={(e) => setItem(items.find(i => i.key === e.target.value))}
                        fullWidth
                        required
                        choices={items}
                    />
                    : !items && ot ? <>{translate('pos.loading.items')}</>
                    : items && !items.length ?  <>{translate('pos.loading.noItems')}</> : <></>
                }
                { item ?
                    <>
                        <NumberInput
                            source="budget_cost"
                            label="resources.purchases.fields.budget_cost"
                            defaultValue={item.coste}
                            disabled
                        />
                        <>{" "}</>
                        <NumberInput
                            source="budget_sale"
                            label="resources.purchases.fields.budget_sale"
                            defaultValue={item.venta}
                            disabled
                        />
                        <RadioButtonGroupInput
                            source="type"
                            label="resources.purchases.fields.type"
                            onChange={(type) => setType(type)}
                            className={classes.radio}
                            row={false}
                            required
                            fullWidth
                            choices={[
                                {id: 'invoice',name: `${translate('resources.purchases.type.invoice')}`},
                                {id: 'urgent',name: `${translate('resources.purchases.type.urgent')}`},
                                {id: 'open_order',name: `${translate('resources.purchases.type.open_order')}`},
                                {id: 'less',name: `${translate('resources.purchases.type.less')} ${organization.purchaseLessThan}€`},
                                {id: 'between',name: `${translate('resources.purchases.type.between')}  ${organization.purchaseLessThan}€ ${translate('resources.purchases.type.and')} ${organization.purchaseMoreThan}€`},
                                {id: 'more',name: `${translate('resources.purchases.type.more')} ${organization.purchaseMoreThan}€`}
                            ]}
                        />
                    </> : <></>
                }

                {
                    type ? <>
                        { type === 'between' || type === 'more' ?
                            <>
                                <OfferReferenceInput
                                    source="offers[0]"
                                    type={type}
                                    label="resources.purchases.tabs.offer1"
                                    reference="offers"
                                    allowEmpty
                                    validate={required()}
                                    perPage={1}

                                />
                                <OfferReferenceInput
                                    source="offers[1]"
                                    type={type}
                                    label="resources.purchases.tabs.offer2"
                                    reference="offers"
                                    allowEmpty
                                    validate={required()}
                                    perPage={1}
                                />
                                <OfferReferenceInput
                                    source="offers[2]"
                                    type={type}
                                    label="resources.purchases.tabs.offer3"
                                    reference="offers"
                                    allowEmpty
                                    validate={required()}
                                    perPage={1}
                                />
                            </> : 
                            <OfferReferenceInput
                                source="offers[0]"
                                type={type}
                                label="resources.purchases.tabs.offer1"
                                reference="offers"
                                allowEmpty
                                validate={required()}
                                perPage={1}
                            />
                        }
                    </>: <></>
                }
                
                { type === 'between' || type === 'more' ?
                    <RadioButtonGroupInput
                        source="preference"
                        label="resources.purchases.fields.preference"
                        fullWidth
                        choices={[{ id: 'offer1', name: `Oferta 1`},{ id: 'offer2', name: `Oferta 2`},{ id: 'offer3', name: `Oferta 3`}]}
                        required
                    /> : <></>
                }
                { type && type === 'more' ?
                        <TextInput source="comments" label="resources.purchases.fields.comments" fullWidth required /> : <></>
                }
                { type && type !== 'more' ?
                        <TextInput source="comments" label="resources.purchases.fields.comments" fullWidth /> : <></>
                }                
                {/* 
                    Crear las ofertas desde el frontend
                    https://marmelab.com/blog/2020/04/27/react-admin-tutorials-custom-forms-related-records.html
                */}

            </SimpleForm>
        </Create>
    } else {
        if (loading) {
            return (<></>);
        } else {
            return (<>{translate('resources.purchases.cannot_create')}</>);
        }
    }

};

export default PurchaseCreate;
