import React, { Fragment, useCallback } from 'react';
import classnames from 'classnames';
import { List } from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import { Drawer, useMediaQuery, makeStyles } from '@material-ui/core';

import ComplaintListMobile from './ComplaintListMobile';
import ComplaintListDesktop from './ComplaintListDesktop';
import ComplaintFilter from './ComplaintFilter';
import ComplaintEdit from './ComplaintEdit';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        // marginRight: 400,
    },
    drawerPaper: {
        zIndex: 100,
    },
}));

const ComplaintList = ({ permissions, ...props }) => {
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const history = useHistory();

    const handleClose = useCallback(() => {
        history.push('/complaints');
    }, [history]);


    return (
        <div className={classes.root}>
            <Route path="/complaints/:id">
                {({ match }) => {
                    const isMatch = !!(
                        match &&
                        match.params &&
                        match.params.id !== 'create' &&
                        permissions
                    );
                    if (permissions) {
                      return (
                        <Fragment>
                            <List
                                {...props}
                                className={classnames(classes.list, {
                                    [classes.listWithDrawer]: isMatch,
                                })}
                                filters={<ComplaintFilter />}
                                filter={ permissions.complaintManager ? { } : null }
                                perPage={25}
                                sort={{ field: 'createdAt', order: 'DESC' }}
                            >
                                {isXSmall ? (
                                    <ComplaintListMobile permissions={permissions} />
                                ) : (
                                    <ComplaintListDesktop permissions={permissions} />
                                )}
                            </List>
                            <Drawer
                                variant="persistent"
                                open={isMatch}
                                anchor="right"
                                onClose={handleClose}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                                {isMatch ? (
                                    <ComplaintEdit
                                        id={match.params.id}
                                        onCancel={handleClose}
                                        permissions={permissions}
                                        {...props}
                                    />
                                ) : null}
                            </Drawer>
                        </Fragment>
                    );
                  } else {
                    return (<></>);
                  }
                }}
            </Route>
        </div>
    );
};

export default ComplaintList;
