import React from 'react';
import {
    AutocompleteInput,
    DateInput,
    Filter,
    ReferenceInput,
    SelectInput,
    useTranslate,
    TextInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useFilterStyles = makeStyles({
    type: { width: 150 },
});

const ContractFilter = props => {
    const classes = useFilterStyles();
    const translate = useTranslate();

    return (
        <Filter {...props}>
            <TextInput source="q" label="resources.contracts.filters.description" alwaysOn />
            <SelectInput
                source="type"
                choices={[
                    { id: 'normal', name: translate("resources.contracts.types.normal") },
                    { id: 'equal', name: translate("resources.contracts.types.equal") },
                    { id: 'admon', name: translate("resources.contracts.types.admon") },
                ]}
                className={classes.type}
            />
            <ReferenceInput
              source="supplier"
              reference="suppliers"
              label={translate("resources.suppliers.fields.supplier")}
            >
                <AutocompleteInput
                    optionText={choice =>
                        choice && choice.id ? `${choice.business}` : ""
                    }
                />
            </ReferenceInput>
            <ReferenceInput
              source="user"
              reference="users"
              label={translate("resources.users.fields.user")}
            >
                <AutocompleteInput
                    optionText={choice =>
                        choice && choice.id ? `${choice.name}` : ""
                    }
                />
            </ReferenceInput>
            <ReferenceInput
                source="budget"
                reference="budgets"
                label={translate("resources.budgets.fields.budget")}
            >
                <AutocompleteInput optionText="description" />
            </ReferenceInput>
            <ReferenceInput
                source="organization"
                reference="organizations"
                label={translate("resources.organizations.fields.organization")}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <DateInput source="expiration_gte" label="resources.contracts.filters.expiration_gte" />
            <DateInput source="expiration_lte" label="resources.contracts.filters.expiration_lte" />
        </Filter>
    );
};

export default ContractFilter;
