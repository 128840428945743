import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import axios from 'axios';
import {
    useTranslate,
    useNotify,
    Notification,
    useRedirect
} from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import SecurityIcon from '@material-ui/icons/Security';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';

import { lightTheme } from '../layout/themes';
import URL from '../URL';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'url(/img/fondo1.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 200,
        maxWidth: 800,
        marginTop: '3em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
        padding: '10px'
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    select: {
        marginTop: '1em',
        width: '100%'
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
}));

const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const ComplaintFind = ({permissions, ...props}) => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const redirectTo = useRedirect();

    const validate = values => {
        const errors = {};     
        if (!values.id || (values.id.length !== 24)) {
            errors.id = translate('ra.validation.required');
        }
        return errors;
    };

    const findComplaint = data => {
        setLoading(true);
        // Request API.
        axios.get(`${URL}/complaints/${data.id}`)
          .then(({ data }) => {
              setLoading(false);
              redirectTo(`/complaint/${data.id}`);
          })
          .catch(error => {
              setLoading(false);
              notify('resources.complaints.public.findError', 'warning');
          });
    };

    return (
    <Form
        onSubmit={findComplaint}
        validate={validate}
        render={({ handleSubmit: findComplaint }) => (
            <form onSubmit={findComplaint} noValidate>
                <div className={classes.main}>
                    <Card className={classes.card}>
                        <div className={classes.avatar}>
                            <Avatar className={classes.icon}>
                                <SecurityIcon />
                            </Avatar>
                        </div>
                        <div className={classes.hint}>
                            {translate('resources.complaints.public.title')}
                        </div>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    name="id"
                                    component={renderInput}
                                    label={translate('resources.complaints.fields.id')}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <CardActions className={classes.actions}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={loading}
                                className={classes.button}
                                fullWidth
                            >
                                {translate('pos.search')}
                            </Button>

                            <Button
                                variant="contained"
                                color="secondary"
                                type="submit"
                                disabled={loading}
                                className={classes.button}
                                fullWidth
                                onClick={() => redirectTo(`/complaint-create`)}
                            >
                                {translate('resources.complaints.public.create')}
                            </Button>
                        </CardActions>
                    </Card>
                    <Notification />
                </div>
            </form>
        )}
    />)

};

ComplaintFind.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in ResetPassword won't get
// the right theme
const ComplaintFindWithTheme = props => (
    <ThemeProvider theme={createTheme(lightTheme)}>
        <ComplaintFind {...props} />
    </ThemeProvider>
);

export default ComplaintFindWithTheme;
