import React, { useCallback } from 'react';
import { useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ThumbDown from '@material-ui/icons/ThumbDown';
import { useTranslate, useUpdate, useNotify, useRedirect } from 'react-admin';

/**
 * This custom button demonstrate using a custom action to update data
 */
const RejectButton = ({ record, resource }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirectTo = useRedirect();
    const formState = useFormState();

    let status = 'rejected';
    if (record.status === 'accepted') {
        status = 'rejected_payout'
    }

    const [reject, { loading }] = useUpdate(
        resource,
        record.id,
        {
            status,
            rejectComment: formState.values.rejectComment,
        },
        record,
        {
            undoable: true,
            onSuccess: () => {
                notify(
                    `resources.${resource}.notification.rejected_success`,
                    'info',
                    {},
                    true
                );
                redirectTo(`/${resource}`);
            },
            onFailure: () => {
                notify(
                    `resources.${resource}.notification.rejected_error`,
                    'warning'
                );
            },
        }
    );


    const handleClick = useCallback(() => {
        if (!formState.valid) {
            return;
        }
        if (status === 'rejected' || status === 'rejected_payout') {
            if (!formState.values.rejectComment) {
                alert(translate(`pos.comment_required`));
                return;
            }
        }
        reject();
    }, [
        formState.valid,
        reject,
        formState.values.rejectComment,
        status,
        translate
    ]);

    return record && (
                record.status === 'pending'
            ||  record.status === 'accepted'
            ||  record.status === 'approved'
        ) ? (
        <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleClick}
            disabled={loading}
        >
            <ThumbDown
                color="primary"
                style={{ paddingRight: '0.5em', color: 'red' }}
            />
            {translate(`resources.${resource}.action.reject`)}
        </Button>
    ) : (
        <span />
    );
};

RejectButton.propTypes = {
    record: PropTypes.object,
};

export default RejectButton;
