import React from 'react';
import {
    Create,
    ReferenceInput,
    SimpleForm,
    TextInput,
    required,
    AutocompleteInput
} from 'react-admin';

const BudgetCreate = ({permissions, ...props}) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="ot" />
                <TextInput source="description" validate={required()} />
                <ReferenceInput
                    label="resources.budgets.fields.organization"
                    source="organization"
                    reference="organizations"
                    validate={required()}
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.name}` : ""
                        }
                    />
                </ReferenceInput>
                {
                  permissions && (permissions.role.type === 'manager') ?
                      <ReferenceInput
                          label="resources.budgets.fields.user"
                          source="user"
                          reference="users"
                          validate={required()}
                        //   filter={{ "role.type": 'validator' }}
                      >
                        <AutocompleteInput
                            optionText={choice =>
                                choice && choice.id ? `${choice.name}` : ""
                            }
                        />
                      </ReferenceInput>
                  : <></>
                }
            </SimpleForm>
        </Create>
    );
};

export default BudgetCreate;
