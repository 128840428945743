import React from 'react';
import {
    DateInput,
    Filter,
    SearchInput,
    SelectInput,
    useTranslate
} from 'react-admin';

const AnnouncementFilter = props => {
    const translate = useTranslate();

    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <SelectInput
                source="category"
                choices={[
                    { id: 'all', name: translate("resources.announcements.categories.all") },
                    { id: 'employee', name: translate("resources.announcements.categories.employee") },
                    { id: 'validator', name: translate("resources.announcements.categories.validator") },
                    { id: 'supplier', name: translate("resources.announcements.categories.supplier") },
                    { id: 'manager', name: translate("resources.announcements.categories.manager") },
                ]}
            />
            <DateInput source="date_gte" />
            <DateInput source="date_lte" />
        </Filter>
    );
};

export default AnnouncementFilter;
