import React, { Fragment } from 'react';
import MuiToolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import { SaveButton } from 'react-admin';
import AcceptButton from './AcceptButton';
import RejectButton from './RejectButton';

const useStyles = makeStyles({
    root: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const SupplierEditToolbar = ({
    basePath,
    handleSubmitWithRedirect,
    invalid,
    record,
    resource,
    saving,
    permissions
}) => {
    const classes = useStyles();

    return (
        <MuiToolbar className={classes.root}>
            {record.status === 'pending' ? (
                <Fragment>
                    <AcceptButton record={record} resource={'suppliers'} />
                    <RejectButton record={record} resource={'suppliers'} />
                </Fragment>
            ) : record.status === 'payment_confirm' && permissions.supplierPaymentValidator ?
                permissions.supplierPaymentValidator ? 
                    <Fragment>
                        <AcceptButton record={record} resource={'suppliers'} action={'payment_confirm'} />
                        <RejectButton record={record} resource={'suppliers'} action={'reject'} />
                    </Fragment> :
                <Fragment>
                    <SaveButton
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                        invalid={invalid}
                        saving={saving}
                        redirect="list"
                        submitOnEnter={true}
                    />
                </Fragment>
            : (
                <Fragment>
                    <SaveButton
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                        invalid={invalid}
                        saving={saving}
                        redirect="list"
                        submitOnEnter={true}
                    />
                </Fragment>
            )}
        </MuiToolbar>
    );
};

export default SupplierEditToolbar;
