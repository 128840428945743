import React, { useState, useCallback } from 'react';
import axios from 'axios';
import {
    useEditController,
    useTranslate,
    SimpleForm,
    TextField,
    TextInput,
    SelectInput,
    NumberInput,
    BooleanField,
    DateField,
    required,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import CustomImageField from '../invoices/CustomImageField';
import OrganizationReferenceField from '../organizations/OrganizationReferenceField';

import URL from '../URL';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
}));

const ContractEdit = ({ onCancel, ...props }) => {
    const classes = useStyles();
    const controllerProps = useEditController(props);
    const translate = useTranslate();
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState();

    const fetchItems = useCallback((cod_ent, cod_del, cod_obra) => {
        setLoading(true);
        // Request items
        axios.get(`${URL}/items?cod_ent=${cod_ent}&cod_del=${cod_del}&cod_obra=${cod_obra}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          }).then(response => {
            if (response.data.error) {
                alert(`${response.data.error}`);
            } else {
                // get max for simulating a tree using spaces
                const max = Math.max.apply(Math, response.data.map(o => parseInt(o.niv_capit)))
                setItems(
                  response.data
                      .sort((a, b) => a.niv_capit >= b.niv_capit ? 1 : -1)
                      .sort((a, b) => a.cod_capit >= b.cod_capit ? 1 : -1)
                      .map(d => {
                        const ss = ' '.repeat(parseInt(d.niv_capit));
                        const se = ' '.repeat(max - parseInt(d.niv_capit));
                        return {
                          key: `${d.cod_capit}_${d.des_capit}`,
                          id: `${d.cod_capit}_${d.des_capit}`,
                          name: `${ss}${d.cod_capit}${se} - ${d.des_capit}`
                        };
                      })
                );
            }
            setLoading(false)
          });
      }, []);

    const setItemSelected = (value) => {
        // get item and itemDesc
        const splited = value.split('_');
        // actually save the form
        controllerProps.save({
            item: splited[0],
            itemDesc: splited[1],
        });        
    };

    if (controllerProps.record) {
        const c = controllerProps.record;
        if (c.type !== 'admon') {
            if (!items && !loading && c.budget) {
                fetchItems(c.organization.nameNumber, c.organization.officeNumber, c.budget.ot);
            }
        }
    } else {
        return null;
    }

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6">
                    {translate('resources.invoices.detail')}
                </Typography>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <SimpleForm
                className={classes.form}
                basePath={controllerProps.basePath}
                record={controllerProps.record}
                save={controllerProps.save}
                version={controllerProps.version}
                redirect="list"
                resource="contracts"
            >
                <NumberInput source="number" validate={required()} fullWidth />
                <TextInput source="description" validate={required()} fullWidth />
                <OrganizationReferenceField link={false} />
                <ReferenceInput
                    source="budget._id"
                    reference="budgets"
                    label="resources.invoices.fields.budget"
                    fullWidth
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.description}` : ""
                        }
                    />
                </ReferenceInput>
                <TextField
                    label="resources.contracts.fields.budget"
                    source="budget.description"
                    fullWidth
                />
                <TextField
                    label="resources.budgets.fields.ot"
                    source="budget.ot"
                    fullWidth
                />
                {
                    items ?
                        <SelectInput
                            source="items"
                            choices={items}
                            onChange={event => setItemSelected(event.target.value)}
                            fullWidth
                        />
                    : <>{translate('pos.loading.items')}</>
                }

                <TextInput source="item" fullWidth />
                <TextInput source="itemDesc" fullWidth />
                <SelectInput
                    source="type"
                    validate={required()}
                    choices={[
                      { key: 'normal', id: 'normal', name: translate(`resources.contracts.types.normal`) },
                      { key: 'equal', id: 'equal', name: translate(`resources.contracts.types.equal`) },
                      { key: 'admon', id: 'admon', name: translate(`resources.contracts.types.admon`) },
                    ]}
                    fullWidth
                />
                {
                  controllerProps.record.amount ? <NumberInput source="amount" fullWidth /> : <></>
                }
                <ReferenceInput
                    source="supplier._id"
                    reference="suppliers"
                    label="resources.invoices.fields.supplier"
                    fullWidth
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.business}` : ""
                        }
                    />
                </ReferenceInput>
                <BooleanField source="valid" />
                <DateField source="expiration" />
                <BooleanField source="signed" />
                {
                  controllerProps.record.signDate ?
                      <DateField source="signDate" />
                  : <></>
                }
                <TextInput source="location" validate={required()} fullWidth />
                <TextInput multiline source="notes" fullWidth />
                <CustomImageField source="files" />
            </SimpleForm>
        </div>
    );
};

export default ContractEdit;
