import React from 'react';
import PropTypes from 'prop-types';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
      paddingTop: 20,
      paddingBottom: 20,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    maxHeight: 320,
    flexWrap: 'nowrap'
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  image: {
    width: "20px",
    height: "20px"
  },
  icon: {
    color: 'white',
  },
}));

const CustomImageField = ({ source, record = {} }) => {
    const classes = useStyles();
    let images;

    if (!record[source]) return <></>;

    if (Array.isArray(record[source])) {
      images = record[source].map(image => {
          return {
            id: image.id,
            url: `${image.url}`,
            name: image.name
          };
      });
    } else {
      images = [{
        id: record[source].id,
        url: `${record[source].url}`,
        name: record[source].name
      }]
    }

    // https://stackoverflow.com/questions/48921432
    return (
      <div className={classes.root}>
          <ImageList className={classes.gridList}>
              {images.map(image => (
                <ImageListItem key={image.id}>
                    <img src={image.url} alt={image.name} />
                    <ImageListItemBar
                        title={image.name}
                        position="top"
                        actionIcon={
                          <IconButton
                            id={image.id}
                            aria-label={`${image.name}`}
                            className={classes.icon}
                            onClick={() => {
                              // some dirty code...
                              window.open(image.url, '_blank');
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        }
                        actionPosition="left"
                        className={classes.titleBar}
                    />
                </ImageListItem>
              ))}
          </ImageList>
      </div>
    );
};

CustomImageField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default CustomImageField;
