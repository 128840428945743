import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
    Filter,
    SearchInput,
    SelectInput,
    useTranslate,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';
import { makeStyles, Chip } from '@material-ui/core';

import URL from '../URL';

const useFilterStyles = makeStyles({
    status: { width: 150 },
});

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

const UserFilter = props => {
    const classes = useFilterStyles();
    const [roles, setRoles] = useState();
    const translate = useTranslate();
    const [organizations, setOrganizations] = useState();

    useEffect(() => {
        axios.get(`${URL}/organizations/`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
          }).then( res => {
            setOrganizations(res.data.map(o => {
                    return { id: o.id, name: o.name };
                }));
          });
    }, [])

    const fetchRoles = useCallback(async () => {
      // Request organizations.
      axios.get(`${URL}/users-permissions/roles`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      }).then(function (response) {
          setRoles(response.data.roles
              .filter(r => ['validator', 'supplier', 'manager', 'employee'].includes(r.type))
              .map(r => {
                  return {key: r.id, id: r.id, name: translate(`pos.roles.${r.type}`)};
          }));
        })
    }, [translate]);

    useEffect(() => {
        if (!roles) {
          fetchRoles();
        }
    }, [roles, fetchRoles]);

    if (roles) {
        return (
            <Filter {...props}>
                <SearchInput source="q" alwaysOn />
                <SelectInput
                    source="role"
                    label="resources.users.fields.role"
                    choices={roles}
                    className={classes.status}
                />
                <QuickFilter source="orderValidator" label="resources.users.fields.order_validator" defaultValue={true} />
                <QuickFilter source="invoiceValidator" label="resources.users.fields.invoice_validator" defaultValue={true} />
                <QuickFilter source="invoicePayer" label="resources.users.fields.invoice_payer" defaultValue={true} />
                <QuickFilter source="ticketValidator" label="resources.users.fields.ticket_validator" defaultValue={true} />
                <QuickFilter source="ticketPayer" label="resources.users.fields.ticket_payer" defaultValue={true} />
                <QuickFilter source="ticketRegistrar" label="resources.users.fields.ticket_registrar" defaultValue={true} />
                <QuickFilter source="ticketAdmin" label="resources.users.fields.ticket_admin" defaultValue={true} />
                <QuickFilter source="supplierValidator" label="resources.users.fields.supplier_validator" defaultValue={true} />
                <QuickFilter source="issueManager" label="resources.users.fields.issue_manager" defaultValue={true} />
                <QuickFilter source="purchaseAdmin" label="resources.users.fields.purchase_admin" defaultValue={true} />
                <QuickFilter source="purchaseAdmonObra" label="resources.users.fields.org_admon_obra" defaultValue={true} />
                <QuickFilter source="foreman" label="resources.users.fields.foreman" defaultValue={true} />
                <QuickFilter source="complaintManager" label="resources.users.fields.complaintManager" defaultValue={true} />
                <ReferenceInput
                    source="supplier"
                    reference="suppliers"
                    label={translate("resources.suppliers.fields.supplier")}
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.business}` : ""
                        }
                    />
                </ReferenceInput>
                <SelectInput
                    source="organization"
                    choices={organizations}
                    label="resources.users.fields.organization"
                />
            </Filter>
        );
    } else {
      return (<></>);
    }
};

export default UserFilter;
