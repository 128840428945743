import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {
    linkToRecord,
} from 'react-admin';

import AvatarField from '../components/AvatarField';

const useStyles = makeStyles({
    root: {
        width: '100vw',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    inline: {
        display: 'inline',
    },
});

const OrganizationListMobileList = ({ basePath, data, ids, loading, total }) => {
    const classes = useStyles();
    return (
        (loading || total > 0) && (
            <List className={classes.root}>
                {ids.map(id => (
                    <Link
                        to={linkToRecord(basePath, id)}
                        className={classes.link}
                        key={id}
                    >
                        <ListItem button>
                            <ListItemAvatar>
                                <AvatarField
                                    record={data[id]}
                                    source="avatar"
                                    size={40}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Fragment>
                                        {`${data[id].name}`}
                                    </Fragment>
                                }
                                secondary={data[id].email}
                                secondaryTypographyProps={{ noWrap: true }}
                            />
                        </ListItem>
                    </Link>
                ))}
            </List>
        )
    );
};

OrganizationListMobileList.propTypes = {
    basePath: PropTypes.string,
    data: PropTypes.object,
    hasBulkActions: PropTypes.bool.isRequired,
    ids: PropTypes.array,
    leftAvatar: PropTypes.func,
    leftIcon: PropTypes.func,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
        .isRequired,
    onToggleItem: PropTypes.func,
    primaryText: PropTypes.func,
    rightAvatar: PropTypes.func,
    rightIcon: PropTypes.func,
    secondaryText: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    tertiaryText: PropTypes.func,
};

OrganizationListMobileList.defaultProps = {
    link: 'edit',
    hasBulkActions: false,
    selectedIds: [],
};

export default OrganizationListMobileList;
