import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
    Create,
    FormTab,
    TabbedForm,
    required,
    Toolbar,
    TextInput,
    NumberInput,
    DateInput,
    SelectInput,
    FileInput,
    FileField,
    ReferenceInput,
    useTranslate,
    AutocompleteInput
} from 'react-admin';
// import { makeStyles } from '@material-ui/core/styles';
import SaveAndUploadButton from './SaveAndUploadButton';

import URL from '../URL';

// const useStyles = makeStyles(theme => ({
//     inline: {
//       display: 'inline-block',
//       width: '50%'
//     }
// }));

const CustomToolbar = props => (
    <Toolbar {...props} >
        <SaveAndUploadButton redirect="contracts" />
    </Toolbar>
);

const ContractCreate = ({permissions, ...props}) => {
    // const classes = useStyles();
    const translate = useTranslate();

    const [typeSelected, setTypeSelected] = useState();
    const [orgSelected, setOrgSelected] = useState();
    const [org, setOrg] = useState();
    const [budgetSelected, setBudgetSelected] = useState();
    const [budget, setBudget] = useState();
    const [ot, setOT] = useState();
    const [items, setItems] = useState([]);

    const fetchItems = useCallback( async (cod_ent, cod_del, cod_obra) => {
      // Request items
      await axios.get(`${URL}/items?cod_ent=${cod_ent}&cod_del=${cod_del}&cod_obra=${cod_obra}`, {
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(response => {
          if (response.data.error) {
            alert(`${response.data.error}`);
            return;
          }
          // get max for simulating a tree using spaces
          const max = Math.max.apply(Math, response.data.map(o => parseInt(o.niv_capit)))
          setItems(
            response.data
                .sort((a, b) => a.niv_capit >= b.niv_capit ? 1 : -1)
                .sort((a, b) => a.cod_capit >= b.cod_capit ? 1 : -1)
                .map(d => {
                  const ss = ' '.repeat(parseInt(d.niv_capit));
                  const se = ' '.repeat(max - parseInt(d.niv_capit));
                  return {
                    key: `${d.cod_capit}_${d.des_capit}`,
                    id: `${d.cod_capit}_${d.des_capit}`,
                    name: `${ss}${d.cod_capit}${se} - ${d.des_capit}`
                  };
                })
          );
        });
    }, []);

    const fetchOrg = useCallback(async (organizationId) => {
      // Request organizations.
      axios.get(`${URL}/organizations/${organizationId}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      }).then(response => {
        setOrg(response.data);
        if (budget) {
          fetchItems(response.data.nameNumber, response.data.officeNumber, budget.ot);
        }
      });
    }, [budget, fetchItems]);

    const fetchBudget = useCallback(async (budgetId) => {
      // Request budgets.
      axios.get(`${URL}/budgets/${budgetId}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      }).then(response => {
        setBudget(response.data);
        if (org) {
          fetchItems(org.nameNumber, org.officeNumber, response.data.ot);
        }
      });
    }, [org, fetchItems]);

    useEffect(() => {
        if (orgSelected && !org) fetchOrg(orgSelected);
        if (budgetSelected && !budget) fetchBudget(budgetSelected);
    }, [
      org,
      orgSelected,
      fetchOrg,
      budget,
      budgetSelected,
      fetchBudget
    ]);

    const setOTSelected = (value) => {
      if (value.length === 7 || value.length === 14) {
        setOT(value);
        fetchItems(org.nameNumber, org.officeNumber, value);
      }
    };

    if (permissions && permissions.role.type === 'manager') {
      return (
          <Create {...props}>
            <TabbedForm toolbar={<CustomToolbar />} >
                <FormTab label="resources.contracts.tabs.contact">
                    <NumberInput source="number" validate={required()} />
                    <TextInput source="description" validate={required()} />
                    <SelectInput
                        source="type"
                        validate={required()}
                        choices={[
                          { key: 'normal', id: 'normal', name: translate(`resources.contracts.types.normal`) },
                          { key: 'equal', id: 'equal', name: translate(`resources.contracts.types.equal`) },
                          { key: 'admon', id: 'admon', name: translate(`resources.contracts.types.admon`) },
                        ]}
                        onChange={event => setTypeSelected(event.target.value)}
                    />
                    {
                      typeSelected === 'equal' ? <NumberInput source="amount" /> : <></>
                    }
  
                    <ReferenceInput
                        label="resources.organizations.fields.organization"
                        source="organization._id"
                        reference="organizations"
                        onChange={event => setOrgSelected(event.target.value)}
                    >
                        <SelectInput optionText="name" validate={required()} />
                    </ReferenceInput>
  
                    {
                      orgSelected && typeSelected !== 'admon' ?
                          <>
                            <ReferenceInput
                                label="resources.budgets.fields.budget"
                                source="budget._id"
                                reference="budgets"
                                filter={{ organization: orgSelected }}
                                onChange={event => setBudgetSelected(event.target.value)}
                                >
                                <SelectInput optionText="description" />
                            </ReferenceInput>
                            <>  </>
                            <TextInput
                                source="ot"
                                label="resources.budgets.fields.ot"
                                onChange={event => setOTSelected(event.target.value)}
                            />
                            <small>{translate('pos.borot')}</small>
                          </>
                      : <></>
                    }

                    {
                      (budgetSelected || ot) && typeSelected !== 'admon' ?
                          items.length ?
                              <SelectInput
                                  source="item"
                                  choices={items}
                              />
                          : <>{translate('pos.loading.items')}</>
                      : <></>
                    }
  
                    {
                      orgSelected ?
                          <ReferenceInput
                              source="supplier._id"
                              reference="suppliers"
                              label="resources.invoices.fields.supplier"
                              >
                              <AutocompleteInput
                                  optionText={choice =>
                                      choice && choice.id ? `${choice.business}` : ""
                                  }
                                  validate={required()}
                              />
                          </ReferenceInput>
                      : <></>
                    }
                    {
                      orgSelected ?
                          <TextInput source="location" validate={required()} />
                      : <></>
                    }
                </FormTab>
                <FormTab label="resources.contracts.tabs.sign">
                    <DateInput source="expiration" validate={required()} />
                    <NumberInput source="notificationDays" validate={required()} />
  
                </FormTab>
                <FormTab label="resources.contracts.tabs.notes">
                    <TextInput multiline source="notes" />
                </FormTab>
                <FormTab label="resources.contracts.tabs.files">
                    <FileInput
                        source="files"
                        multiple
                        accept="application/pdf, image/*"
                    >
                        <FileField source="files" title="title" />
                    </FileInput>
                </FormTab>
            </TabbedForm>
          </Create>
      );
    } else {
      return (<>{translate('resources.contracts.cannot_create')}</>);
    }
};

export default ContractCreate;
