import React, { useState, useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { SaveButton, useCreate, useRedirect, useNotify } from 'react-admin';
import axios from 'axios';

import URL from '../URL';

const SaveAndUploadButton = props => {
    const [loading, setLoading] = useState(false);
    const redirectTo = useRedirect();
    const notify = useNotify();
    // get the form state
    const formState = useFormState();

    const upload = async (recordId) => {
        return new Promise((resolve, reject) => {
            // create an HTML standar Form Data
            const formData = new FormData();
            // append attachments
            Array.from(formState.values.attachments).forEach(image => {
                // https://gist.github.com/alexandrebodin/d69fc465fb731b665429f6da472929df
                // https://stackoverflow.com/questions/54260800
                // https://github.com/marmelab/react-admin/blob/master/docs/Inputs.md#imageinput
                // https://github.com/strapi/strapi-examples/blob/8f601df45bcaddf2b4d4ccd8968c763ea4920e01

                // console.log("is a File instance?" image.rawFile instanceof File);
                formData.append('files', image.rawFile);
            });
            formData.append('ref', "announcement");
            formData.append('refId', recordId);
            // formData.append('source', "announcements");
            formData.append('field', "attachments");

            // make the request
            axios
                .post(`${URL}/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                })
                .then(response => {
                    notify(
                        'ra.notification.created',
                        'info',
                        {},
                        true
                    );
                    setLoading(false);
                    redirectTo('/announcements');
                })
                .catch(error => {
                    notify(
                        `pos.upload_failed`,
                        'warning'
                    );
                    setLoading(false);
                    resolve();
                });
        });
    }

    const [ create ] = useCreate(
      'announcements',
      { ...formState.values, status: 'pending' },
      {
          undoable: false,
          onSuccess: ({ data: newRecord }) => {
              if (formState.values.attachments && formState.values.attachments.length) {
                  notify(
                      'pos.uploading',
                      'info',
                      {},
                      true
                  );
                  upload(newRecord.id);
              } else {
                setLoading(false);
                redirectTo('/announcements');
              }
          },
          onFailure: () => {
              notify(
                  'resources.issues.notification.approved_error',
                  'warning'
              );
          },
      }
    );

    const handleClick = useCallback( () => {
        if (formState.valid) {
            setLoading(true);
            create();
        }
    }, [
        formState.valid,
        create,
    ]);

    return <SaveButton {...props} saving={loading} handleSubmitWithRedirect={handleClick} />;
};


export default SaveAndUploadButton;
