import React, { Fragment } from 'react';
import MuiToolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import { SaveButton } from 'react-admin';
import AcceptButton from './AcceptButton';
import RejectButton from './RejectButton';

const useStyles = makeStyles({
    root: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const PurchaseEditToolbar = ({
    basePath,
    handleSubmitWithRedirect,
    invalid,
    record,
    resource,
    saving,
    permissions,
    changed
}) => {
    const classes = useStyles();
    const userId = permissions._id;

    if (userId !== record.manager._id && userId !== record.admon._id && !permissions.purchaseAdmonObra
            && permissions.role.type !== 'manager') {
        return <></>
    }

    return (
        <MuiToolbar className={classes.root}>
            { record.status === 'pending' ? (
                <Fragment>
                    <AcceptButton record={record} resource={'purchases'} />
                    <RejectButton record={record} resource={'purchases'} />
                </Fragment>
            ) : record.status === 'accepted' ? (
                <Fragment>
                    <AcceptButton record={record} action={'approve'} resource={'purchases'} />
                    <RejectButton record={record} resource={'purchases'} />
                </Fragment>
            ) : record.status === 'approved' ? (
            <Fragment>
                <AcceptButton record={record} action={'telematel'} resource={'purchases'} />
                <RejectButton record={record} resource={'purchases'} />
            </Fragment>
            ) : record.status === 'pending' || record.status === 'delivery_note_pending' ?
            (
                <Fragment>
                    <SaveButton
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                        invalid={invalid}
                        saving={saving}
                        redirect="list"
                        submitOnEnter={true}
                    />
                </Fragment>
            ) : <></>}
        </MuiToolbar>
    );
};

export default PurchaseEditToolbar;

