import React from 'react';
import {
    useEditController,
    useTranslate,
    SimpleForm,
    TextInput,
    NumberInput,
    required,
    ReferenceInput,
    AutocompleteInput,
    BooleanInput,
    ArrayInput,
    SimpleFormIterator
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';


const Separator = () => <Box pt="1em" />;

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
}));

const OrganizationEdit = ({ onCancel, ...props }) => {
    const classes = useStyles();
    const controllerProps = useEditController(props);
    const translate = useTranslate();

    
    if (!controllerProps.record) {
        return null;
    }
    
    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6">
                    {controllerProps.record && controllerProps.record.name}
                </Typography>
                <small>{controllerProps.record && controllerProps.record.office}</small>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <SimpleForm
                className={classes.form}
                basePath={controllerProps.basePath}
                record={controllerProps.record}
                save={controllerProps.save}
                version={controllerProps.version}
                redirect="list"
                resource="organizations"
            >
                {/* <NumberInput
                    source="nameNumber"
                    label={translate('resources.organizations.fields.nameNumber')}
                    validate={required()}
                    fullWidth
                /> */}
                {/* <TextInput
                    source="name"
                    label={translate('resources.organizations.fields.name')}
                    validate={required()}
                    fullWidth
                /> */}
                {/* <TextInput
                    source="officeNumber"
                    label={translate('resources.organizations.fields.officeNumber')}
                    validate={required()}
                    fullWidth
                /> */}
                {/* <TextInput
                    source="office"
                    label={translate('resources.organizations.fields.office')}
                    validate={required()}
                    fullWidth
                /> */}
                <TextInput
                    source="email"
                    label={translate('resources.organizations.fields.email')}
                    validate={required()}
                    fullWidth
                />
                <TextInput
                    source="ocrmail"
                    label={translate('resources.organizations.fields.ocrmail')}
                    fullWidth
                />

                <Separator />
                <Separator />

                <Typography variant="h6" gutterBottom>
                    {translate('resources.organizations.purchases')}
                </Typography>

                <NumberInput source="purchaseLessThan" validate={required()} />
                <NumberInput source="purchaseMoreThan" validate={required()} />

                <ReferenceInput
                    source="orgManager._id"
                    reference="users"
                    label="resources.users.fields.org_manager"
                    filterToQuery={ () => {
                        return ({ role: '5e271f3f7fc8a522156f1c30' });
                    } }
                    fullWidth
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.name}` : ""
                        }
                    />
                </ReferenceInput>
                <ReferenceInput
                    source="orgAdmon._id"
                    reference="users"
                    label="resources.users.fields.org_admon"
                    filterToQuery={ () => {
                        return ({ role: '5e271f3f7fc8a522156f1c30' });
                    } }
                    fullWidth
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.name}` : ""
                        }
                    />
                </ReferenceInput>
                <ArrayInput source="orgAdmonsObra" label="resources.users.fields.org_admons_obra">
                    <SimpleFormIterator>
                            <ReferenceInput
                                source="_id"
                                reference="users"
                                label=""
                                filterToQuery={ () => {
                                    return ({ purchaseAdmonObra: true });
                                } }
                                fullWidth
                            >
                                <AutocompleteInput
                                    optionText={choice =>
                                        choice && choice.id ? `${choice.name}` : ""
                                    }
                                />
                            </ReferenceInput>
                    </SimpleFormIterator>
                </ArrayInput>

                <Separator />
                <Separator />

                <Typography variant="h6" gutterBottom>
                    {translate('resources.organizations.fields.orgSupplierPaymentValidator')}
                </Typography>

                <ReferenceInput
                    source="orgSupplierPaymentValidator._id"
                    reference="users"
                    label="resources.organizations.fields.orgSupplierPaymentValidator"
                    filterToQuery={ () => {
                        return ({ supplierPaymentValidator: true });
                    } }
                    fullWidth
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.name}` : ""
                        }
                    />
                </ReferenceInput>

                <Separator />
                <Separator />

                <Typography variant="h6" gutterBottom>
                    {translate('resources.organizations.orders')}
                </Typography>

                <ReferenceInput
                    source="orderValidator._id"
                    reference="users"
                    label="resources.users.fields.order_validator"
                    filterToQuery={ () => {
                        return ({ orderValidator: true });
                    } }
                    fullWidth
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.name}` : ""
                        }
                    />
                </ReferenceInput>

                <Separator />
                <Separator />

                <Typography variant="h6" gutterBottom>
                    {translate('resources.organizations.invoices')}
                </Typography>

                <ReferenceInput
                    source="invoicePayer._id"
                    reference="users"
                    label="resources.organizations.fields.invoicePayer"
                    filterToQuery={ () => {
                        return ({ invoicePayer: true });
                    } }
                    fullWidth
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.name}` : ""
                        }
                    />
                </ReferenceInput>

                <Separator />
                <Separator />

                <Typography variant="h6" gutterBottom>
                    {translate('resources.organizations.tickets')}
                </Typography>

                <ReferenceInput
                    source="ticketPayerCompanyInvoice._id"
                    reference="users"
                    label="resources.organizations.fields.ticketPayerCompanyInvoice"
                    filterToQuery={ () => {
                        return ({ ticketPayer: true });
                    } }
                    fullWidth
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.name}` : ""
                        }
                    />
                </ReferenceInput>

                <ReferenceInput
                    source="ticketPayerOther._id"
                    reference="users"
                    label="resources.organizations.fields.ticketPayerOther"
                    filterToQuery={ () => {
                        return ({ ticketPayer: true });
                    } }
                    fullWidth
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.name}` : ""
                        }
                    />
                </ReferenceInput>

                <ReferenceInput
                    source="ticketRegistrarCompanyInvoice._id"
                    reference="users"
                    label="resources.organizations.fields.ticketRegistrarCompanyInvoice"
                    filterToQuery={ () => {
                        return ({ ticketRegistrar: true });
                    } }
                    fullWidth
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.name}` : ""
                        }
                    />
                </ReferenceInput>

                <ReferenceInput
                    source="ticketRegistrarOther._id"
                    reference="users"
                    label="resources.organizations.fields.ticketRegistrarOther"
                    filterToQuery={ () => {
                        return ({ ticketRegistrar: true });
                    } }
                    fullWidth
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.name}` : ""
                        }
                    />
                </ReferenceInput>

                <BooleanInput
                    source="enableValidatorList"
                    label={translate('resources.organizations.fields.enableValidatorList')}
                />

                {
                  controllerProps.record.enableValidatorList ?
                    <ArrayInput source="validators" label="resources.organizations.fields.validators">
                        <SimpleFormIterator>
                              <ReferenceInput
                                  source="_id"
                                  reference="users"
                                  label=""
                                  filterToQuery={ () => {
                                      return ({ ticketValidator: true });
                                  } }
                                  fullWidth
                              >
                                  <AutocompleteInput
                                      optionText={choice =>
                                          choice && choice.id ? `${choice.name}` : ""
                                      }
                                  />
                              </ReferenceInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                  : <></>
                }

                <Separator />
                <Separator />

                <Typography variant="h6" gutterBottom>
                    {translate('resources.organizations.issueManagers')}
                </Typography>

                <ReferenceInput
                    source="issueOrderManager._id"
                    reference="users"
                    label="resources.organizations.fields.issueOrderManager"
                    filterToQuery={ () => {
                        return ({ issueManager: true });
                    } }
                    fullWidth
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.name}` : ""
                        }
                    />
                </ReferenceInput>

                <ReferenceInput
                    source="issueInvoiceRejectedManager._id"
                    reference="users"
                    label="resources.organizations.fields.issueInvoiceRejectedManager"
                    filterToQuery={ () => {
                        return ({ issueManager: true });
                    } }
                    fullWidth
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.name}` : ""
                        }
                    />
                </ReferenceInput>

                <ReferenceInput
                    source="issuePaymetNotReceivedManager._id"
                    reference="users"
                    label="resources.organizations.fields.issuePaymetNotReceivedManager"
                    filterToQuery={ () => {
                        return ({ issueManager: true });
                    } }
                    fullWidth
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.name}` : ""
                        }
                    />
                </ReferenceInput>

                <ReferenceInput
                    source="issueTechnicalManager._id"
                    reference="users"
                    label="resources.organizations.fields.issueTechnicalManager"
                    filterToQuery={ () => {
                        return ({ issueManager: true });
                    } }
                    fullWidth
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.name}` : ""
                        }
                    />
                </ReferenceInput>

                <ReferenceInput
                    source="issueOtherManager._id"
                    reference="users"
                    label="resources.organizations.fields.issueOtherManager"
                    filterToQuery={ () => {
                        return ({ issueManager: true });
                    } }
                    fullWidth
                >
                    <AutocompleteInput
                        optionText={choice =>
                            choice && choice.id ? `${choice.name}` : ""
                        }
                    />
                </ReferenceInput>
                {
                    true && <>
                        <Separator />
                        <Separator />

                        <Typography variant="h6" gutterBottom>
                            Business Central Id
                        </Typography>                    
                        <TextInput
                            source="businesscentralId"
                            label="Business Central Id"
                            fullWidth
                        />                    
                    </>
                }
            </SimpleForm>
        </div>
    );
};

export default OrganizationEdit;
