import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';

const PurchaseStatusField = ({ source, record = {} }) => {
    const translate = useTranslate();
    if (record.payed) {
        return (
            <span>{ translate(`resources.purchases.status.payed`) } </span>
          );    
    }
    return (
        <span>{ translate(`resources.purchases.status.${record[source]}`) } </span>
      );
};

PurchaseStatusField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default PurchaseStatusField;
