import React from 'react';
import { Datagrid, DateField, TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import AnnouncementCategoryField from './AnnouncementCategoryField';

import rowStyle from '../components/rowStyle';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});


const UnreadUsersFieldCount = ({ record }) => (
    <span>
        {record.users.length}
    </span>
);

const AnnouncementListDesktop = ({ permissions, ...props }) => {
    const classes = useListStyles();
    
    return (
        <Datagrid
            rowClick="edit"
            rowStyle={rowStyle}
            classes={{ headerRow: classes.headerRow }}
            optimized
            {...props}
        >
            <DateField source="createdAt" />
            <TextField source="title" />
            {
                permissions.role.type === 'manager' ? 
                    <AnnouncementCategoryField source="category" />
                : <></>
            }
            {
                permissions.role.type === 'manager' ? 
                    <UnreadUsersFieldCount source="users" />
                : <></>
            }
        </Datagrid>
    );
};

export default AnnouncementListDesktop;
