import axios from 'axios';
import URL from './URL';
import { isPublicRoute, publicRole } from './routes';

export default {
    login: ({ username, password }) =>  {
      // Request API.
      return axios
        .post(`${URL}/auth/local`, {
          identifier: username,
          password: password
        })
        .then(response => {
          // console.log('username', response.data);
          try {
            localStorage.setItem('token', response.data.jwt);
            localStorage.setItem('user', JSON.stringify(response.data.user));
          } catch (error) {
            throw error;
          }
        })
        .catch(error => {
          throw error;
        });
    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkError: (error) => {
      const status = error.status;
      if (status === 401 || status === 403) {
          localStorage.removeItem('auth');
          return Promise.reject();
      }
      return Promise.resolve();
    },
    checkAuth: () => {
      // const user = localStorage.getItem('user');
      if (isPublicRoute(window.location.hash)) {
        // if (user) {
        //   localStorage.removeItem('token');
        // }
        // console.log('checkAuth isPublicRoute');
        return Promise.resolve();
      }

      return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },  
    getPermissions: () => {
        const user = localStorage.getItem('user');

        if (isPublicRoute(window.location.hash)) {
          // if (user) {
          //   localStorage.removeItem('token');
          // }
          // console.log('getPermissions isPublicRoute');
          return Promise.resolve({
            provider: 'local',
            role: publicRole
          });
        }
        return user ? Promise.resolve(JSON.parse(user)) : Promise.reject();
    }
};
