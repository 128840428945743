import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import ResetPassword from './components/ResetPassword';
import ProfileEdit from './profile/ProfileEdit';
import CommentEdit from './comments/CommentEdit';
import ComplaintView from './complaints/ComplaintView';
import ComplaintCreate from './complaints/ComplaintCreate';
import ComplaintFind from './complaints/ComplaintFind';
import UploadUsersCsv from './configuration/UploadUsersCsv';

export const publicRole = {
    name: "Public",
    type: "public",
};

export const PublicRoutes = {
    COMPLAINT_CREATE: `\/complaint-create`,         // eslint-disable-line
    COMPLAINT_FIND: `\/complaint-find`,             // eslint-disable-line
    COMPLAINT_VIEW: `\/complaint\/[0-9a-f]{24}`,    // eslint-disable-line
}

export const isPublicRoute = (pathname) => 
    Object.values(PublicRoutes).some(route => {
        const re = new RegExp(route)
        return re.exec(pathname)
});


export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/reset-password" render={() => <ResetPassword />} />,
    <Route exact path="/profile" render={() => <ProfileEdit />} />,
    <Route exact path="/comments/:id" render={() => <CommentEdit />} />,
    <Route exact path="/upload-users-csv" render={() => <UploadUsersCsv />} />,
    <Route exact path="/complaint-find" render={() => <ComplaintFind />} />,
    <Route exact path="/complaint/:id" render={() => <ComplaintView />} />,
    <Route exact path="/complaint-create" render={() => <ComplaintCreate />} />,
];
