import React from 'react';
import {
    fetchUtils,
    Admin,
    Resource,
    // ListGuesser,
    // EditGuesser
} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import strapiDataProvider from './dataProvider';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout, Menu } from './layout';
import { Dashboard } from './dashboard';

import customRoutes from './routes';
import spanishMessages from './i18n/es';

import invoices from './invoices';
import suppliers from './suppliers';
import budgets from './budgets';
import organizations from './organizations';
import users from './users';
import contracts from './contracts';
import tickets from './tickets';
import issues from './issues';
import announcements from './announcements';
import purchases from './purchases';
import offers from './offers';
import albaranes from './albaranes';
import complaints from './complaints';

import URL from './URL';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    // options.headers.set('Access-Control-Allow-Origin', '*');
    return fetchUtils.fetchJson(url, options);
}

// get the data provider
const dataProvider = strapiDataProvider(URL, httpClient);

// setup translations
const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'en') {
        return import('./i18n/en').then(messages => messages.default);
    }
    // Always fallback on english
    return spanishMessages;
}, 'es');

const App = () => {

  return (
    <Admin
        disableTelemetry
        title=""
        dataProvider={dataProvider}
        customReducers={{ theme: themeReducer }}
        customRoutes={customRoutes}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={Login}
        layout={Layout}
        menu={Menu}
        i18nProvider={i18nProvider}
    >
      {
          permissions => [
              permissions.role.type === 'manager' ||
              permissions.invoiceValidator ||
              permissions.invoiceCreator ||
              permissions.role.type === 'supplier' ?
                    <Resource name="invoices" {...invoices} />
                : null,

              permissions.role.type === 'manager' ||
              permissions.role.type === 'employee' ||
              permissions.role.type === 'validator' ||
              permissions.foreman ||
              permissions.purchaseAdmonObra ?
                    <Resource name="purchases" {...purchases} />
                : null,

              permissions.role.type === 'manager' ||
              permissions.role.type === 'employee' ||
              permissions.role.type === 'validator' ||
              permissions.ticketAdmin ||
              permissions.ticketValidator ?
                  <Resource name="tickets" {...tickets} />
              : null,

              permissions.role.type === 'manager' ||
              permissions.role.type === 'employee' ||
              permissions.role.type === 'validator' ?
                  <Resource name="albaranes" {...albaranes} />
              : null,

              permissions.role ?
                  <Resource name="orders" />
              : null,

              permissions.role.type === 'manager' ?
                  <Resource name="suppliers" {...suppliers} />
              : <Resource name="suppliers" />,

              permissions.role.type === 'manager' ?
                  <Resource name="budgets" {...budgets} />
              :   <Resource name="budgets" />,

              permissions.role.type === 'manager' ||
              permissions.role.type === 'validator' ||
              permissions.role.type === 'employee' ?
                  <Resource name="offers" {...offers} />
              : null,

              permissions.role.type === 'manager' ||
              permissions.invoiceValidator ||
              permissions.role.type === 'supplier' ?
                  <Resource name="contracts" {...contracts} />
              : null,

              permissions.role.type === 'manager' ?
                  <Resource name="organizations" {...organizations} />
              : <Resource name="organizations" />,

              permissions.role.type === 'manager' ?
                  <Resource name="users" {...users} />
              : <Resource name="users" />,

              <Resource name="issues" {...issues} />,

              permissions.role.type === 'manager' && permissions.complaintManager ?
                  <Resource name="complaints" {...complaints} />
              :   null,

              permissions.role ?
                  <Resource name="announcements" {...announcements} />
              : null,
          ]
      }
    </Admin>
  );
}

export default App;
