import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LinkIcon from '@material-ui/icons/Link';
import {
    Button,
    useNotify,
    useRefresh,
    Confirm
} from 'react-admin';
import axios from 'axios';

import URL from '../URL';

const BulkAcceptButton = ({ selectedIds }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState();

    const approve = () => {
        setLoading(true);
        axios.post(`${URL}/suppliers/link`, selectedIds, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(({ data }) => {
            if (data.error) {
                notify(data.error, 'warning');
            }
            setTimeout(() => {
                setLoading(false);
                refresh();
            }, 3000);
        }).catch(error => {
            notify(
                typeof error === 'string'
                    ? error
                        : typeof error === 'undefined' || !error.message
                            ? 'Se ha producido un error'
                                : error.message,
                'warning'
            );
            setTimeout(() => {
                setLoading(false);
                refresh();
            }, 3000);
        });

    }

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        approve();
        setOpen(false);
    };

    return (
        selectedIds.length > 1 ? <>
            <Button
                label="resources.suppliers.link"
                onClick={handleClick}
                disabled={loading}
            >
                <LinkIcon />
            </Button>
            <Confirm
                isOpen={open}
                loading={loading}
                title={`¡Atención!`}
                content={`Los proveedores se vincularán con el primero que ha sido seleccionado`}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </> : <></>
        
    );
};

BulkAcceptButton.propTypes = {
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BulkAcceptButton;
