import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const OrderReferenceField = props => {
  return (
    props.record.order ?
      <ReferenceField source="order.id" reference="orders" {...props}>
          <TextField source="numero" />
      </ReferenceField>
    : <></>
)};

OrderReferenceField.defaultProps = {
    source: 'order.id',
    label: 'resources.invoices.fields.order',
    addLabel: true,
};

export default OrderReferenceField;
