import React from 'react';
import {
    Filter,
    ReferenceInput,
    AutocompleteInput,
    useTranslate,
    SelectInput,
    SearchInput,
    TextInput
} from 'react-admin';
import { makeStyles, Chip } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';

// const useFilterStyles = makeStyles({
//     status: { width: 150 },
// });

const useQuickFilterStyles = makeStyles(theme => ({
    chip: { marginBottom: theme.spacing(1), },
}));

const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

const useFilterStyles = makeStyles({
    status: { width: 150 },
});

const SupplierFilter = props => {
    const classes = useFilterStyles();
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <SelectInput
                  source="status"
                  choices={[
                      { id: 'payment_confirm', name: translate("pos.supplier_status.payment_confirm") },
                      { id: 'accepted', name: translate("pos.supplier_status.accepted") },
                      { id: 'pending', name: translate("pos.supplier_status.pending") },
                      { id: 'rejected', name: translate("pos.supplier_status.rejected") },
                  ]}
                  className={classes.status}
            />
            <SelectInput
                  source="type"
                  choices={[
                      { id: 'supplier', name: translate("resources.suppliers.fields.supplier") },
                      { id: 'creditor', name: translate("resources.suppliers.fields.creditor") },
                  ]}
                  className={classes.status}
            />
            <QuickFilter source="paymentMethodFinal" label="resources.suppliers.fields.paymentMethodNonFinal" defaultValue={false} />
            <ReferenceInput
                source="organization"
                reference="organizations"
                label={translate("resources.organizations.fields.organization")}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <QuickFilter source="taxid" label="resources.suppliers.fields.notaxid" />
            <TextInput source="city" />
        </Filter>
    );
};

export default SupplierFilter;
