import React from 'react';
import {
    useEditController,
    useTranslate,
    SimpleForm,
    TextInput,
    TextField,
    Toolbar,
    SaveButton,
    RichTextField
    // DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {
    Typography,
    IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import CustomImageField from '../invoices/CustomImageField';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
    card: {
        margin: '0 0 0em 0em',
        width: '100%',
    },
    cardHeader: {
        alignItems: 'flex-start',
    },
    clamp: {
        // display: '-webkit-box',
        // '-webkit-line-clamp': 3,
        // '-webkit-box-orient': 'vertical',
        // overflow: 'hidden',
    },
}));

const AnnouncementEditToolbar = ({ props, permissions }) => (
    permissions.role.type === 'manager' ?
        <Toolbar {...props} >
            <SaveButton />
        </Toolbar>
    :
        <Toolbar {...props} >
          <></>
        </Toolbar>
);

const AnnouncementEdit = ({ onCancel, permissions, ...props }) => {
    const classes = useStyles();
    const controllerProps = useEditController(props);
    const translate = useTranslate();
    if (!controllerProps.record) {
        return null;
    }

    const record = controllerProps.record;

    // const index = permissions.unreadAnnouncements.indexOf(record.id);
    // if (index > -1) {
    //     permissions.unreadAnnouncements.splice(index, 1);
    //     localStorage.setItem('user', JSON.stringify(permissions));
    // }

    // const author = (id) => {
    //     return record.user.id === id ? record.user.name : record.announcementManager.id === id ? record.announcementManager.name : '-'
    // };

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6">
                    { translate(`resources.announcements.categories.${record.category}`) }
                </Typography>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <SimpleForm
                className={classes.form}
                basePath={controllerProps.basePath}
                record={record}
                save={controllerProps.save}
                version={controllerProps.version}
                redirect="list"
                resource="announcements"
                toolbar={<AnnouncementEditToolbar permissions={permissions} />}
            >
                {
                    permissions.role.type === 'manager' ?
                      <TextInput source="title" />
                    : <TextField source="title" />
                }
                {
                    permissions.role.type === 'manager' ?
                      <RichTextInput source="description" />
                    : <RichTextField source="description" />
                }
                <CustomImageField source="attachments" />
            </SimpleForm>
        </div>
    );
};

export default AnnouncementEdit;
