import React from 'react';
import { Datagrid, DateField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import IssueStatusField from './IssueStatusField';
import UserReferenceField from './UserReferenceField';
import ManagerReferenceField from './ManagerReferenceField';
import IssueCategoryField from './IssueCategoryField';

import rowStyle from '../components/rowStyle';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const IssueListDesktop = ({ permissions, ...props }) => {
    const classes = useListStyles();
    
    return (
        <Datagrid
            rowClick="edit"
            rowStyle={rowStyle}
            classes={{ headerRow: classes.headerRow }}
            optimized
            {...props}
        >
            <DateField source="date" />
            <IssueStatusField source="status" />
            <IssueCategoryField source="category" />
            <UserReferenceField link={false} />
            <ManagerReferenceField link={false} />
        </Datagrid>
    );
};

export default IssueListDesktop;
