import React, { Fragment, useCallback } from 'react';
import classnames from 'classnames';
import { List } from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import { Drawer, useMediaQuery, makeStyles } from '@material-ui/core';

import AlbaranListMobile from './AlbaranListMobile';
import AlbaranListDesktop from './AlbaranListDesktop';
import AlbaranFilter from './AlbaranFilter';
import AlbaranEdit from './AlbaranEdit';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        // marginRight: 400,
    },
    drawerPaper: {
        zIndex: 100,
    },
}));

const AlbaranList = ({ permissions, ...props }) => {
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const history = useHistory();

    const handleClose = useCallback(() => {
        history.push('/albaranes');
    }, [history]);

    const filter = {};

    if (permissions) {
        if (!(permissions.purchaseAdmonObra || permissions.foreman || permissions.role.type === 'manager')) {
            filter.user = permissions.id;
        }
    }

    return (
        <div className={classes.root}>
            <Route path="/albaranes/:id">
                {({ match }) => {
                    const isMatch = !!(
                        match &&
                        match.params &&
                        match.params.id !== 'create' &&
                        permissions
                    );
                    if (permissions) {
                      return (
                        <Fragment>
                            <List
                                {...props}
                                className={classnames(classes.list, {
                                    [classes.listWithDrawer]: isMatch,
                                })}
                                filters={<AlbaranFilter />}
                                filter={filter}
                                perPage={25}
                                sort={{ field: 'date', order: 'DESC' }}
                            >
                                {isXSmall ? (
                                    <AlbaranListMobile permissions={permissions} />
                                ) : (
                                    <AlbaranListDesktop permissions={permissions} />
                                )}
                            </List>
                            <Drawer
                                variant="persistent"
                                open={isMatch}
                                anchor="right"
                                onClose={handleClose}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                                {isMatch ? (
                                    <AlbaranEdit
                                        id={match.params.id}
                                        onCancel={handleClose}
                                        permissions={permissions}
                                        {...props}
                                    />
                                ) : null}
                            </Drawer>
                        </Fragment>
                    );
                  } else {
                    return (<></>);
                  }
                }}
            </Route>
        </div>
    );
};

export default AlbaranList;
