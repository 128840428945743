import React, { Fragment } from 'react';
import MuiToolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import { SaveButton } from 'react-admin';
import AcceptButton from './AcceptButton';
import AddCommentButton from './AddCommentButton';

const useStyles = makeStyles({
    root: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const ComplaintEditToolbar = ({
    handleSubmitWithRedirect,
    invalid,
    record,    
    saving,
    permissions
}) => {
    const classes = useStyles();
    
    return (
        <MuiToolbar className={classes.root}>
            {
                <Fragment>
                    {
                        record.status === 'new' ?
                            <SaveButton
                                handleSubmitWithRedirect={handleSubmitWithRedirect}
                                invalid={invalid}
                                saving={saving}
                                redirect="list"
                                submitOnEnter={true}
                                label='resources.complaints.action.comment'
                            /> :
                        record.status === 'in_progress' ? 
                            <>
                                <AddCommentButton record={record} resource={'complaint-comments'} />
                            </> :
                            <></>
                    }
                    {
                        permissions.complaintManager ? 
                            <AcceptButton record={record} action={'close'} resource={'complaints'} permissions={permissions} />
                        : <></>
                    }
                </Fragment>              
            }            
        </MuiToolbar>
    );

};

export default ComplaintEditToolbar;
