import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
    Create,
    SimpleForm,
    Toolbar,
    TextInput,
    ReferenceInput,
    SelectInput,
    useTranslate,
    BooleanInput,
    AutocompleteInput
} from 'react-admin';
import SaveAndUploadButton from './SaveAndUploadButton';

import ValidTaxId from '../components/ValidTaxId';
import URL from '../URL';

const CustomToolbar = props => (
    <Toolbar {...props} >
        <SaveAndUploadButton redirect="users" />
    </Toolbar>
);

const UserCreate = ({permissions, ...props}) => {
    const translate = useTranslate();
    const [roles, setRoles] = useState();
    const [role, setRole] = useState();
    const [zipCode, setZipCode] = useState();
    const [country, setCountry] = useState("Spain");
    // const [addressSearch, setAddressSearch] = useState();
    // const [address, setAddress] = useState();
    const [countries, setCountries] = useState();
    const [city, setCity] = useState();
    const [province, setProvince] = useState();

    useEffect(() => {
        if (countries) return;
        axios.get(`/regions.json`).then(({ data }) => 
            setCountries(data.map(d => ({key: d.code, id: d.name, name: d.name}))));
    });

    useEffect(() => {
        if (country && zipCode /*&& addressSearch*/) {
            const countryData = countries.find(c => c.name === country);
            if (!countryData) {
                // setAddress();
                return;
            }

            const getData = setTimeout(() => {
                axios
                // .get(`${URL}/addresses?region=${countryData.key}&postalCode=${zipCode}&address=${addressSearch}`)
                .get(`${URL}/addresses?region=${countryData.key}&postalCode=${zipCode}&address=null`)
                .then(({ data }) => {    
                    if (data && data.result) {
                        if (data.result.verdict && data.result.address && data.result.address.postalAddress) {
                            setCity(data.result.address.postalAddress.locality);
                            setProvince(data.result.address.postalAddress.administrativeArea);
                        }
                        // if (data.result.verdict && data.result.verdict.addressComplete) {
                        //     setCity(data.result.address.postalAddress.locality);
                        //     setProvince(data.result.address.postalAddress.administrativeArea);
                        //     setAddress(data.result.address.formattedAddress);
                        // } else {
                        //     setAddress();
                        // }
                    }
                });
            }, 1000);
    
            return () => clearTimeout(getData);
        }
    }, [country, zipCode, /*addressSearch,*/ countries]);

    const validateUser = values => {
        const errors = {};
        if (!values.name) {
            errors.name = translate('ra.validation.required');
        }
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }
        if (!values.organization) {
            errors.organization = translate('ra.validation.required');
        }
        if (role && role.type === 'supplier') {
            if (!values.taxid) {
                errors.taxid = translate('ra.validation.required');
            } else if (!ValidTaxId(values.taxid)) {
                errors.taxid = translate('pos.invalidTaxId');
            }
        }
        return errors;
    };

    const fetchRoles = useCallback(async () => {
      // Request organizations.
      axios.get(`${URL}/users-permissions/roles`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      }).then(function (response) {
          setRoles(response.data.roles
              .filter(r => ['validator', 'supplier', 'manager', 'employee'].includes(r.type))
              .map(r => {
                  return {key: r.id, id: r.id, type: r.type,
                      name: translate(`pos.roles.${r.type}`)};
          }));
        })
    }, [translate]);

    // useEffect(() => {
    //     if (!zipCode) return;
    //     const getData = setTimeout(() => {
    //         axios
    //         .get(`${URL}/codigos-postales?_sort=codigo_postal:ASC&_start=0&_limit=10&_q=${zipCode}`)
    //         .then((response) => {
    //             if (!response.data.length) return;
    //             const zip = response.data[0].codigo_postal;
    //             if (zip === zipCode) {
    //                 setZipData(response.data[0] || "")
    //             } else {
    //                 setZipData()
    //             }
    //         });          
    //     }, 500)

    //     return () => clearTimeout(getData);
    // }, [zipCode])

    useEffect(() => {
        if (!roles) {
          fetchRoles();
        }
    }, [roles, fetchRoles]);

    if (roles) {
      return (
          <Create {...props}>
              <SimpleForm toolbar={<CustomToolbar />} validate={validateUser} >
                  <TextInput source="name" fullWidth required />
                  <TextInput source="email" fullWidth required />
                  <ReferenceInput
                      label="resources.organizations.fields.organization"
                      source="organization._id"
                      reference="organizations"
                      fullWidth
                  >
                      <SelectInput optionText="name" required />
                  </ReferenceInput>
                  <SelectInput
                      source="role.id"
                      label="resources.users.fields.role"
                      choices={roles}
                      onChange={e => setRole(roles.find(r => r.id === e.target.value))}
                      fullWidth
                      required
                  />
                  {
                      role && role.type !== 'supplier'  ?
                        <ReferenceInput
                            source="ticketValidatorUserId"
                            reference="users"
                            label="resources.users.fields.ticketValidatorUserId"
                            filter={{'ticketValidator': true}}
                            fullWidth
                        >
                            <AutocompleteInput
                                optionText={choice =>
                                    choice && choice.id ? `${choice.name}` : ""
                                }
                            />
                        </ReferenceInput>
                      : <></>
                  }
                  {
                      role && role.type !== 'supplier' && role.type !== 'employee' ?
                        <>
                            <BooleanInput
                                label="resources.users.fields.invoice_validator"
                                source="invoiceValidator"
                                fullWidth
                            />
                            <BooleanInput
                                label="resources.users.fields.invoice_payer"
                                source="invoicePayer"
                                fullWidth
                            />
                            <BooleanInput
                                label="resources.users.fields.order_validator"
                                source="orderValidator"
                                fullWidth
                            />
                            <BooleanInput
                                label="resources.users.fields.ticket_validator"
                                source="ticketValidator"
                                fullWidth
                            />
                            <BooleanInput
                                label="resources.users.fields.ticket_payer"
                                source="ticketPayer"
                                fullWidth
                            />
                            <BooleanInput
                                label="resources.users.fields.ticket_registrar"
                                source="ticketRegistrar"
                                fullWidth
                            />
                            <BooleanInput
                                label="resources.users.fields.ticket_admin"
                                source="ticketAdmin"
                                fullWidth
                            />
                            <BooleanInput
                                label="resources.users.fields.supplier_validator"
                                source="supplierValidator"
                                fullWidth
                            />
                            <BooleanInput
                                label="resources.users.fields.issue_manager"
                                source="issueManager"
                                fullWidth
                            />
                            <BooleanInput
                                label="resources.users.fields.invoice_creator"
                                source="invoiceCreator"
                                fullWidth
                            />
                        </>
                      : <></>
                  }
                  {
                      role && role.type === 'supplier' ?
                        <>
                            <TextInput
                                source="phone"
                                label="resources.suppliers.fields.phone"
                                fullWidth
                            />
                            <TextInput
                                source="business"
                                label="resources.suppliers.fields.business"
                                fullWidth
                                required
                            />
                            <TextInput
                                source="taxid"
                                label="resources.suppliers.fields.taxid"
                                fullWidth
                                required
                            />
                            <SelectInput
                                source="country"
                                label="resources.suppliers.fields.country"
                                choices={countries}
                                onChange={e => setCountry(countries.find(r => r.id === e.target.value))}
                                initialValue={"Spain"}
                                fullWidth
                                required
                            />
                            <TextInput
                                source="zipcode"
                                label="resources.suppliers.fields.zipcode"
                                onChange={(event) => {
                                    setZipCode(event.target.value)
                                }}
                                fullWidth
                                required
                            />
                            {/* <TextInput
                                source="addressSearch"
                                label="resources.suppliers.fields.addressSearch"
                                fullWidth
                                onChange={event => {
                                    setAddressSearch(event.target.value)
                                }}
                            />
                            <TextInput
                                source="address"
                                label="resources.suppliers.fields.address"
                                initialValue={address}
                                fullWidth
                                required
                                disabled
                            /> */}
                            <TextInput
                                source="address"
                                label="resources.suppliers.fields.address"
                                fullWidth
                                required
                            />
                            <TextInput
                                source="city"
                                label="resources.suppliers.fields.city"
                                initialValue={city}
                                fullWidth
                                required
                                disabled
                            />
                            <TextInput
                                source="province"
                                label="resources.suppliers.fields.province"
                                initialValue={province}
                                fullWidth
                                disabled
                            />
                            <SelectInput
                                source="type"
                                label="resources.suppliers.fields.type"
                                fullWidth
                                required
                                choices={['supplier', 'creditor'].map((type, index) => {
                                    return {key: type, id: type, type: type,
                                        name: translate(`resources.suppliers.fields.${type}`)}
                                })}
                            />
                            <TextInput
                                source="employee"
                                label="resources.suppliers.fields.employeeRegister"
                                fullWidth
                                required
                            />
                            <SelectInput
                                source="payment"
                                label="resources.suppliers.fields.paymentRegister"
                                fullWidth
                                choices={
                                    ['confirming','transfer','note','bill','order','other'].map((type, index) => {
                                        return {key: type, id: type, type: type,
                                            name: translate(`resources.suppliers.payments.${type}`)}
                                    })
                                }
                            />
                            <SelectInput
                                source="days"
                                label="resources.suppliers.fields.days"
                                fullWidth
                                choices={['30', '45', '60', '90', '120', '150', '180'].map((day, index) => {
                                    return {key: day, id: day, name: `${day}`}
                                })}
                            />
                            <TextInput
                                source="bic"
                                label="resources.suppliers.fields.bic"
                                fullWidth
                                required
                            />
                            <TextInput
                                source="swift"
                                label="resources.suppliers.fields.swift"
                                fullWidth
                                required
                            />
                        </>
                      : <></>
                  }
              </SimpleForm>
          </Create>
      );
    } else {
        return (<></>);
    }
};

export default UserCreate;
