import React, { Fragment, useCallback } from 'react';
import classnames from 'classnames';
import { List } from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import { Drawer, useMediaQuery, makeStyles } from '@material-ui/core';
import BulkAcceptButton from './BulkAcceptButton';
import BulkRejectButton from './BulkRejectButton';

import TicketListMobile from './TicketListMobile';
import TicketListDesktop from './TicketListDesktop';
import TicketFilter from './TicketFilter';
import TicketEdit from './TicketEdit';

const TicketsBulkActionButtons = props => (
    <Fragment>
        <BulkAcceptButton {...props} />
        <BulkRejectButton {...props} />
    </Fragment>
);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        // marginRight: 400,
    },
    drawerPaper: {
        zIndex: 100,
    },
}));

const TicketList = ({ permissions, ...props }) => {
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const history = useHistory();

    const handleClose = useCallback(() => {
        history.push('/tickets');
    }, [history]);

    return (
        <div className={classes.root}>
            <Route path="/tickets/:id">
                {({ match }) => {
                    const isMatch = !!(
                        match &&
                        match.params &&
                        match.params.id !== 'create' &&
                        permissions
                    );
                    if (permissions) {
                      return (
                        <Fragment>
                            <List
                                {...props}
                                className={classnames(classes.list, {
                                    [classes.listWithDrawer]: isMatch,
                                })}
                                bulkActionButtons={
                                     permissions.role.type === 'manager'
                                  || permissions.role.type === 'validator'
                                  || permissions.ticketValidator
                                  || permissions.ticketAdmin
                                      ? <TicketsBulkActionButtons />
                                      : <></>
                                }
                                filters={
                                      permissions.role.type === 'manager'
                                   || permissions.role.type === 'validator'
                                   || permissions.ticketValidator
                                   || permissions.ticketAdmin
                                       ? <TicketFilter />
                                       : <></>
                                }
                                filterDefaultValues={{ employee: true }}
                                filter={
                                    permissions.role.type === 'manager' || permissions.ticketAdmin ?
                                      {} :
                                    permissions.ticketValidator || permissions.ticketPayer ?
                                      {} :
                                    permissions.role.type === 'employee' || permissions.role.type === 'validator' ?
                                      { employee: permissions.name } : { user: 'nobody' }
                                }
                                perPage={25}
                                sort={{ field: 'date', order: 'DESC' }}
                            >
                                {isXSmall ? (
                                    <TicketListMobile permissions={permissions} />
                                ) : (
                                    <TicketListDesktop permissions={permissions} />
                                )}
                            </List>
                            <Drawer
                                variant="persistent"
                                open={isMatch}
                                anchor="right"
                                onClose={handleClose}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                                {isMatch ? (
                                    <TicketEdit
                                        id={match.params.id}
                                        onCancel={handleClose}
                                        permissions={permissions}
                                        {...props}
                                    />
                                ) : null}
                            </Drawer>
                        </Fragment>
                    );
                  } else {
                    return (<></>);
                  }
                }}
            </Route>
        </div>
    );
};

export default TicketList;
