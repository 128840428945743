import React from 'react';
import { useTranslate } from 'react-admin';

const PurchaseTypeField = props => {
    const translate = useTranslate();
    if (props.record.type === 'urgent') {
        return <>{`${translate('resources.purchases.type.urgent')}`}</>;
    } else if (props.record.type === 'less') {
        return <>{`${translate('resources.purchases.type.less')} ${props.record.organization.purchaseLessThan}€`}</>;
    } else if (props.record.type === 'between') {
        return <>{`${translate('resources.purchases.type.less')} ${props.record.organization.purchaseMoreThan}€`}</>;
    } else if (props.record.type === 'more') {
        return <>{`${translate('resources.purchases.type.more')} ${props.record.organization.purchaseMoreThan}€`}</>;
    } else if (props.record.type === 'open_order') {
        return <>{`${translate('resources.purchases.type.open_order')}`}</>;
    } else if (props.record.type === 'invoice') {
        return <>{`${translate('resources.purchases.type.invoice')}`}</>;
    } else {
      return <>{`🚨${translate('pos.unknown')}`}</>;
    }
};

PurchaseTypeField.defaultProps = {
    source: 'purchase',
    label: 'resources.purchases.fields.type',
    addLabel: true,
};

export default PurchaseTypeField;
