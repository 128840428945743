import React from 'react';
import {
    AutocompleteInput,
    DateInput,
    Filter,
    ReferenceInput,
    TextInput,
    SelectInput,
    useTranslate,
} from 'react-admin';
import { makeStyles, Chip } from '@material-ui/core';

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

const useFilterStyles = makeStyles({
    status: { width: 150 },
});

const PurchaseFilter = props => {
    const classes = useFilterStyles();
    const translate = useTranslate();

    return (
        <Filter {...props}>
            <TextInput source="q" label="resources.purchases.filters.ot" alwaysOn />
            <SelectInput
                source="status"
                choices={[
                    { id: 'pending', name: translate("resources.purchases.status.pending") },
                    { id: 'accepted', name: translate("resources.purchases.status.accepted") },
                    { id: 'approved', name: translate("resources.purchases.status.approved") },
                    { id: 'rejected', name: translate("resources.purchases.status.rejected") },
                    { id: 'rejected_payout', name: translate("resources.purchases.status.rejected_payout") },
                    { id: 'telematel', name: translate("resources.purchases.status.telematel") },
                ]}
                className={classes.status}
            />
            <QuickFilter source="payed" label="resources.purchases.fields.payed" defaultValue={true} />
            <SelectInput
                source="type"
                choices={[
                    { id: 'urgent', name: translate("resources.purchases.type.urgent") },
                    { id: 'open_order', name: translate("resources.purchases.type.open_order") },
                    { id: 'less', name: translate("resources.purchases.type.less") },
                    { id: 'between', name: translate("resources.purchases.type.between") },
                    { id: 'more', name: translate("resources.purchases.type.more") },
                    { id: 'invoice', name: translate("resources.purchases.type.invoice") },
                ]}
                className={classes.status}
            />
            <ReferenceInput
                source="organization"
                reference="organizations"
                label={translate("resources.organizations.fields.organization")}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
            source="supplier"
            reference="suppliers"
            label={translate("resources.suppliers.fields.supplier")}
            >
                <AutocompleteInput optionText="business" />
            </ReferenceInput>
            <ReferenceInput
            source="user"
            reference="users"
            label={translate("resources.tickets.fields.employee")}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <DateInput source="createdAt_gte" label="resources.purchases.filters.createdAt_gte" />
            <DateInput source="createdAt_lte" label="resources.purchases.filters.createdAt_lte" />
        </Filter>
    );
};

export default PurchaseFilter;
