import React from 'react';

const Logo = props => (
    <svg
        width={172.275}
        height={43.725}
        viewBox="0 0 895.83 227.37"
        {...props}
    >
            <g
                aria-label="~Campos~"
                style={{
                    lineHeight: 1.25,
                }}
                fontWeight={400}
                fontSize={7.056}
                fontFamily="Verdana"
                letterSpacing={0}
                wordSpacing={0}
                strokeWidth={0.265}
                fill="#000"
            >
                <path fill="#000" d="M855.12,127.52l-63.56-37.35c-4.07-2.39-3.52-6.1-3.09-7.58c0.43-1.48,1.93-4.93,6.69-4.93h65.13
                    c5.63,0,10.18-4.42,10.18-9.88c0-5.46-4.56-9.88-10.18-9.88h-65.13c-12.4,0-22.97,7.79-26.31,19.38
                    c-3.33,11.59,1.55,23.57,12.16,29.8l63.56,37.35c4.07,2.39,3.52,6.1,3.09,7.58c-0.43,1.48-1.93,4.93-6.69,4.93h-65.13
                    c-5.62,0-10.18,4.42-10.18,9.88c0,5.46,4.56,9.89,10.18,9.89h65.13c12.4,0,22.98-7.79,26.31-19.38
                    C870.61,145.73,865.73,133.75,855.12,127.52 M726.66,117.3c0-21.86-18.32-39.64-40.84-39.64c-22.52,0-40.84,17.78-40.84,39.64
                    s18.32,39.64,40.84,39.64C708.34,156.94,726.66,139.16,726.66,117.3 M747.03,117.3c0,32.76-27.46,59.41-61.21,59.41
                    c-33.75,0-61.21-26.65-61.21-59.41s27.46-59.4,61.21-59.4C719.57,57.9,747.03,84.55,747.03,117.3 M571.07,57.9h-76.54
                    c-5.62,0-10.18,4.42-10.18,9.88c0,5.46,4.56,9.88,10.18,9.88h76.54c8.45,0,15.33,6.67,15.33,14.88c0,8.2-6.88,14.88-15.33,14.88
                    h-48.75c-5.62,0-10.19,4.43-10.19,9.88v49.52c0,5.46,4.56,9.89,10.19,9.89c5.63,0,10.18-4.43,10.18-9.89v-39.64h38.57
                    c19.69,0,35.7-15.54,35.7-34.64C606.77,73.44,590.75,57.9,571.07,57.9 M482.77,162.08c2.7,4.79,0.89,10.8-4.05,13.42
                    c-1.55,0.83-3.23,1.21-4.88,1.21c-3.6,0-7.1-1.86-8.95-5.14l-46.89-83.19l-46.89,83.19c-1.79,3.17-5.21,5.14-8.94,5.14
                    c-3.72,0-7.15-1.97-8.93-5.14l-46.9-83.19l-46.89,83.19c-1.7,3.02-4.9,4.96-8.44,5.13c-3.55,0.17-6.92-1.47-8.91-4.32l-60.98-87.02
                    l-45.8,65.35c0,0,0,0,0,0c0,0,0,0,0,0c-11.42,16.28-30.35,25.99-50.61,25.99c-33.75,0-61.21-26.65-61.21-59.41S50.95,57.9,84.7,57.9
                    c12.26,0,24.1,3.51,34.23,10.15c4.66,3.05,5.89,9.2,2.74,13.72c-3.15,4.52-9.48,5.71-14.14,2.65c-6.75-4.42-14.64-6.76-22.83-6.76
                    c-22.52,0-40.84,17.78-40.84,39.64s18.32,39.64,40.84,39.64c13.53,0,26.15-6.49,33.78-17.35v0l54.22-77.36
                    c1.9-2.71,5.05-4.32,8.42-4.32c3.37,0,6.53,1.62,8.42,4.32l60.04,85.68l47.84-84.87c1.79-3.17,5.21-5.14,8.93-5.14
                    c3.72,0,7.15,1.97,8.93,5.14l46.89,83.19l46.89-83.19c1.79-3.17,5.21-5.14,8.93-5.14c3.72,0,7.15,1.97,8.94,5.14L482.77,162.08z"/>
                <path fill="#07A990" d="M189.54,138.93c-1.9-2.7-5.05-4.33-8.42-4.33c-3.37,0-6.53,1.62-8.42,4.33l-15.65,22.34
                    c-2.12,3.03-2.35,6.95-0.58,10.18c1.76,3.24,5.23,5.26,9,5.26h31.31c3.77,0,7.24-2.02,9-5.26c1.76-3.24,1.54-7.15-0.58-10.18
                    L189.54,138.93z"/>
            </g>
    </svg>
);

export default Logo;
