import React from 'react';
import { Datagrid, TextField, DateField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import OrganizationReferenceField from '../organizations/OrganizationReferenceField';
import UserReferenceField from '../users/UserReferenceField';

import rowStyle from '../components/rowStyle';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const BudgetListDesktop = props => {
    const classes = useListStyles();
    return (
        <Datagrid
            rowClick="edit"
            rowStyle={rowStyle}
            classes={{ headerRow: classes.headerRow }}
            optimized
            {...props}
        >
            <DateField source="createdAt" locales="es-ES" />
            <TextField source="description" />
            <OrganizationReferenceField link={false} />
            <UserReferenceField link={false} />
            <TextField source="ot" />
        </Datagrid>
    );
};

export default BudgetListDesktop;
