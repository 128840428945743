import React, { useState, useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { SaveButton,
    useNotify,
    usePermissions,
    useRefresh
} from 'react-admin';
import axios from 'axios';

import URL from '../URL';

const AddCommentButton = props => {
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    // get the form state
    const formState = useFormState();
    const { permissions } = usePermissions();
    const refresh = useRefresh()

    const handleClick = useCallback( () => {
        const sendComment = () => {
            setLoading(true);    
            // Request API.
            axios.post(`${URL}/complaint-comments`, {
                  author: permissions?.id,
                  complaintId: props.record?.id,
                  comment: formState.values.comment
              })
              .then(() => {
                  setLoading(false);
                  notify(
                      `pos.comment_sent`,
                      'info',
                      {},
                      false
                  );
                  refresh();
              })
              .catch(error => {
                  setLoading(false);
                  notify(
                      typeof error === 'string'
                          ? error
                          : typeof error === 'undefined' || !error.message
                          ? 'resources.issues.notification.comment_error'
                          : error.message,
                      'warning'
                  );
              });
        };

        if (formState.valid) {
            setLoading(true);
            sendComment();
        } else if (!formState.values.comment) {
            notify(
                'pos.comment_required',
                'warning'
            );
        }
    }, [ formState.valid, formState.values.comment, notify, permissions, props.record, refresh ]);

    return <SaveButton
            {...props}
            saving={loading}
            handleSubmitWithRedirect={handleClick}
            label="resources.complaints.fields.commento"
        />;
};


export default AddCommentButton;
