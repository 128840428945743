import React from 'react';
import { Datagrid, DateField, BooleanField, TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import OrganizationReferenceField from '../organizations/OrganizationReferenceField';
// import ManagerReferenceField from './ManagerReferenceField';
import PurchaseTypeField from './PurchaseTypeField';
import UserReferenceField from '../users/UserReferenceField';
import PurchaseStatusField from './PurchaseStatusField';
import rowStyle from '../components/rowStyle';
import SupplierReferenceField from '../suppliers/SupplierReferenceField';
import PurchaseAmountReferenceField from './PurchaseAmountReferenceField';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const PurchaseListDesktop = ({ permissions, ...props }) => {
    const classes = useListStyles();
    return (
        <Datagrid
            rowClick="edit"
            rowStyle={rowStyle}
            classes={{ headerRow: classes.headerRow }}
            optimized
            {...props}
        >
            <DateField source="createdAt" />
            <UserReferenceField label="resources.purchases.fields.user" link={false} />
            <OrganizationReferenceField link={false} />
            <TextField source="ot" />
            <SupplierReferenceField link={true} />
            <PurchaseAmountReferenceField source="offers" />
            <PurchaseStatusField source="status" />
            <PurchaseTypeField link={false} />
            <BooleanField source="payed" />
        </Datagrid>
    );
};

export default PurchaseListDesktop;
