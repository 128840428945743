import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const BudgetUserReferenceField = props => {
    if (props.record.budget) {
        return (
            <ReferenceField
                source="budget.user"
                reference="users"
                {...props}
            >
                <TextField source="name" />
            </ReferenceField>
        );
    } else {
        return <>{props.record.itemDesc}</>
    }
    

};

BudgetUserReferenceField.defaultProps = {
    source: 'budget.user',
    label: 'resources.users.fields.user',
    addLabel: true,
};

export default BudgetUserReferenceField;
