import React from 'react';
import { useSelector } from 'react-redux';
import { Layout, Sidebar } from 'react-admin';
import AppBar from './AppBar';
import { darkTheme, lightTheme } from './themes';
import { isPublicRoute } from '../routes';

const CustomSidebar = props => <Sidebar {...props} />;

export default props => {
    lightTheme.sidebar = {
        width: isPublicRoute(window.location.hash) ? 0 : 240, // The default value is 240
    };
    const theme = useSelector(state =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );

    return (
        <Layout
            {...props}
            appBar={AppBar}
            sidebar={CustomSidebar}
            theme={theme}
        />
    );
};
