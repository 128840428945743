import React from 'react';
import { Datagrid/*, DatagridBody*/, DateField, TextField, ReferenceField, BooleanField } from 'react-admin';
// import TableCell from '@material-ui/core/TableCell';
// import TableRow from '@material-ui/core/TableRow';
// import Checkbox from '@material-ui/core/Checkbox';

import { makeStyles } from '@material-ui/core/styles';

import OrganizationReferenceField from '../organizations/OrganizationReferenceField';
import TicketStatusField from './TicketStatusField';
import TicketExpenseTypeField from './TicketExpenseTypeField';
import TicketPaymentMethodField from './TicketPaymentMethodField';
import UserReferenceField from '../users/UserReferenceField';
import FullNameField from '../components/FullNameField';

import rowStyle from '../components/rowStyle';

// const CustomDatagridRow = ({ record, resource, id, onToggleItem, children, selected, basePath }) => {    
//     return ( <TableRow key={id}>
//         {/* first column: selection checkbox */}
//         <TableCell padding="none">
//             <Checkbox
//                 disabled={record.selectable}
//                 checked={selected}
//                 onClick={() => onToggleItem(id)}
//             />
//         </TableCell>
//         {/* data columns based on children */}
//         {React.Children.map(children, field => {
//             const _record = { ...record };
//             if (field.props.source === 'payer.id' || field.props.source === 'registrar.id') {
//                 if (!record.user)
//                     if (_record.payer)
//                         delete _record.payer.id;
//                     if (_record.registrar)
//                         delete _record.registrar.id;
//                     return (
//                         <TableCell key={`${id}-${field.props.source}`}>
//                             {React.cloneElement(field, {
//                                 record: _record,
//                                 basePath,
//                                 resource,
//                             })}
//                         </TableCell>
//                     );
//             }
//             return (
//                 <TableCell key={`${id}-${field.props.source}`}>
//                     {React.cloneElement(field, {
//                         record,
//                         basePath,
//                         resource,
//                     })}
//                 </TableCell>
//             );
//         })}
//     </TableRow> );
// };

// const CustomDatagridBody = props => <DatagridBody {...props} row={<CustomDatagridRow />} />;
// const CustomDatagrid = props => <Datagrid {...props} body={<CustomDatagridBody />} />;

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const TicketListDesktop = ({ permissions, ...props }) => {
    const classes = useListStyles();

    return (
        <Datagrid
            rowClick="edit"
            rowStyle={rowStyle}
            classes={{ headerRow: classes.headerRow }}
            optimized
            {...props}
        >
            <DateField source="invoiceDate" />
            <DateField source="date" />
            <TextField source="employee" />
            <TicketPaymentMethodField source="paymentMethod" />
            <TicketExpenseTypeField source="expenseType" />
            <OrganizationReferenceField link={false} />
            <TextField source="amount" />
            <TextField source="ot" />
            <TicketStatusField source="status" />
            <UserReferenceField
                soruce="user"
                label="resources.tickets.fields.user"
                link={false}
            />
            <ReferenceField
                source="registrar.id"
                reference="users"
                label="resources.tickets.fields.registrar"
                link={false}
                {...props}
            >
                <FullNameField />
            </ReferenceField>
            <ReferenceField
                source="payer.id"
                reference="users"
                label="resources.tickets.fields.payer"
                link={false}
                {...props}
            >
                <FullNameField />
            </ReferenceField>
            <BooleanField source="paid" />
        </Datagrid>
    );
};

export default TicketListDesktop;
