import React from 'react';
import { useFormState } from 'react-final-form';
import { ReferenceInput, SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import OfferQuickCreateButton from './OfferQuickCreateButton';
import OfferQuickPreviewButton from './OfferQuickPreviewButton';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center'
    }
});

const spySubscription = { values: true };

const OfferReferenceInput = props => {
    const classes = useStyles();
    // const [version, setVersion] = useState(0);
    const { values } = useFormState({ subscription: spySubscription });
    // const handleChange = useCallback(() => setVersion(version + 1), [version]);

    // `offer[0]` 0 is the 7th char
    const offerIndex = parseInt(props.source[7]);

    return (
        <div className={classes.root}>
            <ReferenceInput key={props.source} sort={{ field: 'createdAt', order: 'DESC' }} {...props}>
                <SelectInput
                    optionText={record => `${record.amount}€ - ${record.supplierName} - ${(new Date(record.createdAt)).toLocaleString()}`}
                    disabled
                />
            </ReferenceInput>

            <OfferQuickCreateButton onChange={() => null} source={props.source} type={props.type} />
            {values.offers && values.offers[offerIndex] && <OfferQuickPreviewButton id={values.offers[offerIndex]} />}
        </div>
    );
};

export default OfferReferenceInput;
