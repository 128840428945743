import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';

const AnnouncementCategoryField = ({ source, record = {} }) => {
    const translate = useTranslate();
    return (
        <span>{ translate(`resources.announcements.categories.${record[source]}`) } </span>
      );
};

AnnouncementCategoryField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default AnnouncementCategoryField;
