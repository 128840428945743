import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    useEditController,
    useTranslate,
    SimpleForm,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    required,
    usePermissions
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import URL from '../URL';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
    a: {
        textDecoration: 'none'
    }
}));

const BudgetEdit = ({ onCancel, ...props }) => {
    const classes = useStyles();
    const controllerProps = useEditController(props);
    const translate = useTranslate();
    const perms = usePermissions();
    const [loading, setLoading] = useState();
    const [contracts, setContracts] = useState();

    useEffect(() => {
        setLoading(true);
        const fetchContracts = async () => {
            if (!controllerProps.record) return;
            // Request contracts
            try {
                const response = await axios.get(`${URL}/contracts?budget.id=${controllerProps.record.id}`, {
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
                });
                const _contracts = response.data.filter(c => c.valid && c.signed && c.type !== 'admon');
    
                if (_contracts.length) {
                    setContracts(
                        _contracts
                        .map(c => {
                            return {key: c.id, id: c.id, name: `${c.number} - ${c.description}`};
                        })
                    );
                } else {
                    setContracts([]);
                }
            } catch (error) {
                console.log(error.message);
                setContracts([]);
            }
            setLoading(false);
        };

        fetchContracts();

    }, [controllerProps.record]);    

    if (!controllerProps.record) {
        return <></>;
    }

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6">
                    {translate('resources.invoices.detail')}
                </Typography>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <SimpleForm
                className={classes.form}
                basePath={controllerProps.basePath}
                record={controllerProps.record}
                save={controllerProps.save}
                version={controllerProps.version}
                redirect="list"
                resource="budgets"
            >
                <TextInput source="ot" fullWidth />
                <TextInput
                    source="description"
                    label={translate('resources.budgets.fields.description')}
                    validate={required()}
                    maxRows={25}
                    multiline
                    fullWidth
                />
                {
                    perms.loaded && perms.permissions.role.type === 'manager' ?
                        <ReferenceInput
                            label="resources.budgets.fields.user"
                            source="user._id"
                            reference="users"
                            fullWidth
                        >
                            <AutocompleteInput optionText="name" validate={required()} />
                        </ReferenceInput>
                    : <></>
                }
                {
                    loading ?
                        <>
                            <Typography variant="h6">                    
                                <small>{translate('pos.loading.contracts')}</small>
                            </Typography>
                        </> :
                        <>
                            {
                                contracts && contracts.length ?
                                    <Typography variant="h6">
                                        {translate('resources.suppliers.fields.contracts')}
                                    </Typography> : 
                                    <Typography variant="h6">
                                        {translate('resources.budgets.no_contracts')}
                                    </Typography>
                            }
                            <ol>
                            {
                                contracts && contracts.map(c => 
                                    <li key={c.id}>
                                        <a className={classes.a} href={`/#/contracts/${c.id}`} target="_blank" rel="noopener noreferrer">{c.name}</a>
                                    </li>
                                )
                            }
                            </ol>
                        </>

                }
            </SimpleForm>
        </div>
    );
};

export default BudgetEdit;
