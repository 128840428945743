import React from 'react';
import { ReferenceField } from 'react-admin';

import FullNameField from '../components/FullNameField';

const OrganizationReferenceField = props => {
  return (
    props.record && props.record.organization ?
      <ReferenceField source="organization.id" reference="organizations" {...props}>
          <FullNameField />
      </ReferenceField>
    : <>-</>
)};

OrganizationReferenceField.defaultProps = {
    source: 'organization.id',
    label: 'resources.organizations.fields.organization',
    addLabel: true,
};

export default OrganizationReferenceField;
