import spanishMessages from '@blackbox-vision/ra-language-spanish';

export default {
    ...spanishMessages,
    pos: {
        search: 'Buscar',
        configuration: 'Configuración',
        language: 'Idioma',
        login: 'Inicia sesión',
        signup: 'Introduce los datos de registro',
        email: "Email",
        login_link: 'Iniciar sesión',
        signup_link: 'Registrarse',
        forgot_password: 'Restaurar contraseña',
        code: 'Code',
        submit: 'Enviar',
        email_code: 'Código',
        send_code: 'Enviar código',
        code_sent: 'Código enviado',
        reset_password_menu: 'Contraseña',
        reset_password: 'Elige una nueva contraseña para tu cuenta',
        send_code_desc: 'Escribe tu email y pulsa ENVIAR CÓDIGO. Recibirás un email con el cógigo que necesitarás a continuación.',
        password_changed: 'Contraseña cambiada',
        choosepassword: 'Elige una contraseña',
        repassword: 'Repite la contraseña',
        notmatch: 'La contraseña no coincide',
        tooshort: 'La contrseña debe tener al menos 6 caracteres',
        success: 'El registro se ha realizado con éxito! Por favor, espera a recibir un correo de validación',
        upload_failed: 'Ha fallado la subida de archivos',
        saving: 'Guardando',
        saving_failed: 'Falló el guardado',
        wrong_date: 'La fecha de la factura es anterior a 4 días',
        uploading: 'Subiendo archivos',
        avatar: 'Avatar',
        name: 'Nombre',
        phone: 'Teléfono',
        save: 'Guardar',
        profile: 'Perfil de usuario',
        autoTicketValidation: 'Validación automática de tickets',
        advanced: 'Avanzado',
        supplierProfile: 'Perfil de proveedor',
        unknown: 'Desconocido',
        to: 'a',
        nopayment: 'Sin forma de pago',
        invalidTaxId: 'El CIF no es válido',
        borot: '*Presupuesto u OT (7 o 14 dígitos)',
        upload_remove_help: 'Pulsar el botón rojo para eliminar algún archivo adjuntado',
        comment_required: '\n\nEl comentario es obligatorio\n\n',
        comment_order_number_required: 'El número de pedido es obligatorio al cerrar',
        email_exists: 'El email ya existe en la base de datos',
        edit_comment: 'Editar comentario',
        comment_sent: 'Comentario enviado',
        invalid_bic: 'El IBAN no es válido',
        changed: 'Actualizado',
        csvok: 'El fichero se ha procesado correctamente, recibirás un correo con los resultados',
        see_invoices: "Ver facturas de ",
        supplierLink: "Enlace al proveedor",
        relatedUsers: "Usuarios relacionados",
        return: "Volver",
        theme: {
            name: 'Tema',
            light: 'Claro',
            dark: 'Oscuro',
        },
        roles: {
            public: 'Público',
            authenticated: 'Autenticado',
            supplier: 'Proveedor',
            employee: 'Empleado',
            manager: 'Administrador',
            validator: 'Validador',
            tickets_post: 'API POST'
        },
        dashboard: {
            monthly_revenue: 'Facturación Mensual',
            new_orders: 'Nuevos Pedidos',
            pending_invoices: 'Facturas Pendientes',
            pending_announcements: 'Anuncios pendientes',
            new_suppliers: 'Nuevos Proveedores',
            pending_suppliers: 'Proveedores Pendientes',
            paymentPendingSuppliers: 'Proveedores pendientes de validación de forma de pago',
            order: {
                items:
                    'por %{supplier_name}, un item |||| por %{supplier_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Terminos y condiciones de Campos Facturación',
                subtitle:
                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                aor_button: 'Web de Campos Corporación',
                demo_button: 'Fuentes de la aplicación',
            }
        },
        menu: {
            sales: 'Administración',
            catalog: 'Catálogo',
            suppliers: 'Proveedores',
            admin: 'Configuración',
            comm: 'Comunicaciones'
        },
        invoice_status: {
          pending: "Pendiente",
          accepted: "Aceptada",
          rejected: "Rechazada",
          accounted: "Contabilizada",
          delivery_note_pending: "Pendiente de albarán (Campos)",
          rejectadmin: "Rechazada (Admon)",
          payed: "Pagada"
        },
        supplier_status: {
          payment_confirm: "Pendiente Forma de Pago",
          pending: "Pendiente",
          accepted: "Aceptado",
          rejected: "Rechazado",
        },
        purchase_status: {
            pending: "Pendiente",
            accepted: "Aceptada",
            rejected: "Rechazada",
        },
        loading: {
            orders: 'Cargando pedidos…',
            items: 'Cargando partidas…',
            noItems: 'No se encuentran partidas. (Para introducir una manualmente, el nombre debe ser de al menos 15 caracteres)',
            budgets: 'Cargando presupuestos…',
            contracts: 'Cargando contratos…',
            no_orders: 'No tienes pedidos',
            no_budgets: 'No tienes presupuestos',
            no_contracts: 'No tienes contratos',
        }
    },
    resources: {
        suppliers: {
            name: 'Proveedor |||| Proveedores',
            nonFinalPaymentMethod: "⚠️ Método de pago NO definitivo",
            finalPaymentMethod: "✓ Método de pago definitivo",
            link: "Vincular",
            fields: {
                supplier: 'Proveedor',
                creditor: 'Acreedor',
                type: '¿Proveedor o Acreedor?',
                commands: 'Pedidos',
                groups: 'Segmentos',
                last_seen_gte: 'Última visita',
                name: 'Nombre',
                total_spent: 'Gasto total',
                address: 'Dirección',
                addressSearch: 'Buscar dirección, por ejemplo "Calle Primera, 1, 1A"',
                city: 'Ciudad',
                province: 'Provincia',
                zipcode: 'Código postal',
                email: "Email",
                phone: "Teléfono",
                business: "Nombre de la empresa",
                country: "País",
                taxid: "CIF",
                notaxid: "SIN CIF",
                employee: "Contacto en Campos",
                employeeRegister: "Empleado de Campos con el que has contactado",
                paymentMethod: "Descripción de la forma de pago acordada",
                terms: "Acepto la política de privcidad de Campos Corporación",
                status: 'Estado',
                comment: 'Comentario',
                rating: 'Valoración',
                budgets: 'Presupuestos',
                contracts: 'Contratos',
                payment: 'Forma de pago',
                paymentRegister: 'Forma de pago acordada',
                days: 'Días',
                "0": "0",
                "15": "15",
                "30": "30",
                "45": "45",
                "60": "60",
                "90": "90",
                "120": "120",
                "150": "150",
                "180": "180",
                bic: 'Número de cuenta',
                swift: 'Código BIC/SWIFT',
                createdAt: 'Alta',
                approvedBy: 'Aprobado o Rechazado por',
                paymentMethodComment: "Comentario sobre la forma de pago",
                paymentMethodFinal: "Forma de pago definitiva",
                paymentMethodNonFinal: "Sin forma de pago definitiva",
                purchaseFamily: "Familia de compras"
            },
            action: {
                accept: 'Aceptar',
                reject: 'Rechazar',
                acceptPaymentMethod: 'Aceptar forma pago'
            },
            notification: {
                approved_success: 'Proveedor aceptado',
                approved_error: 'Error: Proveedor no aprobado',
                rejected_success: 'Proveedor rechazado',
                rejected_error: 'Error: Proveedor no rechazado',
                taxid_match: 'Algún CIF no coincide. Por favor, revísalo y vuelve a intentarlo.'
            },
            payments: {
                'confirming': 'Confirming',
                'transfer': 'Transferencia',
                'note': 'Pagaré',
                'bill': 'Recibo',
                'order': 'Giro',
                'other': 'Otro',
                'undefined': 'Otro',
            },
            filters: {
                name: 'Buscar por nombre proveedor'
            },
            purchaseFamilies: {
                acabado: "Acabado",
                clima: "Clima",
                construccion: "Construccion",
                electricidad: "Electricidad",
                ferreteria: "Ferreteria",
                fontaneria: "Fontaneria",
                hierro: "Hierro",
                madera: "Madera",
                pci: "Pci",
                renovables: "Renovables",
                vehiculos: "Vehiculos"
            }
        },
        commands: {
            name: 'Pedido |||| Pedidos',
            title: 'Pedido %{reference}',
            fields: {
                basket: {
                    delivery: 'Entrega',
                    reference: 'Referencia',
                    quantity: 'Cantidad',
                    sum: 'Sum',
                    tax_rate: 'Tipo impositivo',
                    total: 'Total',
                    unit_price: 'Precio unitario',
                },
                supplier_id: 'Proveedor',
                date_gte: 'Aprobado desde',
                date_lte: 'Aprobado antes',
                total_gte: 'Cantidad mínima',
                status: 'Estado',
                returned: 'Devuelto',
            },
        },
        invoices: {
            name: 'Factura |||| Facturas',
            detail: 'Detalle',
            detail_purchase: 'Detalle - Factura desde compras',
            no_budget: 'Por ahora no tienes ningún pedido o contrato asignado.',
            cannot_create: 'Lo sentimos. A tu usuario no se le permite crear facturas.',
            my_invoices: 'Mis facturas',
            new: 'Registrar una nueva factura',
            fields: {
                date: 'Fecha factura',
                updatedAt: 'Fecha modificación',
                supplier_id: 'Proveedor',
                order: 'Pedido',
                date_gte: 'Fecha mayor que',
                date_lte: 'Fecha menor que',
                total_gte: 'Cantidad mínima',
                address: 'Dirección',
                rating: 'Valoración',
                amount: 'Importe',
                status: 'Estado',
                images: 'Adjuntar documentos (PDFs o Imagenes) *',
                budget: 'Presupuesto',
                supplier: 'Proveedor',
                comment: 'Comentario',
                number: 'Número de factura',
                contract: 'Contrato',
                type: 'Tipo de factura',
                deliveryNote: 'Albarán',
                validationDate: 'Fecha de validación',
                validator: 'Validador',
                accountDate: 'Contabilizada el',
                accounter: 'La contabilizó',
                createdAt: 'Registrada el',
                notifySupplier: 'Notificar al proveedor al rechazar',
                paymentBank: 'Pagada por banco',
                ot: "OT",
                purchase: 'Compra'
            },
            action: {
                accept: 'Aceptar',
                reject: 'Rechazar',
                account: 'Contabilizar',
                pay: 'Pagar'
            },
            notification: {
                approved_success: 'Factura aceptada',
                approved_error: 'Error: Factura no aprobada',
                rejected_success: 'Factura rechazada',
                rejected_error: 'Error: Factura no rechazada',
            },
            tabs: {
                image: 'Imagen',
                details: 'Detalles',
                description: 'Descripción',
                announcements: 'Comunicaciones',
            },
            type: {
                budget: "Presupuesto",
                contract: "Contrato",
                order: "Pedido",
                payment: "Abono",
                orderless: "Sin pedido"
            },
            filters: {
                number: 'Buscar por número de factura'
            }
        },
        budgets: {
            name: 'Presupuesto |||| Presupuestos',
            no_contracts: 'Sin contratos relacionados',
            fields: {
                budget: 'Presupuesto',
                budgets: 'Presupuestos',
                description: "Descripción",
                user: "Usuario",
                organization: "Organización",
                ot: "OT"
            },
            filters: {
                supplierName: 'Buscar por nombre proveedor'
            }
        },
        orders: {
            name: 'Pedido |||| Pedidos',
            fields: {
                code: 'Código',
                ot: "OT",
                date: "Fecha",
                amount: "Importe"
            },
        },
        organizations: {
            name: 'Organización |||| Organizaciones',
            orders: 'Configuración de Pedidos',
            tickets: 'Configuración de Tickets',
            invoices: 'Configuración de facturas',
            purchases: 'Configuración de compras',
            suppliers: 'Configuración de proveedores',
            issues: 'Configuración de incidencias',
            issueManagers: 'Gestores de incidencias',
            fields: {
                organization: 'Organización',
                name: 'Entidad',
                nameNumber: 'Código entidad',
                office: 'Delegación',
                officeNumber: 'Código delegación',
                email: 'Email',
                ocrmail: 'Email para OCR de facturas',
                avatar: 'Avatar',
                issueOrderManager: 'De pedidos',
                issueInvoiceRejectedManager: 'De facturas rechazadas',
                issuePaymetNotReceivedManager: 'De pagos no recibidos',
                issueTechnicalManager: 'De problemas técnicos',
                issueOtherManager: 'De otras incidencias',
                ticketPayer: 'Pagador de tickets',
                invoicePayer: 'Pagador de facturas',
                ticketPayerCompanyInvoice: 'Paga tickets con factura empresa',
                ticketPayerOther: 'Paga tickets de otros tipos de gasto',
                ticketRegistrarCompanyInvoice: 'Contabiliza tickets con factura empresa',
                ticketRegistrarOther: 'Contabiliza tickets de otros tipos de gasto',
                enableValidatorList: 'Habilitar lista de validadores de tickets',
                validators: "Validadores de tickets",
                purchaseLessThan: "Compras inferiores a",
                urgent: "Pago urgente",
                purchaseBetweenThan: "Compras entre",
                and: " y ",
                purchaseMoreThan: "Compras superiores a",
                orgSupplierPaymentValidator: "Validador de formas de pago"
            },
            tabs: {
                avatar: 'Avatar',
                details: 'Detalles',
            },
            notification: {
                created_error: 'Organización no creada',
            },
        },
        announcements: {
            name: 'Anuncios |||| Anuncios',
            detail: 'Detalle',
            cannot_create: 'Lo sentimos. A este usuario no se le permite crear anuncios.',
            fields: {
                title: 'Asunto',
                category: 'Destinatarios',
                description: 'Descripción',
                attachments: 'Adjuntos',
                createdAt: 'Fecha',
                users: 'No leidos'
            },
            categories: {
                all: "Todos los usuarios",
                manager: "Administradores",
                validator: "Validadores",
                employee: "Empleados",
                supplier: "Proveedores"
            },
        },
        contracts: {
            name: 'Contrato |||| Contratos',
            cannot_create: 'Lo sentimos. A tu usuario no se le permite crear contratos.',
            fields: {
                contract: 'Contrato',
                description: 'Descripción',
                contractor: 'Contratante',
                contracted: 'Contratado',
                number: 'Número',
                signed: 'Firmado',
                valid: 'Vigente',
                signDate: 'Fecha de firma',
                expiration: 'Fecha de Fin',
                files: 'Archivos',
                loanLimit: 'Límite de préstamo',
                rentalAmount: 'Importe de alquiler (mensual)',
                location: 'Ubicación física',
                notes: 'Observaciones',
                type: 'Tipo',
                amount: 'Iguala',
                expirationNotificationCount: 'Notificaciones de vencimiento',
                supplier: 'Proveedor',
                notificationDays: 'Tiempo de pre-aviso en días',
                item: 'Número de la Partida',
                itemDesc: 'Partida',
                budget: 'Presupuesto',
                items: 'Cambiar partida'
            },
            types: {
              normal: 'Automático',
              equal: 'Iguala',
              admon: 'Administrativo'
            },
            tabs: {
                contact: 'Contrato',
                sign: 'Vigencia',
                limits: 'Límites',
                notes: 'Notas',
                files: 'Archivos'
            },
            notification: {
                approved_success: 'Contrato aceptado',
                approved_error: 'Error: Contrato no aprobado',
                rejected_success: 'Contrato rechazado',
                rejected_error: 'Error: Contrato no rechazado',
            },
            filters: {
                description: 'Buscar por descripcion',
                expiration_gte: 'Fecha de expiración mayor que',
                expiration_lte: 'Fecha de expiración menor que'
            }
        },
        users: {
            name: 'Usuario |||| Usuarios',
            byDefault: 'Por defecto',
            byPaymentMethod: 'Por forma de pago',
            byExpenseType: 'Por tipo de gasto',
            block: 'Bloquear usuario',
            unblock: 'Desbloquear usuario',
            active: 'activado',
            uploadcsv: "Importar fichero CSV de usuarios",
            csvhelp: "Ejemplo de formato del fichero CSV",
            csvhelp1: "El formato es el que se obtiene por defecto en Drive si se elije la opción",
            csvhelp2: "Archivo → Descargar → Valores separados por comas (.csv, hoja actual)",
            uploadcsvb: "Importar",
            importing: "Importando usuarios…",
            fields: {
              user: 'Encargado',
              username: 'Nombre de usuario',
              email: 'Email',
              password: 'Contraseña',
              name: 'Nombre',
              bcUserId: "ID de Usuario de Business Central",
              role: 'Rol',
              organization: 'Organización',
              avatar: 'Avatar',
              ticket_validator: 'Validador de Tickets',
              ticket_payer: 'Pagos de tickets',
              ticket_registrar: 'Contabiliza tickets',
              autoTicketMaxAmount: 'Cantidad máxima para validar tickets automáticamente',
              autoCardCompany: 'Tarjeta Empresa, cantidad máxima para validar tickets automáticamente',
              autoCardPersonal: 'Tarjeta Personal/Efectivo, cantidad máxima para validar tickets automáticamente',
              autoCardCepsa: 'Tarjeta Cepsa, cantidad máxima para validar tickets automáticamente',
              autoInvoiceHotel: 'Factura Hotel, cantidad máxima para validar tickets automáticamente',
              autoCompanyInvoice: 'Factura empresa, cantidad máxima para validar tickets automáticamente',
              autoOther: 'Otro, cantidad máxima para validar tickets automáticamente',
              ticket_admin: 'Administrador de Tickets',
              invoice_validator: 'Validador de Facturas',
              order_validator: 'Validador de Pedidos',
              supplier_validator: 'Validador de Proveedores',
              supplierPaymentValidator: 'Validador de formas de pago de proveedores',
              issue_manager: 'Gestor de Incidencias',
              invoice_creator: 'Crear facturas',
              invoice_payer: 'Pagador de facturas',
              ticketValidatorUserId: 'Usuario que validada mis tickets',
              budget_validator: 'Validator de Facturas con Presupuesto',
              enableTicketNotifications: 'Habilitar notificaciones de tickets por correo',
              blocked: 'Bloqueo del usuario',
              org_manager: "Gerencia",
              org_admon: "Administración compras",
              org_admon_obra: "Administración obra",
              org_admons_obra: "Administradores de obra",
              foreman: "Jefe de Obra",
              purchase_admin: "Administración compras",
              complaintManager: "Gestor de denuncias"
            },
            notification: {
                created_success: 'Usuario creado',
                created_error: 'Error: Usuario no creado',
                supplier_created_success: 'Perfil de proveedor creado',
                supplier_created_error: 'Error: Perfil de proveedor no creado',
                role_created_success: 'Rol establecido',
                role_created_error: 'Error: Rol no establecido',
                missing: {
                  email: "El Email es obligatorio"
                }
            },
            tabs: {
                avatar: 'Avatar',
                details: 'Detalles',
            },
        },
        tickets: {
            name: 'Control de gasto',
            my_tickets: 'Mis tickets',
            validate: 'Para validar',
            not_payed: 'Para pagar',
            invoice_date_start: "Fecha de ticket inicio",
            invoice_date_end: "Fecha de ticket fin",
            date_start: "Fecha de subida inicio",
            date_end: "Fecha de subida fin",
            amount_start: "Importe mayor o igual que",
            amount_end: "Importe menor o igual que",
            paidAt: 'Pagado el',
            lastMonth: 'Desde el 15 del mes pasado',
            fields: {
                status: 'Estado',
                date: 'Fecha registro ticket',
                employee: 'Empleado',
                paymentMethod: 'Forma de Pago',
                expenseType: 'Tipo de gasto',
                email: 'Email',
                amount: 'Importe',
                invoiceDate: 'Fecha Factura',
                fileUrl: 'Enlaces',
                ot: 'Obra',
                notes: 'Observaciones',
                plate: 'Matrícula',
                user: 'Valida',
                registrar: 'Contabiliza',
                payer: 'Paga',
                paid: 'Pagado',
                organization: 'Organización',
                attachments: 'Factura/Ticket'
            },
            filter: {
                registrar: 'Para contabilizar',
            },
            notification: {
                created_success: 'Ticket created',
                created_error: 'Error: Ticket not created',
                approved_success: 'Ticket actualizado',
                approved_error: 'Error: Ticket no actualizado',
                rejected_success: 'Ticket actualizado',
                rejected_error: 'Error: Ticket no actualizado',
            },
            status: {
                pending: 'Nuevo',
                accepted: 'Validado',
                rejected: 'Rechazado',
                paid: "Pagado",
                telematel: "Apuntado en Telematel"
            },
            action: {
                accept: 'Aceptar',
                reject: 'Rechazar',
                pay: "Pagar",
                telematel: "apuntar"
            },
            expenseType: {
              company_invoice: 'Factura Empresa',
              other: 'Cualquier Otro Gasto'
            },
            paymentMethod: {
              card_company: 'Tarjeta Empresa',
              card_personal: 'Tarjeta Personal / Efectivo',
              card_cepsa: 'Tarjeta Cepsa',
              invoice_hotel: 'Factura Hotel'
            }
        },
        issues: {
            name: 'Incidencia |||| Incidencias',
            my_issues: 'Mis incidencias',
            fields: {
                category: 'Categoría',
                description: 'Descripción',
                status: 'Estado',
                attachments: 'Adjuntos',
                issue_manager: 'Gestor de la incidencia',
                comment: 'Comentar',
                comments: 'Comentarios',
                commento: 'Comentar',
                date: 'Fecha'
            },
            notification: {
                created_success: 'Incidencia creada',
                created_error: 'Error: Incidencia no creada',
                approved_success: 'Incidencia resuelta',
                approved_error: 'Error: Incidencia no resuelta',
                rejected_success: 'Incidencia rechazada',
                rejected_error: 'Error: Incidencia no rechazada',
                comment_success: 'Comentario creado',
                comment_error: 'Error: Comentario no creado',
            },
            status: {
                new: 'Nueva',
                in_progress: 'En proceso',
                resolved: 'Resuelta'
            },
            categories: {
                Order: 'Pedidos',
                InvoiceRejected: 'Facturas rechazadas',
                PaymetNotReceived: 'Pagos no recibidos',
                Technical: 'Problemas técnicos',
                Other: 'Otras incidencias'
            },
            tabs: {
                attachment: 'Adjunto',
                details: 'Detalles'
            },
            action: {
                accept: 'Cerrar',
                comment: 'Comentar'
            },
        },
        albaranes: {
            name: 'Albarán |||| Albaranes',
            misAlbaranes: 'Mis albaranes',
            notseen: 'Sin revisar',
            fields: {
                ot: 'OT',
                attachments: 'Adjuntos*',
                description: 'Descripción',
                organization: 'Organización',
                user: 'Usuario',
                checked: 'Revisado',
                createdAt: 'Creado el'
            },
            notification: {
                created_success: 'Albarán creado',
                created_error: 'Error: Albarán no creado',
            },
            filters: {
                ot: 'Buscar por OT',
                createdAt_gte: 'Fecha de creación mayor que',
                createdAt_lte: 'Fecha de creación menor que'
            }
        },
        purchases: {
            name: 'Compra |||| Compras',
            cannot_create: 'Lo sentimos. A tu usuario no se le permite crear solicitudes de compra.',
            selectOffer: 'Selecciona la oferta',
            selectedOffer: 'Oferta seleccionada',
            new: 'Registrar una nueva compra',
            selectSupplier: 'Selecciona un proveedor. Si no lo encuentras, rellena a mano los campos correspondientes:',
            supplierReg: {
                info: 'El proveedor debe estar dado de alta en demios para poder registrar la factura.',
                info2: 'Si no a parece en el cuadro de búsqueda, deberá registrarse en:',
                info3: 'https://demios.es'
            },
            fields: {
                createdAt: 'Creada',
                offers: 'Ofertas',
                supplierName: 'Nombre de la empresa',
                amount: 'Importe',
                user: 'Usuario',
                manager: 'Gerente',
                admon: 'Administración',
                admon_obra: 'Administración Obra',
                status: 'Estado',
                ot: 'OT',
                item: 'Partida',
                budget_cost: 'Coste presupuestado',
                budget_sale: 'Venta presupuestada ',
                preference: 'Preferencia',
                paymentMethod: 'Método de pago',
                days: 'Plazo de pago',
                comments: 'Comentarios/Justificación',
                rejectComment: 'Motivo de rechazo',
                orderNumber: 'Número de pedido',
                type: 'Tipo',
                payed: 'Pagada',
                justif: 'Justificante de pago'
            },
            status: {
              pending: 'Pendiente',
              accepted: 'Aceptada',
              approved: 'Aprobada',
              rejected: 'Rechazada',
              telematel: 'Cerrada',
              rejected_payout: 'Pago rechazado',
              payed: "Pagada"
            },
            tabs: {
                data: 'Datos',
                offer1: 'Oferta 1',
                offer2: 'Oferta 2',
                offer3: 'Oferta 3',
                invoice: 'Factura',
                files: 'Archivos'
            },
            type: {
                urgent: '🚨 Pago urgente con Oferta',
                less: '⏬ Menos de',
                between: '⚖️ Entre',
                and: 'y',
                more: '⏫ Más de',
                open_order: '📖 Pedido abierto',
                invoice: '🧾🚨 Pago urgente con Factura'
            },
            notification: {
                approved_success: 'Solicitud aceptada',
                approved_error: 'Error: Solicitud no aprobada',
                rejected_success: 'Solicitud rechazada',
                rejected_error: 'Error: Solicitud no rechazada',
            },
            action: {
                accept: 'Aceptar',
                reject: 'Rechazar',
                approve: 'Aprobar pago',
                telematel: 'Cerrar',
            },
            filters: {
                ot: 'Buscar por OT',
                createdAt_gte: 'Fecha de creación mayor que',
                createdAt_lte: 'Fecha de creación menor que'
            }
        },
        offers: {
            name: 'Oferta |||| Ofertas',
            fields: {
                supplierName: 'Nombre del proveedor',
                supplierEmail: 'Email del proveedor',
                amount: 'Importe',
                file: 'Fichero',
                accepted: 'Aceptada',
                paymentMethod: "Forma de pago",
                days: 'Días',
                advance: 'Porcentaje del total en primer pago',
                createdAt: 'Creada el',
                createdAt_gte: 'Fecha mayor que',
                createdAt_lte: 'Fecha menor que',
            }
        },
        complaints: {
            name: 'Denuncia |||| Denuncias',
            config: 'Denuncias',
            fields: {
                id: "Identificador de la denuncia",
                category: 'Tipo de Denuncia',
                description: 'Descripción del hecho denunciado',
                involved: 'Personas implicadas',
                status: 'Estado',
                attachments: 'Adjuntos',
                complaint_manager: 'Gestor de la incidencia',
                comment: 'Comentar',
                comments: 'Comentarios',
                commento: 'Comentar',
                date: 'Cuándo sucedió',
                was: 'Sucedió el',
                createdAt: 'Creada el'
            },
            notification: {
                created_success: 'Denuncia creada',
                created_error: 'Error: Denuncia no creada',
                approved_success: 'Denuncia resuelta',
                approved_error: 'Error: Denuncia no resuelta',
                rejected_success: 'Denuncia rechazada',
                rejected_error: 'Error: Denuncia no rechazada',
                comment_success: 'Comentario creado',
                comment_error: 'Error: Comentario no creado',
            },
            status: {
                new: 'Nueva',
                in_progress: 'En proceso',
                resolved: 'Resuelta'
            },
            categories: {
                conducta: "Conductas y acciones inmorales o ilegales para con el desempeño del trabajo",
                gestion: "Gestión inadecuada o lucrativa de los recursos empresariales",
                robo: "Robos",
                acoso: "Acoso laboral y/o sexual",
                trato: "Tratos discriminatorios o tráfico de influencias",
                informacion: "Mal uso de información clasificada",
                fraude: "Fraudes y corruptelas",
                other: "Otros",
            },
            tabs: {
                attachment: 'Adjunto',
                details: 'Detalles'
            },
            action: {
                accept: 'Cerrar',
                comment: 'Comentar'
            },
            public: {
                title: 'Servicio de denuncias anónimo de Campos Corporación',
                copy: 'Asegúrate de copiar y guardar el siguiente identificador de tu denuncia. Sólo la prodrás consultar usando este identificador:',
                findError: 'El identificador no corresponde a ninguna denuncia',
                create: "Crear",
                comite: "Comité de cumplimiento",
                user: "Yo",
                data: "Datos de la denuncia"
            }
        },
    },
};

