import React from 'react';
import {
    AutocompleteInput,
    Filter,
    ReferenceInput,
    useTranslate,
    DateInput,
    TextInput
} from 'react-admin';
import { makeStyles, Chip } from '@material-ui/core';

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

const OfferFilter = props => {
    const translate = useTranslate();

    return (
        <Filter {...props}>
            <TextInput source="q"  label="resources.budgets.filters.supplierName" fullWidth alwaysOn />
            <ReferenceInput
              source="user"
              reference="users"
              label={translate("resources.budgets.fields.user")}
            >
                <AutocompleteInput
                    optionText={choice =>
                        choice && choice.id ? `${choice.name}` : ""
                    }
                />
            </ReferenceInput>
            <ReferenceInput
              source="supplier"
              reference="suppliers"
              label={translate("resources.suppliers.fields.supplier")}
            >
                <AutocompleteInput
                    optionText={choice =>
                        choice && choice.id ? `${choice.business}` : ""
                    }
                />
            </ReferenceInput>
            <QuickFilter source="accepted" label="resources.offers.fields.accepted" defaultValue={true} />
            <DateInput source="createdAt_gte" />
            <DateInput source="createdAt_lte" />
        </Filter>
    );
};

export default OfferFilter;
