import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';

const SupplierPaymentField = ({ source, record = {} }) => {
    const translate = useTranslate();

    return (
        <span>{ record[source] ? translate(`resources.suppliers.payments.${record[source]}`) : '' } </span>
      );
};

SupplierPaymentField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default SupplierPaymentField;
