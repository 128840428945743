import React from 'react';
import { Datagrid, DateField, TextField, NumberField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

// import FullNameField from '../components/FullNameField';
// import BudgetReferenceField from '../budgets/BudgetReferenceField';
import SupplierReferenceField from '../suppliers/SupplierReferenceField';
// import ContractReferenceField from '../contracts/ContractReferenceField';
// import OrderReferenceField from '../orders/OrderReferenceField';
// import StarRatingField from '../components/StarRatingField';
// import InvoiceOrganizationReferenceField from './InvoiceOrganizationReferenceField';
import OrganizationReferenceField from '../organizations/OrganizationReferenceField';
// import UserReferenceField from '../users/UserReferenceField';
import InvoiceStatusField from './InvoiceStatusField';
import UserReferenceField from '../users/UserReferenceField';
import InvoiceTypeField from './InvoiceTypeField';

import rowStyle from '../components/rowStyle';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const InvoiceListDesktop = ({ permissions, ...props }) => {
    const classes = useListStyles();

    return (
        <Datagrid
            rowClick="edit"
            rowStyle={rowStyle}
            classes={{ headerRow: classes.headerRow }}
            optimized
            {...props}
        >
            <DateField source="date" />
            <DateField source="createdAt" />
            <TextField source="number" />
            {/* <TextField source="deliveryNote" /> */}
            {
              permissions.role.type === 'manager' || permissions.role.type === 'validator' ?
                  <SupplierReferenceField link={false} />
              : <></>
            }
            {
                permissions.role.type === 'manager' ?
                    <OrganizationReferenceField link={false} /> : <></>
            }
            <NumberField source="amount" options={{ style: 'currency', currency: 'EUR' }} />
            {/*
              permissions.role.type === 'manager' ?
                  <StarRatingField link={false} /> :
                  <></>
            */}
            <InvoiceStatusField source="status" />
            <InvoiceTypeField link={false} />
            {
                permissions.role.type === 'manager' ?
                    <UserReferenceField link={false} /> :
                    <></>
            }
        </Datagrid>
    );
};

export default InvoiceListDesktop;
