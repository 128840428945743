import React from 'react';


const FileUrl = ({ record }) => (
    <span>
        {record.fileUrl.split(',')
            .map((_, i) => (
                <p key={i}>
                    <a
                        href={_}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                            {`Enlace ${i + 1}`}
                    </a>
                </p>
            ))}
    </span>
);

FileUrl.defaultProps = {
    label: 'resources.tickets.fields.fileUrl',
    source: 'fileUrl',
    addLabel: true,
};

export default FileUrl;
