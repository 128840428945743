import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const BudgetReferenceField = props => {

    if (props.record.budget) {
        return (
            <ReferenceField
                label="Budget"
                source="budget.id"
                reference="budgets"
                {...props}
            >
                <TextField source="description" />
            </ReferenceField>
        );
    } else {
        return <>{props.record.itemDesc}</>
    }
    

};

BudgetReferenceField.defaultProps = {
    source: 'budget.id',
    label: 'resources.budgets.fields.budget',
    addLabel: true,
};

export default BudgetReferenceField;
