import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import LabelIcon from '@material-ui/icons/Label';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink, usePermissions } from 'react-admin';

import invoices from '../invoices';
import budgets from '../budgets';
import contracts from '../contracts';
import offers from '../offers';
import users from '../users';
import suppliers from '../suppliers';
import organizations from '../organizations';
import tickets from '../tickets';
import issues from '../issues';
import albaranes from '../albaranes';
import announcements from '../announcements';
import purchases from '../purchases';
import complaints from '../complaints';
import SubMenu from './SubMenu';


const Menu = ({ onMenuClick, dense, logout }) => {
    const { permissions } = usePermissions();

    const [state, setState] = useState({
        menuAdmon: false,
        menuSales: false,
        menuCustomers: false,
    });
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    useSelector((state) => state.theme); // force rerender on theme change

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <div>
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />

            { permissions && (permissions.role.type !== 'employee') ?
                <SubMenu
                    handleToggle={() => handleToggle('menuSales')}
                    isOpen={state.menuSales}
                    icon={<invoices.icon />}
                    sidebarIsOpen={open}
                    name="pos.menu.sales"
                    dense={dense}
                >
                    {  permissions && (permissions.role.type === 'manager' || permissions.invoiceValidator || permissions.invoiceCreator || permissions.role.type === 'supplier') ?
                    <MenuItemLink
                        to={`/invoices`}
                        primaryText={translate(`resources.invoices.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<invoices.icon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    /> : <></> }
                    { permissions && (permissions.role.type === 'manager' || permissions.purchaseAdmin) ? 
                    <MenuItemLink
                        to={`/purchases`}
                        primaryText={translate(`resources.purchases.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<purchases.icon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    /> : <></> }
                    { permissions && (permissions.role.type === 'manager') ? 
                    <MenuItemLink
                        to={`/budgets`}
                        primaryText={translate(`resources.budgets.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<budgets.icon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    /> : <></> }
                    { permissions && (permissions.role.type === 'manager' || permissions.invoiceValidator || permissions.role.type === 'supplier') ?
                    <MenuItemLink
                        to={`/contracts`}
                        primaryText={translate(`resources.contracts.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<contracts.icon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    /> : <></> }
                    { permissions && (permissions.role.type === 'manager' || permissions.role.type === 'employee') ?
                    <MenuItemLink
                        to={`/offers`}
                        primaryText={translate(`resources.offers.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<offers.icon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    /> : <></> }
                    {permissions && (permissions.role.type === 'manager' || permissions.role.type === 'validator' || permissions.ticketValidator || permissions.ticketAdmin) ?
                      <MenuItemLink
                            to={`/tickets`}
                            primaryText={translate(`resources.tickets.name`, {
                                smart_count: 2,
                            })}
                            leftIcon={<tickets.icon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        /> : <></>}
                    {permissions && (permissions.role.type === 'manager' || permissions.role.type === 'validator' || permissions.foreman) ?
                        <MenuItemLink
                            to={`/albaranes`}
                            primaryText={translate(`resources.albaranes.name`, { smart_count: 2,})}
                            leftIcon={<albaranes.icon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        /> : <></>}
            </SubMenu> : <></> }


            { permissions && (permissions.role.type === 'manager') ?
            <SubMenu
                handleToggle={() => handleToggle('menuCustomers')}
                isOpen={state.menuCustomers}
                sidebarIsOpen={open}
                name="pos.menu.comm"
                icon={<announcements.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/announcements`}
                    primaryText={translate(`resources.announcements.name`, { smart_count: 2,})}
                    leftIcon={<announcements.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                {<MenuItemLink
                    to={`/issues`}
                    primaryText={translate(`resources.issues.name`, { smart_count: 2,})}
                    leftIcon={<issues.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />}
                {   permissions.complaintManager &&
                    <MenuItemLink
                        to={`/complaints`}
                        primaryText={translate(`resources.complaints.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<complaints.icon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                />}
            </SubMenu> :
                <>
                    {permissions && (permissions.role.type === 'employee') ?
                        <MenuItemLink
                                to={`/tickets`}
                                primaryText={translate(`resources.tickets.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<tickets.icon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                            /> : <></>
                    }
                    {permissions && (permissions.role.type === 'employee') ?
                        <MenuItemLink
                            to={`/albaranes`}
                            primaryText={translate(`resources.albaranes.name`, { smart_count: 2,})}
                            leftIcon={<albaranes.icon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        /> : <></>
                    }
                    { (permissions && (permissions.foreman || permissions.purchaseAdmonObra)) ? <MenuItemLink
                        to={`/purchases`}
                        primaryText={translate(`resources.purchases.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<purchases.icon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    /> : <></> }
                    {<MenuItemLink
                        to={`/issues`}
                        primaryText={translate(`resources.issues.name`, { smart_count: 2,})}
                        leftIcon={<issues.icon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />}
                    <MenuItemLink
                        to={`/announcements`}
                        primaryText={translate(`resources.announcements.name`, { smart_count: 2,})}
                        leftIcon={<announcements.icon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                </>
            }


            { permissions && (permissions.role.type === 'manager') ?
            <SubMenu
                handleToggle={() => handleToggle('menuAdmon')}
                isOpen={state.menuAdmon}
                sidebarIsOpen={open}
                name="pos.menu.admin"
                icon={<LabelIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/users`}
                    primaryText={translate(`resources.users.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<users.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/suppliers`}
                    primaryText={translate(`resources.suppliers.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<suppliers.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/organizations`}
                    primaryText={translate(`resources.organizations.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<organizations.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu> : <></>}

            {isXSmall && logout}
        </div>
    );
};

export default Menu;
