import React from 'react';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import PeopleIcon from '@material-ui/icons/People';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';

import CardIcon from './CardIcon';

const useStyles = makeStyles(theme => ({
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 20,
    },
    titleLink: { textDecoration: 'none', color: 'inherit' },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    avatar: {
        background: theme.palette.background.avatar,
    },
    listItemText: {
        overflowY: 'hidden',
        height: '4em',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    },
}));

const location = {
    pathname: 'suppliers',
    query: { filter: JSON.stringify({ status: 'pending' }) },
};

const PendingSuppliers = ({ suppliers = [], nb, paymentPending }) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <div className={classes.main}>
            <CardIcon Icon={PeopleIcon} bgColor="#E8E8E8" />
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                    {
                        paymentPending ? <>
                            {translate('pos.dashboard.paymentPendingSuppliers')}
                        </> : <>
                            {translate('pos.dashboard.pending_suppliers')}
                        </>
                    }
                </Typography>
                <Typography
                    variant="h5"
                    component="h2"
                    className={classes.value}
                >
                    <Link to={location} className={classes.titleLink}>
                        {nb}
                    </Link>
                </Typography>
                <Divider />
                <List>
                    {suppliers.map(record => {
                      return (
                        <ListItem
                            key={record.id}
                            button
                            component={Link}
                            to={`/suppliers/${record.id}`}
                            alignItems="flex-start"
                        >
                            <ListItemAvatar>
                                {suppliers[record.id] ? (
                                    <Avatar
                                        src={`${
                                            suppliers[record.id].avatar
                                        }?size=32x32`}
                                        className={classes.avatar}
                                    />
                                ) : (
                                    <Avatar />
                                )}
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${record.name} - ${record.business}`}
                                secondary={`${(new Date(record.updatedAt)).toLocaleDateString()}`}
                                className={classes.listItemText}
                                style={{ paddingRight: 0 }}
                            />
                            <ListItemText
                                primary={`${record.organization.name}`}
                                secondary={record.type ? translate(`resources.suppliers.fields.${record.type}`) : ''}
                                className={classes.listItemText}
                                style={{ paddingRight: 0 }}
                            />
                        </ListItem>
                    )})}
                </List>
            </Card>
        </div>
    );
};

export default PendingSuppliers;
