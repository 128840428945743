import React, { useCallback } from 'react';
import { useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ThumbUp from '@material-ui/icons/ThumbUp';
import { useTranslate, useUpdate, useNotify, useRedirect } from 'react-admin';

/**
 * This custom button demonstrate using useUpdate to update data
 */
const AcceptButton = ({ record, action = 'accept' }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirectTo = useRedirect();
    // const [update] = useUpdate('purchases');
    const formState = useFormState();

    const [approve, { loading }] = useUpdate(
      'purchases',
      record.id,
      {
          status: 
            action === 'accept' ? 'accepted'
                : action === 'approve' ? 'approved'
                    : 'telematel',
          selectedOffer: formState.values.selectedOffer,
          orderNumber: formState.values.orderNumber,
      },
      record,
      {
          undoable: true,
          onSuccess: () => {
              notify(
                  'resources.purchases.notification.approved_success',
                  'info',
                  {},
                  true
              );
              redirectTo('/purchases');
          },
          onFailure: () => {
              notify(
                  'resources.purchases.notification.approved_error',
                  'warning'
              );
          },
      }
    );

    const handleClick = useCallback(() => {
        if (!formState.valid) {
            return;
        }
        if (record.status === 'approved') {
            if (!formState.values.orderNumber) {
                alert(translate(`pos.comment_order_number_required`));
                return;
            }
        }
        approve();
    }, [
        formState.valid,
        formState.values.orderNumber,
        record.status,
        translate,
        approve,
    ]);

    return record && (
                record.status === 'pending'
            ||  record.status === 'accepted'
            ||  record.status === 'approved'
        ) ? (
        <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleClick}
            disabled={loading}
        >
            <ThumbUp
                color="primary"
                style={{ paddingRight: '0.5em', color: 'green' }}
            />
            {
              action === 'approve' ? translate('resources.purchases.action.approve') :
              action === 'telematel' ? translate('resources.purchases.action.telematel') :
              translate('resources.purchases.action.accept') 
            }

        </Button>
    ) : (
        <span />
    );
};

AcceptButton.propTypes = {
    record: PropTypes.object,
};

export default AcceptButton;
