import React, { useCallback } from 'react';
import { useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ThumbUp from '@material-ui/icons/ThumbUp';
import { useTranslate, useUpdate, useNotify, useRedirect } from 'react-admin';

/**
 * This custom button demonstrate using useUpdate to update data
 */
const AcceptButton = ({ record, action = 'accept' }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirectTo = useRedirect();
    // const [update] = useUpdate('invoices');
    const formState = useFormState();

    const [approve, { loading }] = useUpdate(
      'invoices',
      record.id,
      {
          comment: formState.values.comment,
        //   rating: formState.values.rating,
          status: action === 'account' ? 'accounted' : action === 'pay' ? 'payed' : 'accepted',
          deliveryNote: formState.values.deliveryNote,
          organization: formState.values.organization,
      },
      record,
      {
          undoable: true,
          onSuccess: () => {
              notify(
                  'resources.invoices.notification.approved_success',
                  'info',
                  {},
                  true
              );
              redirectTo('/invoices');
          },
          onFailure: () => {
              notify(
                  'resources.invoices.notification.approved_error',
                  'warning'
              );
          },
      }
    );

    const handleClick = useCallback(() => {

      if (
          !formState.values.deliveryNote &&
          (record.order || record.purchase)
      ) {
        alert("\n\nFALTA ALBARÁN!\n\n");
        return;
      }

        if (!formState.valid) {
            return;
        }
        approve();
    }, [
        formState.valid,
        approve,
        formState.values.deliveryNote,
        record.order,
        record.purchase
    ]);


    return record && (
                record.status === 'pending'
            ||  record.status === 'delivery_note_pending'
            ||  record.status === 'accepted'
            // ||  record.status === 'payed'
            || record.status === 'rejectadmin'
            || record.status === 'accounted'
        ) ? (
        <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleClick}
            disabled={loading}
        >
            <ThumbUp
                color="primary"
                style={{ paddingRight: '0.5em', color: 'green' }}
            />
            {
              action === 'account' ? translate('resources.invoices.action.account') :
              action === 'pay' ? translate('resources.invoices.action.pay') :
                translate('resources.invoices.action.accept')
            }

        </Button>
    ) : (
        <span />
    );
};

AcceptButton.propTypes = {
    record: PropTypes.object,
};

export default AcceptButton;
