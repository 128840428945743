import React from 'react';
import {
    Create,
    TextInput,
    SelectInput,
    SimpleForm,
    FileInput,
    FileField,
    useTranslate,
    FormDataConsumer,
    Toolbar
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import SaveAndUploadButton from './SaveAndUploadButton';

const CustomToolbar = props => (
    <Toolbar {...props} >
        <SaveAndUploadButton redirect="invoices" />
    </Toolbar>
);

const AnnouncementCreate = ({permissions, ...props}) => {
    const translate = useTranslate();   

    const categories = [
        { id: 'all', name: translate("resources.announcements.categories.all") },
        { id: 'employee', name: translate("resources.announcements.categories.employee") },
        { id: 'validator', name: translate("resources.announcements.categories.validator") },
        { id: 'supplier', name: translate("resources.announcements.categories.supplier") },
        { id: 'manager', name: translate("resources.announcements.categories.manager") },
    ];

    const validateAnnouncement = values => {
        const errors = {};     
        return errors;
    };

    if (!permissions) {
        return;
    }

    if (permissions.role.type !== 'manager') {
        return (<>{translate('resources.announcements.cannot_create')}</>);
    }

    return <Create {...props}>
        <SimpleForm
            toolbar={<CustomToolbar />}
            validate={validateAnnouncement}
        >
            <SelectInput
                label="resources.announcements.fields.category"
                source="category"
                choices={categories}
                fullWidth
            />
            <TextInput source="title" />
            <RichTextInput source="description" fullWidth />
            <FileInput
                source="attachments"
                accept="application/pdf, image/*"
                multiple
            >
                <FileField source="attachments" title="title" />
            </FileInput>
            <FormDataConsumer>
                {
                    ({ formData, ...rest }) => {
                        if (formData.attachment)
                            return <i>{translate('pos.upload_remove_help')}</i>
                        else return <></>

                    }
                }
            </FormDataConsumer>
        </SimpleForm>
    </Create>

};

export default AnnouncementCreate;
