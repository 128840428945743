import React, { useState } from 'react';
import {
    useEditController,
    useTranslate,
    // UrlField,
    SimpleForm,
    DateField,
    TextField,
    EmailField,
    SelectInput,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import TicketEditToolbar from './TicketEditToolbar';
import UserReferenceField from '../users/UserReferenceField';
// import TicketExpenseTypeField from './TicketExpenseTypeField';
// import TicketPaymentMethodField from './TicketPaymentMethodField';
import FileUrl from './FileUrl';


const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
}));

const TicketEdit = ({ onCancel, permissions, ...props }) => {
    const classes = useStyles();
    const controllerProps = useEditController(props);
    const translate = useTranslate();
    const [changed, setChanged] = useState();

    if (!controllerProps.record) {
        return null;
    }

    const expenseTypes = [
      { key: 'company_invoice', id: 'company_invoice', name: translate(`resources.tickets.expenseType.company_invoice`) },
      { key: 'other', id: 'other', name: translate(`resources.tickets.expenseType.other`) },
    ];

    const paymentMethods = [
      { key: 'card_company', id: 'card_company', name: translate(`resources.tickets.paymentMethod.card_company`) },
      { key: 'card_personal', id: 'card_personal', name: translate(`resources.tickets.paymentMethod.card_personal`) },
      { key: 'card_cepsa', id: 'card_cepsa', name: translate(`resources.tickets.paymentMethod.card_cepsa`) },
      { key: 'invoice_hotel', id: 'invoice_hotel', name: translate(`resources.tickets.paymentMethod.invoice_hotel`) },
    ];

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6">
                    {translate('resources.invoices.detail')}
                </Typography>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <SimpleForm
                className={classes.form}
                basePath={controllerProps.basePath}
                record={controllerProps.record}
                save={controllerProps.save}
                version={controllerProps.version}
                redirect="list"
                resource="tickets"
                toolbar={<TicketEditToolbar permissions={permissions} changed={changed}/>}
            >
                {
                    controllerProps.record.status === 'paid' ?
                        <DateField source="updatedAt" label={translate('resources.tickets.paidAt')} formClassName={classes.inlineField} /> :
                        <></>
                }
                <></>
                <DateField source="invoiceDate" formClassName={classes.inlineField} />
                <DateField source="date" formClassName={classes.inlineField} />
                <TextField source="employee" />
                <EmailField source="email" />
                {
                    permissions && (permissions.role.type !== 'employee') && (controllerProps.record.status === 'pending' || controllerProps.record.status === 'accepted') ?
                      <SelectInput source="paymentMethod" choices={paymentMethods} onChange={(e) => {setChanged(true)}} fullWidth /> :
                      <SelectInput source="paymentMethod" choices={paymentMethods} disabled fullWidth />
                }
                {
                    permissions && (permissions.role.type !== 'employee') && (controllerProps.record.status === 'pending' || controllerProps.record.status === 'accepted') ?
                      <SelectInput source="expenseType" choices={expenseTypes} onChange={(e) => {setChanged(true)}} fullWidth /> :
                      <SelectInput source="expenseType" choices={expenseTypes} disabled fullWidth />
                }
                <TextField source="amount" formClassName={classes.inlineField} />
                <TextField source="ot" formClassName={classes.inlineField} />
                {
                    controllerProps.record.plate ? <TextField source="plate" formClassName={classes.inlineField} /> : <></>
                }
                <FileUrl
                  source="fileUrl"
                />
                {
                    permissions && controllerProps.record.status === 'pending'
                    && ( permissions.role.type === 'manager'
                    || permissions.ticketAdmin
                    || (permissions.ticketValidator && controllerProps.record.user && (controllerProps.record.user.id === permissions.id))) ?
                        <ReferenceInput
                            source="user._id"
                            reference="users"
                            label="resources.tickets.fields.user"
                            filterToQuery={ () => {
                                return ({ ticketValidator: true });
                              } }
                            perPage={1000}
                            formClassName={classes.inlineField}
                            onChange={(e) => {setChanged(true)}}
                            >
                            <AutocompleteInput
                                optionText={choice =>
                                    choice && choice.id ? `${choice.name}` : ""
                                }
                            />
                        </ReferenceInput> :
                        <UserReferenceField source="user._id"
                            label={translate('resources.tickets.fields.user')}
                            link={false}
                        />
                }
                {
                  controllerProps.record.registrar ?
                    <UserReferenceField source="registrar._id"
                      label={translate('resources.tickets.fields.registrar')}
                      link={false}
                    /> : <></>
                }
                {
                  controllerProps.record.payer ?
                    <UserReferenceField source="payer._id"
                      label={translate('resources.tickets.fields.payer')}
                      link={false}
                    /> : <></>
                }
            </SimpleForm>
        </div>
    );
};

export default TicketEdit;
