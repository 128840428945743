import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { useForm } from 'react-final-form';
import {
    required,
    email,
    Button,
    SaveButton,
    TextInput,
    NumberInput,
    useCreate,
    useNotify,
    FormWithRedirect,
    FileInput,
    FileField,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    useTranslate
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import URL from '../URL';
const validateEmail = email();

function OfferQuickCreateButton({ onChange, source, type }) {
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);
    const [supplier, setSupplier] = useState();
    const [uploading, setUploading] = useState();
    const [create, { loading }] = useCreate('offers');
    const notify = useNotify();
    const form = useForm();

    const paymentChoices = ['confirming','transfer','note','bill','order','other'].map(type => ({
        key: type, id: type, name: translate(`resources.suppliers.payments.${type}`)}));
    const daysChoices = ['0','15','30','45','60','90','120','150','180'].map(days => ({
        key: days, id: days, name: translate(`resources.suppliers.fields.${days.toString()}`) }));
    

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {
        create(
            { payload: { data: values } },
            {
                onSuccess: ({ data }) => {
                    setShowDialog(false);
                    // Update the comment form to target the newly created offer
                    // Updating the ReferenceInput value will force it to reload the available offers
                    form.change(source, data.id);
                    onChange();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    const uploadFile = useCallback( file => {
        notify('pos.uploading', 'info', {}, true);
        setUploading(true);
        const formData = new FormData();
        formData.append("files", file);
        axios.post(`${URL}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then(({data}) => {
            file._id = data[0]._id;
            setUploading(false);
        })
        .catch(error => {
            notify(`pos.upload_failed`);
            setUploading(false);
        });
    }, [notify]);

    const getSupplier = useCallback( (id) => {
        setSupplier(null);
        axios.get(`${URL}/suppliers/${id}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then(({data}) => {
            setSupplier(data);
        });
    }, []);

    return (
        <>
            <Button onClick={handleClick} label="ra.action.create">
                <IconContentAdd />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Crear oferta"
            >
                <DialogTitle>Crear oferta</DialogTitle>

                <FormWithRedirect
                    resource="offers"
                    save={handleSubmit}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                            <DialogContent>
                                <FileInput
                                    source="file"
                                    label="resources.offers.fields.file"
                                    accept="application/pdf, image/*"
                                    onChange={file => uploadFile(file)}
                                    validate={required()}
                                >
                                    <FileField source="src" />
                                </FileInput>

                                <div style={{textAlign: "center"}}>
                                    {translate('resources.purchases.selectSupplier')}
                                </div>

                                <ReferenceInput
                                    source="supplier._id"
                                    reference="suppliers"
                                    label="resources.invoices.fields.supplier"
                                    allowEmpty={true}
                                    fullWidth
                                    onChange={(id) => getSupplier(id)}
                                >
                                    <AutocompleteInput
                                        optionText={(record) => record && record.taxid ? `${record.taxid } | ${record.business}` : ''}
                                    />
                                </ReferenceInput>
                                {
                                    supplier ?
                                    <>
                                        <TextInput source="supplierName" label={translate("resources.purchases.fields.supplierName")} initialValue={supplier.business} validate={required()} fullWidth  disabled />
                                        <TextInput source="supplierEmail" label={translate("resources.offers.fields.supplierEmail")} initialValue={supplier.email} fullWidth validate={validateEmail} required  disabled />
                                        <TextInput source="taxid" label={translate("resources.suppliers.fields.taxid")} initialValue={supplier.taxid} validate={required()} fullWidth />
                                        <TextInput source="bic" label={translate("resources.suppliers.fields.bic")} initialValue={supplier.bic} validate={required()} fullWidth />
                                        <TextInput source="swift" label={translate("resources.suppliers.fields.swift")} initialValue={supplier.swift} validate={required()} fullWidth />
                                        <SelectInput
                                            source="paymentMethod"
                                            label={translate("resources.offers.fields.paymentMethod")}
                                            fullWidth
                                            choices={paymentChoices}
                                            initialValue={supplier.payment}
                                            validate={required()}
                                        />
                                        <SelectInput source="days" label={translate("resources.offers.fields.days")} fullWidth choices={daysChoices} initialValue={supplier.days} validate={required()} />
                                    </> :
                                    <>
                                        <TextInput source="supplierName" label={translate("resources.purchases.fields.supplierName")} validate={required()} fullWidth />
                                        <TextInput source="supplierEmail" label={translate("resources.offers.fields.supplierEmail")} fullWidth validate={validateEmail} required />
                                        <TextInput source="taxid" label={translate("resources.suppliers.fields.taxid")} validate={required()} fullWidth />
                                        <TextInput source="bic" label={translate("resources.suppliers.fields.bic")} validate={required()} fullWidth />
                                        <TextInput source="swift" label={translate("resources.suppliers.fields.swift")} validate={required()} fullWidth />
                                        <SelectInput source="paymentMethod" label={translate("resources.offers.fields.paymentMethod")} fullWidth choices={paymentChoices} validate={required()} />
                                        <SelectInput source="days" label={translate("resources.offers.fields.days")} fullWidth choices={daysChoices} validate={required()} />
                                    </>
                                }
                                { type === 'invoice' ? <TextInput source="invoiceNumber" label={translate("resources.invoices.fields.number")} validate={required()} fullWidth /> : <></> }
                                <NumberInput source="amount" label={translate("resources.purchases.fields.amount")} validate={required()} fullWidth />
                                <NumberInput source="advance" min="0" max="100" step="5" label={translate("resources.offers.fields.advance")} fullWidth />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                    disabled={loading || uploading}
                                >
                                    <IconCancel />
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading || uploading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
}

export default OfferQuickCreateButton;
