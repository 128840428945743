import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
    useEditController,
    useTranslate,
    SimpleForm,
    TextInput,
    TextField,
    SelectInput,
    SelectArrayInput,
    ArrayInput,
    SimpleFormIterator,
    ReferenceInput,
    BooleanInput,
    useRefresh,
    useNotify
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import SupplierEditToolbar from './SupplierEditToolbar';
import ValidBic from '../components/ValidBic';
import UserReferenceField from './UserReferenceField';

import URL from '../URL';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
    linkContainer: {
        margin: '1em',
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "auto",
        maxWidth: "75%",
        whiteSpace: "nowrap"
    },
    link: {
        textDecoration: 'none',
        color: "#00A98F",
    }
}));

const SupplierEdit = ({ permissions, onCancel, ...props }) => {
    const classes = useStyles();
    const controllerProps = useEditController(props);

    // dirty fix for purchaseFamily as string
    if (controllerProps.record) {
        if (!controllerProps.record.purchaseFamily) {
            controllerProps.record.purchaseFamily = [];
        } else if (typeof controllerProps.record.purchaseFamily === 'string') {
            controllerProps.record.purchaseFamily = [controllerProps.record.purchaseFamily];
        }
    }

    const translate = useTranslate();

    const [budgets, setBudgets] = useState();
    // const [contracts, setContracts] = useState();
    // const [users, setUsers] = useState();

    const refresh = useRefresh();
    const notify = useNotify();

    const purchaseFamilies = [
        { id: 'acabado', name: translate("resources.suppliers.purchaseFamilies.acabado") },
        { id: 'clima', name: translate("resources.suppliers.purchaseFamilies.clima") },
        { id: 'construccion', name: translate("resources.suppliers.purchaseFamilies.construccion") },
        { id: 'electricidad', name: translate("resources.suppliers.purchaseFamilies.electricidad") },
        { id: 'ferreteria', name: translate("resources.suppliers.purchaseFamilies.ferreteria") },
        { id: 'fontaneria', name: translate("resources.suppliers.purchaseFamilies.fontaneria") },
        { id: 'hierro', name: translate("resources.suppliers.purchaseFamilies.hierro") },
        { id: 'madera', name: translate("resources.suppliers.purchaseFamilies.madera") },
        { id: 'pci', name: translate("resources.suppliers.purchaseFamilies.pci") },
        { id: 'renovables', name: translate("resources.suppliers.purchaseFamilies.renovables") },
        { id: 'vehiculos', name: translate("resources.suppliers.purchaseFamilies.vehiculos") },
    ];

    const validate = values => {
        const errors = {};
        if (values.bic) {
            if (ValidBic(values.bic)) {
              errors.bic = translate('pos.invalid_bic');
            }
        } else {
          // errors.bic = translate('ra.validation.required');
        }
        return errors;
    };

    // const fetchUsers = useCallback(async () => {
    //     // Request user
    //     const response = await axios.get(`${URL}/users?supplier=${props.id}`, {
    //         headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
    //       });            
    //       setUsers(response.data);
    //       console.log("users", response.data);
    //   }, [props.id]);

    const fetchBudgets = useCallback(async () => {
      // Request organizations.
      axios.get(`${URL}/budgets`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      }).then( (response) => {
          setBudgets(response.data
              .map(r => {
                  return {key: r.id, id: r.id, name: r.description};
          }));
        })
    }, []);

    // const fetchContracts = useCallback(async () => {
    //   // Request organizations.
    //   axios.get(`${URL}/contracts`, {
    //     headers: {
    //         'Authorization': `Bearer ${localStorage.getItem('token')}`
    //     },
    //   }).then( (response) => {
    //       setContracts(response.data
    //           .map(c => {
    //               return {key: c.id, id: c.id, name: c.description};
    //       }));
    //     })
    // }, []);

    const updateSupplier = useCallback( async (paymentMethodFinal, id) => {
        axios.put(`${URL}/suppliers/${id}`, { paymentMethodFinal }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then(() => {
            refresh();
        }).catch(error => { notify(`Falló la actualización`,); });
    }, [notify, refresh]);

    useEffect(() => {
        if (!budgets) {
          fetchBudgets();
        }
        // if (!contracts) {
        //   fetchContracts();
        // }
        // if (!users) {
        //     fetchUsers();
        // }
    }, [budgets, fetchBudgets, /*contracts, fetchContracts, users, fetchUsers*/]);

    if (!controllerProps.record) {
        return null;
    }

    if (budgets && budgets.length) {
        return (
            <div className={classes.root}>
                <div className={classes.title}>
                    <Typography variant="h6">
                        {
                            controllerProps.record.paymentMethodFinal ? 
                            <>{translate('resources.suppliers.finalPaymentMethod')}</> :
                            <>{translate('resources.suppliers.nonFinalPaymentMethod')}</>
                        }
                    </Typography>
                    <IconButton onClick={onCancel}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.linkContainer}>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.link}
                        href={`/#/invoices?displayedFilters={"supplier"%3Atrue}&filter={"supplier"%3A"${controllerProps.record.id}"}&order=DESC&page=1&perPage=25&sort=createdAt`}

                    >
                        {translate('pos.see_invoices')} {controllerProps.record.business}
                    </a>
                </div>
                <SimpleForm
                    className={classes.form}
                    basePath={controllerProps.basePath}
                    record={controllerProps.record}
                    save={controllerProps.save}
                    version={controllerProps.version}
                    validate={validate}
                    redirect="list"
                    resource="suppliers"
                    toolbar={<SupplierEditToolbar permissions={permissions} />}
                >
                    {/* {
                        permissions.role.type === 'manager' ? 
                        <SelectInput
                            source="status"
                            label="resources.suppliers.fields.status"
                            fullWidth
                            choices={
                                ["payment_confirm", "pending", "accepted", "rejected"].map((type, index) => {
                                    return {key: type, id: type, type: type,
                                        name: translate(`pos.supplier_status.${type}`)}
                                })
                            }
                        /> :<></>
                    } */}
                    {
                        permissions.role.type === 'manager' || permissions.role.type === 'validator'
                        ? <TextInput source="name" required fullWidth />
                        : <TextField source="name" fullWidth />
                    }
                    {
                        permissions.role.type === 'manager' || permissions.role.type === 'validator'
                        ? <TextInput source="business" fullWidth required />
                        : <TextField source="business" fullWidth />
                    }
                    {/* <>
                        <Typography gutterBottom variant="h6" component="h6">
                            {translate('pos.relatedUsers')}
                        </Typography>
                        <div className={classes.linkContainer}>
                            {
                                users?.map(user => <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.link}
                                    href={`/#/users/${user.id}`}
            
                                >
                                    {user.name}
                                </a>)
                            }
                        </div>
                    </> */}
                    <SelectInput
                        source="type"
                        fullWidth
                        choices={
                            ['supplier','creditor'].map(type => {
                                return {
                                    key: type, id: type, name: translate(`resources.suppliers.fields.${type}`),
                                };
                            })
                        }
                    />
                    {
                        permissions.role.type === 'manager' || permissions.role.type === 'validator'
                        ? <TextInput source="email" fullWidth required />
                        : <TextField source="email" fullWidth />
                    }
                    <ReferenceInput
                        label="resources.organizations.fields.organization"
                        source="organization._id"
                        reference="organizations"
                        fullWidth
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    {
                        permissions.role.type === 'manager' || permissions.role.type === 'validator'
                        ? <TextInput source="taxid" fullWidth required />
                        : <TextField source="taxid" fullWidth />
                    }
                    {
                        permissions.role.type === 'manager' ? 
                        <TextInput source="city" fullWidth required /> :
                        <TextField source="city" fullWidth required />
                    }
                    {
                        permissions.role.type === 'manager' ? 
                        <TextInput source="province" fullWidth required /> :
                        <TextField source="province" fullWidth required />
                    }
                    {
                        permissions.role.type === 'manager' ? 
                        <TextInput source="address" fullWidth required /> :
                        <TextField source="address" fullWidth required />
                    }
                    {
                        permissions.role.type === 'manager' ? 
                        <TextInput source="zipcode" fullWidth required /> :
                        <TextField source="zipcode" fullWidth required />
                    }
                    {
                        permissions.role.type === 'manager' ? 
                        <TextInput source="country" fullWidth required /> :
                        <TextField source="country" fullWidth required />
                    }
                    {
                        permissions.role.type === 'manager' ? 
                        <TextInput source="phone" fullWidth /> :
                        <TextField source="phone" fullWidth />
                    }
                    <SelectInput
                        source="payment"
                        fullWidth
                        choices={
                            ['confirming','transfer','note','bill','order','other'].map(type => {
                                return {
                                    key: type, id: type, name: translate(`resources.suppliers.payments.${type}`),
                                };
                            })
                        }
                        disabled={controllerProps.record.status === 'payment_confirm' && !permissions.supplierPaymentValidator}
                    />
                    <SelectInput
                        source="days"
                        fullWidth
                        choices={
                            [0,15,30,45,60,90,120,150,180].map(days => {
                                return {
                                    key: days, id: days, name: days.toString(),
                                };
                            })
                        }
                        disabled={controllerProps.record.status === 'payment_confirm' && !permissions.supplierPaymentValidator}
                    />
                    <TextInput source="bic" fullWidth required />
                    <TextInput source="swift" fullWidth required />
                    <TextInput
                        source="paymentMethodComment"
                        maxRows={15} multiline fullWidth
                        disabled={!permissions.supplierPaymentValidator}
                    />
                    {
                        permissions.supplierPaymentValidator &&
                            <BooleanInput
                                source='paymentMethodFinal'
                                disabled={!permissions.supplierPaymentValidator}
                                onChange={e => updateSupplier(e, controllerProps.record.id)}
                            />
                    }
                    { controllerProps.record.purchaseFamily &&
                        <SelectArrayInput
                            source="purchaseFamily"
                            fullWidth
                            choices={purchaseFamilies}
                            disabled={!permissions.supplierPaymentValidator && permissions.role.type !== 'manager'}
                        />
                    }

                    <TextField source="employee" required />
                    <UserReferenceField source="approvedBy.id" {...props} />

                    {
                        controllerProps.record.status === 'pending'
                        ?
                            <TextInput
                                source="comment"
                                label="resources.suppliers.fields.comment"
                                maxRows={15} multiline fullWidth
                            />
                        :
                            <>
                                {/*<NumberInput
                                  source="rating"
                                  label="resources.suppliers.fields.rating"
                                  inputProps={{
                                     step: 1,
                                     min: 1,
                                     max: 5
                                   }}
                                />*/}
                                <TextInput
                                    source="comment"
                                    label="resources.suppliers.fields.comment"
                                    maxRows={15} multiline fullWidth
                                />
                                <ArrayInput source="budgets" label="resources.suppliers.fields.budgets">
                                    <SimpleFormIterator>
                                        <SelectInput
                                            label="resources.suppliers.fields.budgets"
                                            source="_id"
                                            choices={budgets}
                                        />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </>
                    }
                </SimpleForm>
            </div>
        );
    } else {
        return (<></>);
    }
};

export default SupplierEdit;
