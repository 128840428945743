import React from 'react';
import { ReferenceField } from 'react-admin';
import PurchaseStatusField from '../purchases/PurchaseStatusField';

const PurchaseField = ({ record = {}, size }) => {
    return (
        <>
            { `OT ${record.ot} - ${record.item}` }
        </>
    );
};

const PurchaseReferenceField = props => {
    return (
    <>
        <PurchaseStatusField source="status" record={props.record.purchase} />
        {"→ "}
        <ReferenceField source="purchase.id" reference="purchases" {...props}>
            <PurchaseField />
        </ReferenceField>
    </>
)};

PurchaseReferenceField.defaultProps = {
    source: 'purchase.id',
    label: 'resources.invoices.fields.purchase',
    addLabel: true,
};

export default PurchaseReferenceField;