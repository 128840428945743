import React, { Fragment } from 'react';
import MuiToolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import { SaveButton } from 'react-admin';
import AcceptButton from './AcceptButton';
import PayButton from './PayButton';
import RejectButton from './RejectButton';

const useStyles = makeStyles({
    root: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const TicketEditToolbar = ({
    handleSubmitWithRedirect,
    invalid,
    saving,
    record,
    permissions,
    changed
}) => {
    const classes = useStyles();

    return (
        <MuiToolbar className={classes.root}>
            {
                changed ? (
                    <Fragment>
                        <SaveButton
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            invalid={invalid}
                            saving={saving}
                            redirect="list"
                            submitOnEnter={true}
                        />
                    </Fragment>
                )
                : record.status === 'pending' && !record.user ?
                    permissions.ticketValidator ?
                        <Fragment>
                            <AcceptButton record={record} resource={'tickets'} />
                            <RejectButton record={record} resource={'tickets'} />
                        </Fragment>
                    : <></>
                : record.status === 'pending' && permissions.id === record.user.id ?
                    <Fragment>
                        <AcceptButton record={record} resource={'tickets'} />
                        <RejectButton record={record} resource={'tickets'} />
                    </Fragment>
                : record.status === 'accepted' && ((record.registrar && (permissions.id === record.registrar.id)) || (record.payer && (permissions.id === record.payer.id))) ?
                    <Fragment>
                        {
                            !record.paid && record.payer && (permissions.id === record.payer.id) ?
                                <PayButton record={record} resource={'tickets'} />
                            : <></>
                        }
                        {
                            record.registrar && (permissions.id === record.registrar.id) ?
                                <AcceptButton record={record} resource={'tickets'} />
                            : <></>
                        }

                    </Fragment>
                : record.status === 'telematel' && (record.payer && (permissions.id === record.payer.id)) ?
                    <Fragment>
                        {
                            !record.paid ?
                                    <PayButton record={record} resource={'tickets'} />
                            : <></>
                        }
                    </Fragment>
                : <></>
            }
        </MuiToolbar>
    );
};

export default TicketEditToolbar;
