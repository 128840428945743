import React from 'react';
import { ReferenceField } from 'react-admin';

import FullNameField from '../components/FullNameField';

const ContractReferenceField = props => {
  return (
    props.record.contract ?
      <ReferenceField source="contract.id" reference="contracts" {...props}>
          <FullNameField />
      </ReferenceField>
    : <></>
)};

ContractReferenceField.defaultProps = {
    source: 'contract.id',
    label: 'resources.contracts.fields.contract',
    addLabel: true,
};

export default ContractReferenceField;
