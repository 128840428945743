import React, { useCallback, Fragment } from 'react';
import axios from 'axios';
import {
    useEditController,
    useTranslate,
    SimpleForm,
    DateField,
    TextField,
    RadioButtonGroupInput,
    FormDataConsumer,
    FunctionField,
    NumberField,
    TextInput,
    BooleanInput,
    useNotify,
    useRefresh,
    FileInput,
    FileField
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import PurchaseEditToolbar from './PurchaseEditToolbar';
import CustomImageField from './CustomImageField';
import UserReferenceField from '../users/UserReferenceField';
import OrganizationReferenceField from '../organizations/OrganizationReferenceField';
import URL from '../URL';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
    separator: {
        width: '100%',
        'border-top': '1px solid silver'
    },
    highlight: {
        fontSize: "18px"
    }
}));


const OfferData = props => {
    const classes = useStyles();
    const num = props.num;
    const record = props.record.offers[num - 1];
    const translate = useTranslate();

    return <>
            <FunctionField
                record={record}
                render={record => (
                    <>
                        <Typography>
                            {record.supplierName}
                        </Typography>
                        <Typography variant="h6">
                            <TextField record={record} source="supplierEmail" />
                        </Typography>
                    </>
                )}
            />
            <Typography>
                { translate(`resources.suppliers.fields.taxid`) }
                {' '} <TextField className={classes.highlight} record={record} source="taxid" />
            </Typography>

            <Typography>
                { translate(`resources.offers.fields.amount`) }
                {' '} <NumberField className={classes.highlight} record={record} source="amount"
                    options={{
                        style: 'currency',
                        currency: 'EUR',
                    }}
                />
            </Typography>
            <Typography>
                { translate(`resources.offers.fields.advance`) }
                {' '} <NumberField className={classes.highlight} record={record} source="advance" />{'%'}
            </Typography>

            <Typography>
                { translate(`resources.suppliers.fields.bic`) }
            </Typography>
            
            <TextField className={classes.highlight} record={record} source="bic" />

            <Typography>
                { translate(`resources.suppliers.fields.swift`) }
            </Typography>
            
            <TextField className={classes.highlight} record={record} source="swift" />
            
            <Typography>
                { translate(`resources.suppliers.fields.paymentMethod`) }
            </Typography>
            
            <FunctionField
                record={record}
                render={record => (
                    <>
                        <span className={classes.inlineField}>
                            <Typography variant='h6'>
                                { record.paymentMethod ? translate(`resources.suppliers.payments.${record.paymentMethod}`) : "Sin forma de pago acordada" }
                            </Typography>
                        </span>
                        <span className={classes.inlineField}>
                            <Typography variant='h6'>
                                {record.days ? `${record.days} días` : ''}
                            </Typography>
                        </span>
                    </>
                )}
            />
        
            <CustomImageField label="Fichero" record={record} source="file" />
    </>;
}

const PurchaseEdit = ({ onCancel, permissions, ...props }) => {
    const classes = useStyles();
    const controllerProps = useEditController(props);
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();

    const updatePurchase = useCallback( async (payed, id) => {
        // notify('Actualizando', 'info', {}, true);
        axios.put(`${URL}/purchases/${id}`, { payed }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then(() => {
            refresh();
        }).catch(error => { notify(`Falló la actualización`,); });
    }, [notify, refresh]);


    const uploadFile = useCallback( (file, id) => {
        if (file === null) {
            notify('pos.saving', 'info', {}, true);
            axios.put(`${URL}/purchases/${id}`, { justif: null }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            }).then(() => {
                refresh();
            }).catch(error => {
                notify(`pos.saving_failed`,);
            });
        } else {
            notify('pos.uploading', 'info', { }, false, 8000);
            const formData = new FormData();
            formData.append("files", file);
            axios.post(`${URL}/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            }).then(({data}) => {
                notify('pos.saving', 'info', {}, true);
                axios.put(`${URL}/purchases/${id}`, { justif: { _id: data[0]._id } }, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                }).then(() => {
                    refresh();
                }).catch(error => {
                    notify(`pos.saving_failed`,);
                });
            })
            .catch(error => { notify(`pos.upload_failed`,); });
        }
    }, [notify, refresh]);

    if (!controllerProps.record) {
        return null;
    }

    const offers = controllerProps.record.offers;


    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6">
                    { translate(`resources.purchases.status.${controllerProps.record.status}`) }
                </Typography>
                <Typography>
                    { translate(`resources.purchases.type.${controllerProps.record.type}`) }
                    { controllerProps.record.type === 'more' ? ` ${controllerProps.record.organization.purchaseMoreThan}€` :
                        controllerProps.record.type === 'less' ? ` ${controllerProps.record.organization.purchaseLessThan}€` :
                        controllerProps.record.type === 'between' ? ` ${controllerProps.record.organization.purchaseLessThan}€` : ''
                    }
                    {
                        controllerProps.record.type === 'between' ? ` ${translate('resources.purchases.type.and')} ${controllerProps.record.organization.purchaseMoreThan}€` : ''
                    }
                </Typography>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <SimpleForm
                className={classes.form}
                basePath={controllerProps.basePath}
                record={controllerProps.record}
                save={controllerProps.save}
                version={controllerProps.version}
                redirect="list"
                resource="purchases"
                toolbar={<PurchaseEditToolbar permissions={permissions} />}
            >
                {
                    permissions && (permissions.purchaseAdmin || permissions.role.type === 'manager') ? 
                    <BooleanInput
                        source="payed"
                        onChange={e => updatePurchase(e, controllerProps.record.id)}
                    /> : <></>
                }
                <UserReferenceField
                    source="user._id"
                    label={translate('resources.purchases.fields.user')}
                    link={false}
                />
                <OrganizationReferenceField link={false} />
                <DateField source="createdAt" formClassName={classes.inlineField} />
                <TextField source="ot" formClassName={classes.inlineField} />
                <TextField source="item" />
                <NumberField
                    source="budget_cost"
                    formClassName={classes.inlineField}
                    options={{
                        style: 'currency',
                        currency: 'EUR',
                    }}
                />
                <NumberField
                    source="budget_sale"
                    formClassName={classes.inlineField}
                    options={{
                        style: 'currency',
                        currency: 'EUR',
                    }}
                />
                {
                    controllerProps.record.comments ? 
                        <TextField source="comments" /> : <></>
                }
                {
                    controllerProps.record.rejectComment ? 
                        <TextField source="rejectComment" /> : <></>
                }
                {
                    controllerProps.record.status === 'approved' && (permissions.purchaseAdmonObra || permissions.role.type === 'manager') ? 
                        <TextInput source="orderNumber" required /> : <></>
                }
                {
                    controllerProps.record.orderNumber ? 
                        <TextField source="orderNumber" /> : <></>
                }
                {
                    controllerProps.record.orderNumber ? 
                        <UserReferenceField
                            source="admon_obra._id"
                            label={translate('resources.purchases.fields.admon_obra')}
                            link={false}
                        /> : <></>
                }
                {/* <UserReferenceField
                    source="manager._id"
                    label={translate('resources.purchases.fields.manager')}
                    link={false}
                /> */}
                {/* <UserReferenceField
                    source="admon._id"
                    label={translate('resources.purchases.fields.admon')}
                    link={false}
                /> */}
                {/* <UserReferenceField
                    source="admon_obra._id"
                    label={translate('resources.purchases.fields.admon_obra')}
                    link={false}
                /> */}

                {
                    controllerProps.record.status === 'pending' ? 
                    <div className={classes.separator}>
                            <Typography variant="h6">
                                { translate(`resources.purchases.selectOffer`) }
                            </Typography>
                        <TextField
                            label={
                                `${translate(`resources.purchases.fields.peference`)} ${translate(`resources.purchases.tabs.${controllerProps.record.preference || 'offer1'}`)}`
                            }
                        />
                        <RadioButtonGroupInput
                            label=""
                            helperText=""
                            source="selectedOffer"
                            initialValue={controllerProps.record.preference || 'offer1'}
                            fullWidth
                            choices={offers.map((o, index) => ({
                                id: `offer${index + 1}`,
                                name: `Oferta ${index + 1}`
                            }))}
                        />
                        <FormDataConsumer>
                            {
                                ({ formData, ...rest }) => {
                                    if (formData.selectedOffer === 'offer1') {
                                        return <OfferData record={formData} num={1}/>
                                    } else if (formData.selectedOffer === 'offer2') {
                                        return <OfferData record={formData} num={2}/>
                                    } else if (formData.selectedOffer === 'offer3') {
                                        return <OfferData record={formData} num={3}/>
                                    } else {
                                        return <></>
                                    }
                                }
                            }
                        </FormDataConsumer>
                    </div>
                    : <></>
                }
                { (controllerProps.record.status === 'accepted' || controllerProps.record.status === 'approved' || controllerProps.record.status === 'telematel') &&
                    <Fragment>
                        <div className={classes.separator}>
                            <Typography variant="h6">
                                { translate(`resources.purchases.selectedOffer`) }
                            </Typography>
                            <FormDataConsumer>
                            {
                                ({ formData, ...rest }) => {
                                    const index = controllerProps.record.offers.findIndex(o => o.accepted);
                                    if (index === 0) {
                                        return <OfferData record={formData} num={1}/>
                                    } else if (index === 1) {
                                        return <OfferData record={formData} num={2}/>
                                    } else {
                                        return <OfferData record={formData} num={3}/>
                                    }
                                }
                            }
                            </FormDataConsumer>
                        </div>
                    </Fragment>
                }
                {
                    controllerProps.record.payed &&
                    <Fragment>
                        <div className={classes.separator}>
                            <FileInput
                                source="justif"
                                label="resources.purchases.fields.justif"
                                accept="application/pdf, image/*"
                                onChange={file => uploadFile(file, controllerProps.record.id) }
                            >
                                <FileField source="src" title="title" />
                            </FileInput>
                        </div>
                        {
                            controllerProps.record.justif ?
                            <div className={classes.separator}>
                                <Typography>
                                    { translate(`resources.purchases.fields.justif`) }
                                </Typography>
                                <CustomImageField label="resources.purchases.fields.justif" record={controllerProps.record} source="justif" />
                            </div>
                            : <></>
                        }
                    </Fragment>
                }
                { !controllerProps.record.payed && <TextInput source="rejectComment" /> }
            </SimpleForm>
        </div>
    );
};

export default PurchaseEdit;
