import React, { useState, useCallback, Fragment } from 'react';
import axios from 'axios';
import {
    Create,
    NumberInput,
    TextInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    DateInput,
    FileInput,
    FileField,
    Toolbar,
    useTranslate,
    FormDataConsumer,
    AutocompleteInput
} from 'react-admin';
import { InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import SaveAndUploadButton from './SaveAndUploadButton';

import URL from '../URL';

const CustomToolbar = props => (
    <Toolbar {...props} >
        <SaveAndUploadButton redirect="invoices" />
    </Toolbar>
);

export const styles = {
    amount: { width: '7em' },
    widthFormGroup: { display: 'inline-block' },
    heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

const useStyles = makeStyles(styles);

const workingDays = (numOfWorkingDays = 7) => {
    let date = new Date();
    // reset time portion
    date.setHours(0,0,0,0);

    while(numOfWorkingDays) {
        date.setDate(date.getDate() - 1);
        let day = date.getDay();
        if(day !== 0 && day !== 6) {
            numOfWorkingDays--;
        }
    }
    return date;
};


const InvoiceCreate = ({permissions, ...props}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [loading, setLoading] = useState();
    const [budgets, setBudgets] = useState();
    const [budget, setBudget] = useState();
    const [noBudgets, setNoBudgets] = useState();
    const [contracts, setContracts] = useState();
    const [contract, setContract] = useState();
    const [noContracts, setNoContracts] = useState();
    const [orders, setOrders] = useState();
    const [noOrders, setNoOrders] = useState();
    const [order, setOrder] = useState();
    const [organizations, setOrganizations] = useState();
    const [organization, setOrganization] = useState();
    const [supplierId, setSupplierId] = useState();
    const [supplier, setSupplier] = useState();
    const [type, setType] = useState();

    const types = [
        { id: 'budget', name: translate('resources.invoices.type.budget') },
        { id: 'order', name: translate('resources.invoices.type.order') },
        { id: 'contract', name: translate('resources.invoices.type.contract') },
        { id: 'payment', name: translate('resources.invoices.type.payment') },
        { id: 'orderless', name: translate('resources.invoices.type.orderless') },
    ];

    const validateInvoice = values => {
        const errors = {};
        const date = new Date(values.date);

        if (!values.date) {
            errors.date = translate('ra.validation.required');
        } else {
            if (permissions.role.type === 'supplier') {
                if (date < workingDays(7)) {
                    errors.date = translate('pos.wrong_date');
                }
            }
        }
        if (!values.images) {
            errors.images = translate('ra.validation.required');
        }
        if (!values.number) {
            errors.number = translate('ra.validation.required');
        }
        if (!values.amount) {
            errors.amount = translate('ra.validation.required');
        }
        if (type === 'budget' && !values.budget) {
          errors.budget = translate('ra.validation.required');
        }
        if (type === 'contract' && !values.contract) {
          errors.contract = translate('ra.validation.required');
        }
        if (type === 'order' && !values.order) {
          errors.order = translate('ra.validation.required');
        }
        if (permissions && (permissions.role.type === 'manager' || permissions.role.type === 'validator') && !values.supplier) {
            errors.supplier = translate('ra.validation.required');
        }

        return errors;
    };

    const fetchOrders = useCallback( async (cod_ent, cod_del, cif_prov) => {
        setLoading(true);
        // Request orders
        const response = await axios.get(`${URL}/orders?cod_ent=${cod_ent}&cod_del=${cod_del}&cif_prov=${cif_prov}`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        });

        if (response.data.error) {
            alert(`${response.data.error}`);
        } else {
            const _orders = response.data
            .map(o => {
                const data =
                    `Numero: ${o.numero_pedido} | Usuario: ${o.codigo_usuario} | Fecha: ${o.fecha_pedido} | Estado: ${o.estado_pedido} | Bruto: ${o.bruto_pedido} | Email: ${o.email_empleado}`;
                return { key: o.numero_pedido, id: data, name: data };
            });
            if (_orders.length) {
                setOrders(_orders);
            } else {
                setNoOrders(true);
            }
        }
        setLoading(false);
    }, []);

    const fetchOrganizations = async () => {
        setLoading(true);
        // Request organizations
        const response = await axios.get(`${URL}/organizations/`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
          });
          const _organizations = response.data.map(o => {
            return {
                    key: o.id, id: o.id, name: `${o.name} - ${o.office}`,
                    nameNumber: o.nameNumber, officeNumber: o.officeNumber
                };
            })
          setOrganizations(_organizations);
          setLoading(false);
          return _organizations;
    };

    const fetchContracts = async (id) => {
        setLoading(true);
        // Request contracts
        const response = await axios.get(`${URL}/contracts?supplier=${id}`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        })
        const _contracts = response.data.filter(c => c.valid && c.signed && c.type !== 'admon');

        if (_contracts.length) {
            setContracts(
                _contracts
                .map(c => {
                    return {key: c.id, id: c.id, name: `${c.number} - ${c.description}`};
                })
            );
        } else {
            setNoContracts(true);
        }
        setLoading(false);
    };

    const fetchSupplier = async (supplierId) => {

      if (supplier && (supplier._id === supplierId)) return;

      setLoading(true);
      // Request supplier
      const response = await axios.get(`${URL}/suppliers/${supplierId}`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
        setSupplier(response.data);
        setSupplierId(response.data._id);
        // get the contracts first
        await fetchContracts(response.data._id);
        // filter the budgets linked to a contract
        let contractBudgets = [];
        if (contracts) {
            contractBudgets = contracts.map(c => c.budget);
        }
        if (!contractBudgets.length) {
            setNoContracts(true);
        }
        const _budgets = response.data.budgets
            .filter(c => !contractBudgets.includes(c.id))
            .map(b => {
                return {key: b.id, id: b.id, name: b.description};
        });

        if(_budgets.length) {
            setBudgets(_budgets);
        } else {
            setNoBudgets(true);
        }
        setLoading(false);
    };

    const resetForm = () => {
        setBudget();
        setBudgets();
        setNoBudgets();
        setOrder();
        setOrders();
        setNoOrders();
        setContract();
        setContracts();
        setNoContracts();
        setOrganization();
        if (permissions.role.type === 'manager' || permissions.role.type === 'validator') {
            setSupplierId();
            setSupplier();
        }
    }

    const typeSelected = async (type) => {
        if (!permissions) return;

        resetForm();
        switch (type) {
            case 'budget':
                setType('budget');
                if (permissions.role.type === 'supplier' && !loading) {
                    setSupplierId(permissions.supplier._id);
                    await fetchSupplier(permissions.supplier._id);
                }
                break;
            case 'order':
                setType('order');
                // set organization
                if (!organizations) {
                    setOrganizations(await fetchOrganizations());
                }
                if (permissions.role.type === 'supplier') {
                    // set supplier
                    setSupplierId(permissions.supplier._id);
                    setSupplier(permissions.supplier);
                }
                break;
            case 'contract':
                setType('contract');
                if (permissions.role.type === 'supplier' && !loading) {
                    fetchSupplier(permissions.supplier._id);
                }
                break;
            case 'payment':
                setType('payment');
                if (permissions.role.type === 'supplier' && !loading) {
                    fetchSupplier(permissions.supplier._id);
                }
                if (!organizations && !loading) {
                    setOrganizations(await fetchOrganizations());
                }
                break;
            case 'orderless':
                setType('orderless');
                if (!organizations && !loading) {
                    setOrganizations(await fetchOrganizations());
                }
                break;

            default:
                break;
        }
    };

    const orgSelected = async (orgId) => {
        const org = organizations.find(o => o.id === orgId);
        setOrganization(org);
        // set supplier
        if (permissions.role.type === 'supplier') {
            setSupplierId(permissions.supplier._id);
        }
        // set supplier (manager does not have direct access)
        if (permissions.role.type === 'manager' || permissions.role.type === 'validator') {
            // fetch supplier
            if (supplierId) {
              await fetchSupplier(supplierId);
              // fetch orders
              await fetchOrders(org.nameNumber, org.officeNumber, supplier.taxid);
            }
        } else if (permissions.role.type === 'supplier') {
            // set supplier
            setSupplier(permissions.supplier);
            // fetch orders
            await fetchOrders(org.nameNumber, org.officeNumber, permissions.supplier.taxid);
        }
    };

    const supplierSelected = async (supId) => {
        resetForm();
        if (supId) await fetchSupplier(supId);
    };

    if (permissions && (permissions.role.type === 'manager' || permissions.role.type === 'supplier' || permissions.role.type === 'validator')) {
        // setup types
        if (permissions.role.type === 'supplier') {
          types.splice(
            types.findIndex(t => t.id === 'orderless'),
            1
          );
        }
        return <Create {...props}>
            <SimpleForm
                toolbar={<CustomToolbar />}
                validate={validateInvoice}
            >
                <Fragment>
                    <Typography variant="h6">
                        {translate('resources.invoices.new')}
                    </Typography>
                </Fragment>
                <FileInput
                    source="images"
                    multiple
                    accept="application/pdf, image/*"
                >
                    <FileField source="images" title="title" />
                </FileInput>
                <FormDataConsumer>
                    {
                    ({ formData, ...rest }) => {
                        if (formData.images && formData.images.length)
                            return <i>{translate('pos.upload_remove_help')}</i>
                        else return <></>

                    }
                    }
                </FormDataConsumer>
                <SelectInput
                    label="resources.invoices.fields.type"
                    source="type"
                    choices={types}
                    onChange={(e) => typeSelected(e.target.value)}
                    fullWidth
                />
                {
                    permissions.role.type === 'manager' || permissions.role.type === 'validator'?
                        <>
                            { type === 'orderless' && organizations ?
                                <SelectInput
                                    label="resources.organizations.fields.organization"
                                    source="organization._id"
                                    optionText="name"
                                    choices={organizations || []}
                                    onChange={(e) => orgSelected(e.target.value)}
                                    fullWidth
                                />
                                : <></>
                            }
                            { type === 'orderless' ?
                                <ReferenceInput
                                    source="validator"
                                    filter={{ invoiceValidator: true }}
                                    reference="users"
                                    label="resources.invoices.fields.validator"
                                    fullWidth
                                >
                                    <AutocompleteInput
                                        optionText={choice =>
                                        choice && choice.id && choice.invoiceValidator ? `${choice.name}` : ""
                                        }
                                    />
                                </ReferenceInput>
                                : <></>
                            }
                            <ReferenceInput
                                source="supplier"
                                reference="suppliers"
                                label="resources.invoices.fields.supplier"
                                filter={{ status: 'accepted' }}
                                onChange={(e) => supplierSelected(e)}
                                fullWidth
                                >
                                <AutocompleteInput
                                    optionText={choice =>
                                        choice && choice.id ? `${choice.business}` : ""
                                    }
                                />
                            </ReferenceInput>
                            { type === 'orderless' ?
                                <TextInput source="ot" label="resources.invoices.fields.ot" />
                                : <></>
                            }
                        </>
                    : <></>
                }
                {
                    type === 'budget' && budgets && budgets.length ?
                        <SelectInput
                            label="resources.invoices.fields.budget"
                            source="budget"
                            optionText="name"
                            choices={budgets || []}
                            onChange={(e) => setBudget(e.target.value)}
                            fullWidth
                        />
                    : type === 'budget' && !noBudgets && supplierId ?
                        <><p>{translate('pos.loading.budgets')}</p></> :
                            type === 'budget' && noBudgets ?
                                <><p>{translate('pos.loading.no_budgets')}</p></> :
                                    <></>
                }
                {
                    (type === 'order' || type === 'payment') && (supplierId) ?
                        <>
                            {
                                organizations ?
                                    <SelectInput
                                        label="resources.organizations.fields.organization"
                                        source="organization._id"
                                        optionText="name"
                                        choices={organizations || []}
                                        onChange={(e) => orgSelected(e.target.value)}
                                        fullWidth
                                    />
                                :
                                    <></>
                            }
                        </>
                    :
                        <></>
                }
                {
                    type === 'contract' && contracts && contracts.length ?
                        <SelectInput
                            label="resources.invoices.fields.contract"
                            source="contract"
                            optionText="name"
                            choices={contracts || []}
                            onChange={(e) => setContract(e.target.value)}
                            fullWidth
                        />
                    : type === 'contract' && !noContracts && supplierId ?
                        <><p>{translate('pos.loading.contracts')}</p></> :
                        type === 'contract' && noContracts ?
                            <><p>{translate('pos.loading.no_contracts')}</p></> :
                                <></>
                }
                {
                    type === 'order' && organization && orders && orders.length ?
                        <SelectInput
                            source="order"
                            choices={orders || []}
                            onChange={(e) => setOrder(e.target.value)}
                            fullWidth
                        />
                    : type === 'order' && organization && !noOrders ?
                        <><p>{translate('pos.loading.orders')}</p></> :
                            noOrders ? <><p>{translate('pos.loading.no_orders')}</p></> : <></>
                }
                {
                    ((type === 'budget' && budget) ||
                    (type === 'contract' && contract) ||
                    (type === 'order' && order) ||
                    (type === 'payment' || type === 'orderless')) && supplierId ?
                        <TextInput source="number" />
                        :  <></>
                }
                {
                    ((type === 'budget' && budget) ||
                    (type === 'contract' && contract) ||
                    (type === 'order' && order) ||
                    (type === 'payment' || type === 'orderless')) && supplierId ?
                        <DateInput source="date" />
                        :  <></>
                }
                {
                    ((type === 'budget' && budget) ||
                    (type === 'contract' && contract) ||
                    (type === 'order' && order) ||
                    (type === 'payment' || type === 'orderless')) && supplierId ?
                        <NumberInput
                            source="amount"
                            className={classes.amount}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        €
                                    </InputAdornment>
                                ),
                            }}
                        />
                        :  <></>
                }


            </SimpleForm>
        </Create>
    } else {
        return (<>{translate('resources.invoices.cannot_create')}</>);
    }

};

export default InvoiceCreate;
