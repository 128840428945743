import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import purple from '@material-ui/core/colors/purple';
import pink from '@material-ui/core/colors/pink';
import grey from '@material-ui/core/colors/grey';

const rowStyle = (record, index, defaultStyle = {}) => {
    if (!record) {
      return {
          ...defaultStyle,
          borderLeftWidth: 5,
          borderLeftStyle: 'solid',
      };
    }

    // same component for invoices and contracts
    let status;
    if (record.hasOwnProperty('status')) {
      status = record.status;
    } else if (record.hasOwnProperty('valid')) {
      // this is a contract
      const expiration = (new Date(record.expiration)).getTime();
      const now = (new Date()).getTime();
      const days = record.expirationNotificationCount * 24 * 60 * 60 * 1000;

      if (expiration - days < now) {
        status = 'expiration';
      } else  if (record.type === 'admon') {
        status = 'admon';
      } else if (record.valid) {
        status = 'valid';
      } else if (!record.valid) {
        status = 'invalid';
      }
    } else if (record.hasOwnProperty('category') && record.hasOwnProperty('title')) {
        // this is an announcement
        const user = JSON.parse(localStorage.getItem('user'));
        if (record.users && record.users.includes(user.id)) {
            status = 'accepted';
        } else {
            return {
                ...defaultStyle,
                borderLeftColor: grey[500],
                borderLeftWidth: 5,
                borderLeftStyle: 'solid',
            };
        }
    }

    if (status === 'telematel' && record.paid)
    return {
        ...defaultStyle,
        borderLeftColor: grey[500],
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    };
    if (status === 'telematel' && !record.paid)
    return {
        ...defaultStyle,
        borderLeftColor: blue[500],
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    };
    if (status === 'accepted' || status === 'resolved')
        return {
            ...defaultStyle,
            borderLeftColor: green[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (status === 'pending' || status === 'new')
        return {
            ...defaultStyle,
            borderLeftColor: orange[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (status === 'delivery_note_pending' || status === 'in_progress' || status === 'approved')
        return {
            ...defaultStyle,
            borderLeftColor: purple[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (status === 'rejected' || status === 'rejected_payout')
        return {
            ...defaultStyle,
            borderLeftColor: red[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (status === 'accounted')
        return {
            ...defaultStyle,
            borderLeftColor: blue[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };

    if (status === 'valid')
        return {
            ...defaultStyle,
            borderLeftColor: green[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (status === 'invalid')
        return {
            ...defaultStyle,
            borderLeftColor: red[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (status === 'admon')
        return {
            ...defaultStyle,
            borderLeftColor: blue[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (status === 'expiration')
        return {
            ...defaultStyle,
            borderLeftColor: orange[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (status === 'rejectadmin' || status === 'rejected_comparative')
        return {
            ...defaultStyle,
            borderLeftColor: pink[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (status === 'payed')
        return {
            ...defaultStyle,
            borderLeftColor: grey[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    return defaultStyle;
};

export default rowStyle;
