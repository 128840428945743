import React, { forwardRef } from 'react';
import { AppBar,
    UserMenu,
    MenuItemLink,
    useTranslate
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles } from '@material-ui/core/styles';

import Logo from './logos/white/Logo';
import LogoDemios from './logos/white/LogoDemios';
import { isPublicRoute } from '../routes';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    logo: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontFamily: 'Roboto',
        fontWeight: 'bolder'
    },
    spacer: {
        flex: 1,
    },
    spacer1: {
        flex: 0.05,
    },
    displayNone: {
        display: 'none'
    }
});

const ConfigurationMenu = forwardRef((props, ref) => {
    const translate = useTranslate();
    const user = JSON.parse(localStorage.getItem('user'));
    return (
        <>
          <MenuItemLink
              ref={ref}
              to="/profile"
              /*{leftIcon={<AccountCircleIcon />}}*/
              onClick={props.onClick}
              primaryText={
                  <>
                      <p>
                        {user.name}
                        <br />
                        <small>{user.email}</small>
                      </p>
                  </>
              }
          />
          <MenuItemLink
              ref={ref}
              to="/configuration"
              primaryText={translate('pos.configuration')}
              leftIcon={<SettingsIcon />}
              onClick={props.onClick}
          />
          <MenuItemLink
              ref={ref}
              to="/reset-password"
              primaryText={translate('pos.reset_password_menu')}
              leftIcon={<LockIcon />}
              onClick={props.onClick}
          />
          {
              user && user.role.type === 'manager' ?
              <MenuItemLink
                ref={ref}
                to="/upload-users-csv"
                primaryText={translate('resources.users.uploadcsv')}
                leftIcon={<PublishIcon />}
                onClick={props.onClick}
              /> :
              <></>
          }
        </>
    );
});

const CustomUserMenu = props => (
    <UserMenu {...props}>
        <ConfigurationMenu />
    </UserMenu>
);

const CustomAppBar = props => {
    const classes = useStyles();
    return (
        <AppBar
            {...props}
            userMenu={<CustomUserMenu />}
            className={isPublicRoute(window.location.hash) ? classes.displayNone : ''}
        >
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <Logo />
            <span className={classes.spacer1} />
            <LogoDemios />
            <span className={classes.spacer} />
        </AppBar>
    );
};

export default CustomAppBar;
