import React from 'react';
import {
    AutocompleteInput,
    DateInput,
    Filter,
    ReferenceInput,
    // SearchInput,
    SelectInput,
    useTranslate
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useFilterStyles = makeStyles({
    status: { width: 150 },
});

const IssueFilter = props => {
    const classes = useFilterStyles();
    const translate = useTranslate();

    return (
        <Filter {...props}>
            {/* <SearchInput source="q" alwaysOn /> */}
            <SelectInput
                source="status"
                choices={[
                    { id: 'new', name: translate("resources.issues.status.new") },
                    { id: 'in_progress', name: translate("resources.issues.status.in_progress") },
                    { id: 'resolved', name: translate("resources.issues.status.resolved") },
                ]}
                className={classes.status}
            />
            <SelectInput
                source="category"
                choices={[
                    { id: 'Order', name: translate("resources.issues.categories.Order") },
                    { id: 'InvoiceRejected', name: translate("resources.issues.categories.InvoiceRejected") },
                    { id: 'PaymetNotReceived', name: translate("resources.issues.categories.PaymetNotReceived") },
                    { id: 'Technical', name: translate("resources.issues.categories.Technical") },
                    { id: 'Other', name: translate("resources.issues.categories.Other") },
                ]}
                className={classes.status}
            />
            <ReferenceInput
              source="user"
              reference="users"
              label={translate("resources.users.fields.username")}
            >
                <AutocompleteInput
                    optionText={choice =>
                        choice && choice.id ? `${choice.name}` : ""
                    }
                />
            </ReferenceInput>
            <DateInput source="date_gte" />
            <DateInput source="date_lte" />
        </Filter>
    );
};

export default IssueFilter;
