import React from 'react';
import {
    AutocompleteInput,
    Filter,
    ReferenceInput,
    SearchInput,
    useTranslate
} from 'react-admin';

const BudgetFilter = props => {
    const translate = useTranslate();

    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <ReferenceInput
              source="user"
              reference="users"
              label={translate("resources.users.fields.user")}
            >
                <AutocompleteInput
                    optionText={choice =>
                        choice && choice.id ? `${choice.name}` : ""
                    }
                />
            </ReferenceInput>
            <ReferenceInput
                source="organization"
                reference="organizations"
                label={translate("resources.organizations.fields.organization")}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
        </Filter>
    );
};

export default BudgetFilter;
