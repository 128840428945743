import React, { useState, useEffect, useCallback } from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';

// import Welcome from './Welcome';
import MonthlyRevenue from './MonthlyRevenue';
// import PendingInvoices from './PendingInvoices';
import PendingAnnouncements from './PendingAnnouncements';
import PendingSuppliers from './PendingSuppliers';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const Dashboard = ({permissions}) => {
    const [state, setState] = useState({});
    const [loading, setLoading] = useState();
    const [loadingPMPS, setLoadingPMPS] = useState();
    const version = useVersion();
    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    // const fetchInvoices = useCallback(async (perms) => {
    //     if (!permissions || !perms)
    //       return;
    //
    //     setLoading(true);
    //
    //     let filter;
    //     if (perms.supplier) {
    //       filter = { status: 'pending', supplier: perms.supplier.id };
    //     } else {
    //       filter = { status: 'pending' };
    //     }
    //     const { data: invoices } = await dataProvider.getList('invoices', {
    //         filter: filter,
    //         sort: { field: 'date', order: 'DESC' },
    //         pagination: { page: 1, perPage: 100 },
    //     });
    //
    //     const nbPendingInvoices = invoices.reduce(nb => ++nb, 0);
    //     const pendingInvoices = invoices.slice(0, Math.min(10, invoices.length));
    //     setState(state => ({ ...state, pendingInvoices, nbPendingInvoices }));
    //     setLoading(false);
    // }, [dataProvider, permissions]);

    const fetchAnnouncements = useCallback(async (perms) => {
        if (!permissions || !perms)
          return;

        setLoading(true);

        let filter = { id: permissions.id };

        try {
          const { data: announcements } = await dataProvider.getList('announcements', {
            filter: filter,
            sort: { field: 'createdAt', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
          });

          const nbPendingAnnouncements = announcements.reduce(nb => ++nb, 0);
          const pendingAnnouncements = announcements.slice(0, Math.min(10, announcements.length));
          setState(state => ({ ...state, pendingAnnouncements, nbPendingAnnouncements }));
          setLoading(false);
        } catch (e) {
          return;
        }
    }, [dataProvider, permissions]);

    const fetchSuppliers = useCallback(async (perms) => {
        setLoading(true);
        
        try {            
            const { data: suppliers } = await dataProvider.getList('suppliers', {
                filter: { status: 'pending' },
                sort: { field: 'status', order: 'DESC' },
                pagination: { page: 1, perPage: 100 },
            });
    
            const nbPendingSuppliers = suppliers.reduce(nb => ++nb, 0);
            const pendingSuppliers = suppliers.slice(0, Math.min(10, suppliers.length));
            setState(state => ({ ...state, pendingSuppliers, nbPendingSuppliers }));
            setLoading(false);
        } catch (e) {
            return;
        }
    }, [dataProvider]);

    const fetchPaymentMethodPendingSuppliers = useCallback(async (perms) => {
        setLoadingPMPS(true);
        
        try {            
            const { data: suppliers } = await dataProvider.getList('suppliers', {
                filter: { status: 'payment_confirm' },
                sort: { field: 'status', order: 'DESC' },
                pagination: { page: 1, perPage: 100 },
            });
    
            const nbPaymentPendingSuppliers = suppliers.reduce(nb => ++nb, 0);
            const paymentPendingSuppliers = suppliers.slice(0, Math.min(10, suppliers.length));
            setState(state => ({ ...state, paymentPendingSuppliers, nbPaymentPendingSuppliers }));
            setLoadingPMPS(false);
        } catch (e) {
            return;
        }
    }, [dataProvider]);

    useEffect(() => {
        if (permissions
                && (
                        permissions.role.type === 'manager'
                    || permissions.role.type === 'supplier'
                    || permissions.invoiceValidator
                )
            ) {
          // if (!state.pendingInvoices && !loading) {
          //     fetchInvoices(permissions);
          // }
          if (permissions.role.type === 'manager' || permissions.supplierPaymentValidator) {
              if (!state.pendingSuppliers && !loading) {
                  fetchSuppliers();
              }
              if (!state.paymentPendingSuppliers && !loadingPMPS) {
                fetchPaymentMethodPendingSuppliers();
              }
          }
        }
        if (permissions) {
            if (!state.pendingAnnouncements && !loading) {
                fetchAnnouncements(permissions);
              }
        }
    }, [version, permissions]); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        // nbPendingInvoices,
        // pendingInvoices,
        nbPendingAnnouncements,
        pendingAnnouncements,
        revenue,
        // organizations,
        nbPendingSuppliers,
        pendingSuppliers,
        nbPaymentPendingSuppliers,
        paymentPendingSuppliers
    } = state;


    if (permissions) {
      /* if (permissions.role.type === 'supplier' ||  permissions.invoiceValidator ) {
        return isXSmall ? (
            <div>
                <div style={styles.flexColumn}>
                    <div style={styles.flex}>
                        <PendingInvoices
                            nb={nbPendingInvoices}
                            invoices={pendingInvoices}
                            organizations={organizations}
                        />
                    </div>
                </div>
            </div>
        ) : isSmall ? (
            <div style={styles.flexColumn}>
                <div style={styles.flex}>
                    <PendingInvoices
                        nb={nbPendingInvoices}
                        invoices={pendingInvoices}
                        organizations={organizations}
                    />
                </div>
            </div>
        ) : (
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <PendingInvoices
                            nb={nbPendingInvoices}
                            invoices={pendingInvoices}
                            organizations={organizations}
                        />
                    </div>
                </div>
            </div>
        );
      } else */ if (permissions.role.type === 'manager' || permissions.supplierPaymentValidator || permissions.supplierValidator) {
        return isXSmall ? (
            <div>
                <div style={styles.flexColumn}>
                    <div style={styles.flex}>
                        <PendingSuppliers
                            nb={nbPendingSuppliers}
                            suppliers={pendingSuppliers}
                        />
                    </div>
                    <div style={styles.flex}>
                        <PendingSuppliers
                            nb={nbPaymentPendingSuppliers}
                            suppliers={paymentPendingSuppliers}
                            paymentPending={true}
                        />
                    </div>
                </div>
            </div>
        ) : isSmall ? (
            <div style={styles.flexColumn}>
                <div style={styles.flex}>
                    <PendingSuppliers
                        nb={nbPendingSuppliers}
                        suppliers={pendingSuppliers}
                    />
                </div>
                <div style={styles.flex}>
                    <PendingSuppliers
                        nb={nbPaymentPendingSuppliers}
                        suppliers={paymentPendingSuppliers}
                        paymentPending={true}
                    />
                </div>
            </div>
        ) : (
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    {
                      false ?
                          <div style={styles.flex}>
                              <MonthlyRevenue value={revenue || 0 } />
                          </div>
                      : <></>
                    }
                    <div style={styles.singleCol}>
                        <PendingSuppliers
                            nb={nbPendingSuppliers}
                            suppliers={pendingSuppliers}
                        />
                    </div>
                    <div style={styles.leftCol}>
                        <div style={styles.flex}>
                            <PendingSuppliers
                                nb={nbPaymentPendingSuppliers}
                                suppliers={paymentPendingSuppliers}
                                paymentPending={true}
                            />
                        </div>
                    </div>
                </div>
                <div style={styles.rightCol}>
                    <div style={styles.flex}>
                        <PendingAnnouncements
                            nb={nbPendingAnnouncements}
                            announcements={pendingAnnouncements}
                        />
                    </div>
                </div>
            </div>
        );
      } else {
        return (
            <div style={styles.flex}>
                <div style={styles.rightCol}>
                    <div style={styles.flex}>
                        <PendingAnnouncements
                            nb={nbPendingAnnouncements}
                            announcements={pendingAnnouncements}
                        />
                    </div>
                </div>
            </div>
        );
      }
    } else {
      return <></>
    }
};

export default Dashboard;
