import React, { useCallback } from 'react';
import { useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ThumbDown from '@material-ui/icons/ThumbDown';
import { useTranslate, useUpdate, useNotify, useRedirect } from 'react-admin';

/**
 * This custom button demonstrate using a custom action to update data
 */
const RejectButton = ({ record, resource }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirectTo = useRedirect();
    const formState = useFormState();

    const [reject, { loading }] = useUpdate(
        resource,
        record.id,
        {
            id: record.id,
            status: 'rejected',
            employee: record.employee,
            expenseType: record.expenseType,
            organization: record.organization,
            date: record.date,
            notes: record.notes,
            fileUrl: record.fileUrl,
            ot: record.ot,
            email: record.email,
            plate: record.plate
        },
        record,
        {
            undoable: true,
            onSuccess: () => {
                notify(
                    `resources.${resource}.notification.rejected_success`,
                    'info',
                    {},
                    true
                );
                redirectTo(`/${resource}`);
            },
            onFailure: () => {
                notify(
                    `resources.${resource}.notification.rejected_error`,
                    'warning'
                );
            },
        }
    );


    const handleClick = useCallback(() => {
        if (!formState.valid) {
            return;
        }
        reject();
    }, [
        formState.valid,
        reject,
    ]);

    return record && (
            record.status === 'pending' || record.status === 'accepted'
        ) ? (
        <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleClick}
            disabled={loading}
        >
            <ThumbDown
                color="primary"
                style={{
                    paddingRight: '0.5em',
                    color: 'red'
                }}
            />
            {
              translate('resources.tickets.action.reject')
            }
        </Button>
    ) : (
        <span />
    );
};

RejectButton.propTypes = {
    record: PropTypes.object,
};

export default RejectButton;
