import React, { useCallback } from 'react';
import { useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ThumbUp from '@material-ui/icons/ThumbUp';
import { useTranslate, useUpdate, useNotify, useRedirect } from 'react-admin';

/**
 * This custom button demonstrate using useUpdate to update data
 */
const PayButton = ({ record, action = 'pay' }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirectTo = useRedirect();
    // const [update] = useUpdate('tickets');
    const formState = useFormState();

    const [approve, { loading }] = useUpdate(
      'tickets',
      record.id,
      {
        id: record.id,
        employee: record.employee,
        user: record.user,
        expenseType: record.expenseType,
        paymentMethod: record.paymentMethod,
        organization: record.organization,
        date: record.date,
        notes: record.notes,
        fileUrl: record.fileUrl,
        ot: record.ot,
        email: record.email,
        plate: record.plate,
        paid: true
      },
      record,
      {
          undoable: true,
          onSuccess: () => {
              notify(
                  'resources.tickets.notification.approved_success',
                  'info',
                  {},
                  true
              );
              redirectTo('/tickets');
          },
          onFailure: () => {
              notify(
                  'resources.tickets.notification.approved_error',
                  'warning'
              );
          },
      }
    );

    const handleClick = useCallback(() => {
        if (!formState.valid) {
            return;
        }
        approve();
    }, [
        formState.valid,
        approve,
    ]);

    return record ? (
        <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleClick}
            disabled={loading}
        >
            <ThumbUp
                color="primary"
                style={{ paddingRight: '0.5em', color: 'green' }}
            />
            { translate('resources.tickets.action.pay') }
        </Button>
    ) : (
        <span />
    );
};

PayButton.propTypes = {
    record: PropTypes.object,
};

export default PayButton;
