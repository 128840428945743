import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const OtReferenceField = props => (
    <ReferenceField
        label="Budget"
        source="budget.id"
        reference="budgets"
        {...props}
    >
        <TextField source="ot" />
    </ReferenceField>
);

OtReferenceField.defaultProps = {
    source: 'budget.id',
    label: 'resources.budgets.fields.ot',
    addLabel: true,
};

export default OtReferenceField;
