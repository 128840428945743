import React from 'react';
import {
    useEditController,
    useTranslate,
    SimpleForm,
    TextField,
    required,
    ReferenceInput,
    AutocompleteInput,
    linkToRecord,
    RichTextField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {
    Typography,
    IconButton,
    Card,
    Tooltip,
    CardHeader,
} from '@material-ui/core';
import ContentCreate from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';

import IssueEditToolbar from './IssueEditToolbar';
import IssueCategoryField from './IssueCategoryField';
import CustomImageField from '../invoices/CustomImageField';
import IssueStatusField from './IssueStatusField';
import UserReferenceField from '../users/UserReferenceField';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
    card: {
        margin: '0 0 0em 0em',
        width: '100%',
    },
    cardHeader: {
        alignItems: 'flex-start',
    },
    clamp: {
        // display: '-webkit-box',
        // '-webkit-line-clamp': 3,
        // '-webkit-box-orient': 'vertical',
        // overflow: 'hidden',
    },
}));

const IssueEdit = ({ onCancel, permissions, ...props }) => {
    const classes = useStyles();
    const controllerProps = useEditController(props);
    const translate = useTranslate();
    if (!controllerProps.record) {
        return null;
    }

    const record = controllerProps.record;

    const author = (id, comment) => {
        return record.user.id === id ?
            record.user.name :
                record.issue_manager.id === id
                    ? record.issue_manager.name : '-'
    };

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6">
                    {translate('resources.invoices.detail')}
                </Typography>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <SimpleForm
                className={classes.form}
                basePath={controllerProps.basePath}
                record={record}
                save={controllerProps.save}
                version={controllerProps.version}
                redirect="list"
                resource="issues"
                toolbar={<IssueEditToolbar permissions={permissions}/>}
            >
                <UserReferenceField source="user.id"
                    label={translate('resources.budgets.fields.user')}
                    link={false}
                />
                <div className={classes.title}></div>
                <IssueCategoryField formClassName={classes.inlineField} source="category" />
                <IssueStatusField formClassName={classes.inlineField} source="status" />
                <TextField formClassName={classes.inlineField} source="date" />
                {
                    permissions.issueManager && record.issue_manager && record.status !== 'resolved' ?
                        <ReferenceInput
                            source="issue_manager.id"
                            reference="users"
                            label="resources.users.fields.user"
                            filterToQuery={ () => {
                                return ({ issueManager: true });
                            } }
                            fullWidth
                        >
                            <AutocompleteInput
                                optionText={choice =>
                                    choice && choice.id ? `${choice.name}` : ""
                                }
                            />
                        </ReferenceInput>
                    :
                        <UserReferenceField source="issue_manager.id"
                            label={translate('resources.users.fields.user')}
                            link={false}
                        />
                }
                <TextField source="description" />
                {/*
                    permissions.role.type === 'manager' || permissions.issueManager ?
                        <TextInput
                            source="comment"
                            label={translate('resources.issues.fields.comment')}
                            maxRows={25}
                            multiline
                            fullWidth
                            validate={required()}
                        />
                    : <TextField source="comment" />
                */}
                {
                    record.attachments.length ? <CustomImageField source="attachments" /> : <></>
                }
                {
                    record.status !== 'resolved' ?
                        <>
                            <TextField source="" label={translate('resources.issues.fields.commento')} />
                            <RichTextInput
                                source="comment"
                                label={translate('resources.issues.fields.comment')}
                                maxRows={25}
                                multiline
                                fullWidth
                                validate={required()}
                            />
                        </>
                    : <></>

                }
            </SimpleForm>
            {
                record.comments.length ?
                    <div className={classes.title}>
                        <Typography variant="h6">
                            {translate('resources.issues.fields.comments')}
                        </Typography>
                    </div>
                : <></>
            }

            {record.comments
                .sort((d, f) => d.createdAt < f.createdAt)
                .map(comment =>
                    (
                        <Card className={classes.card} key={comment.id} >
                            <CardHeader
                                className={classes.cardHeader}
                                action={
                                    author(comment.author) === permissions.name ?
                                        <EditButton record={comment} basePath="/comments" /> :
                                        <></>
                                    }
                                title={
                                    <Typography variant="h6">
                                        {author(comment.author)}
                                    </Typography>
                                }
                                subheader={
                                    <>
                                        <Typography variant="body2">{new Date(comment.createdAt).toLocaleDateString()}</Typography>
                                        <RichTextField record={comment} source="comment" />
                                    </>
                                }
                            />
                        </Card>
                    )
            )}
        </div>
    );
};


const EditButton = ({ record, basePath }) => {
    const translate = useTranslate();
    return (
        <Tooltip title={translate('ra.action.edit')}>
            <IconButton
                aria-label={translate('ra.action.edit')}
                component={Link}
                to={linkToRecord(basePath, record && record.id)}
            >
                <ContentCreate />
            </IconButton>
        </Tooltip>
    );
};

export default IssueEdit;
