import React from 'react';
import {
  Datagrid,
  TextField,
  BooleanField,
  DateField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ContractTypeField from './ContractTypeField';
import OrganizationReferenceField from '../organizations/OrganizationReferenceField';
import SupplierReferenceField from '../suppliers/SupplierReferenceField';
import BudgetReferenceField from '../budgets/BudgetReferenceField';
import BudgetUserReferenceField from '../budgets/BudgetUserReferenceField';

import rowStyle from '../components/rowStyle';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const ContractListDesktop = props => {
    const classes = useListStyles();
    return (
        <Datagrid
            rowClick="edit"
            rowStyle={rowStyle}
            classes={{ headerRow: classes.headerRow }}
            optimized
            {...props}
        >
            <TextField source="number" />
            <TextField source="description" />
            <OrganizationReferenceField link={false} />
            <BudgetReferenceField />
            <BudgetUserReferenceField link={false} />
            <TextField source="itemDesc" />
            <ContractTypeField source="type" />
            <TextField source="amount" />
            <SupplierReferenceField link={false} />
            <BooleanField source="signed" />
            <BooleanField source="valid" />
            <DateField source="signDate" />
            <DateField source="expiration" />
        </Datagrid>
    );
};

export default ContractListDesktop;
