import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
    useEditController,
    useTranslate,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput,
    BooleanInput,
    SelectInput,
    FormDataConsumer,
    PasswordInput,
    AutocompleteInput,
    Link
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import URL from '../URL';

const Separator = () => <Box pt="1em" />;

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
}));

const UserEdit = ({ onCancel, ...props }) => {
    const classes = useStyles();
    const controllerProps = useEditController(props);
    const translate = useTranslate();
    const [roles, setRoles] = useState();

    const fetchRoles = useCallback(async () => {
      // Request organizations.
      axios.get(`${URL}/users-permissions/roles`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      }).then(function (response) {
          setRoles(response.data.roles
              .filter(r => ['validator', 'supplier', 'manager', 'employee'].includes(r.type))
              .map(r => {
                  return {key: r.id, id: r.id, type: r.type, name: translate(`pos.roles.${r.type}`)};
          }));
        })
    }, [translate]);

    useEffect(() => {
        if (!roles) {
          fetchRoles();
        }
    }, [roles, fetchRoles]);


    if (!controllerProps.record) {
        return null;
    }

    if (roles) {

      return (
          <div className={classes.root}>
              <div className={classes.title}>
                  <Typography variant="h6">
                      {translate('resources.invoices.detail')}
                  </Typography>
                  <IconButton onClick={onCancel}>
                      <CloseIcon />
                  </IconButton>
              </div>
              <SimpleForm
                  className={classes.form}
                  basePath={controllerProps.basePath}
                  record={controllerProps.record}
                  save={controllerProps.save}
                  version={controllerProps.version}
                  redirect="list"
                  resource="users"
              >
                  <TextInput
                      source="name"
                      label={translate('resources.users.fields.name')}
                      validate={required()}
                      fullWidth
                  />
                  <TextInput
                      source="email"
                      label={translate('resources.users.fields.email')}
                      validate={required()}
                      fullWidth
                  />
                  <PasswordInput
                      source="password"
                      label={translate('resources.users.fields.password')}
                      fullWidth
                  />
                  <TextInput
                      source="bcUserId"
                      label={translate('resources.users.fields.bcUserId')}
                      fullWidth
                  />
                  <SelectInput
                      source="role._id"
                      label="resources.users.fields.role"
                      validate={required()}
                      choices={roles}
                      fullWidth
                  />
                  <ReferenceInput
                      label="resources.organizations.fields.organization"
                      source="organization._id"
                      reference="organizations"
                      fullWidth
                  >
                      <SelectInput optionText="name"  />
                  </ReferenceInput>
                  <FormDataConsumer>
                      {
                        ({ formData, ...rest }) => {
                            const role = roles.find(r => r.id === formData.role.id);
                            if (role && role.type === 'employee')
                                return (
                                    <>
                                    <ReferenceInput
                                        source="ticketValidatorUserId"
                                        reference="users"
                                        label="resources.users.fields.ticketValidatorUserId"
                                        filter={{'ticketValidator': true}}
                                        allowEmpty={true}
                                        fullWidth
                                    >
                                        <AutocompleteInput
                                            optionText={choice =>
                                                choice && choice.id ? `${choice.name}` : ""
                                            }
                                        />
                                    </ReferenceInput>
                                    <BooleanInput
                                        label="resources.users.fields.foreman"
                                        source="foreman"
                                        fullWidth
                                    />
                                    <BooleanInput
                                        label="resources.users.fields.org_admon_obra"
                                        source="purchaseAdmonObra"
                                        fullWidth
                                    />
                                    </>
                                )
                            else if (role && (role.type === 'validator' || role.type === 'manager'))
                            return (
                                <>
                                { <ReferenceInput
                                    source="ticketValidatorUserId"
                                    reference="users"
                                    label="resources.users.fields.ticketValidatorUserId"
                                    filter={{'ticketValidator': true}}
                                    allowEmpty={true}
                                    fullWidth
                                >
                                    <AutocompleteInput
                                        optionText={choice =>
                                            choice && choice.id ? `${choice.name}` : ""
                                        }
                                    />
                                </ReferenceInput> }

                                <Separator />
                                <Typography variant="h6" gutterBottom>
                                    {translate('resources.organizations.invoices')}
                                </Typography>
                                <BooleanInput
                                    label="resources.users.fields.invoice_validator"
                                    source="invoiceValidator"
                                    fullWidth
                                />
                                <BooleanInput
                                    label="resources.users.fields.invoice_payer"
                                    source="invoicePayer"
                                    fullWidth
                                />
                                <BooleanInput
                                    label="resources.users.fields.invoice_creator"
                                    source="invoiceCreator"
                                    fullWidth
                                />

                                <Separator />
                                <Typography variant="h6" gutterBottom>
                                    {translate('resources.organizations.orders')}
                                </Typography>
                                <BooleanInput
                                    label="resources.users.fields.order_validator"
                                    source="orderValidator"
                                    fullWidth
                                />

                                <Separator />
                                <Typography variant="h6" gutterBottom>
                                    {translate('resources.organizations.tickets')}
                                </Typography>
                                <BooleanInput
                                    label="resources.users.fields.ticket_validator"
                                    source="ticketValidator"
                                    fullWidth
                                />
                                <BooleanInput
                                    label="resources.users.fields.ticket_payer"
                                    source="ticketPayer"
                                    fullWidth
                                />
                                <BooleanInput
                                    label="resources.users.fields.ticket_registrar"
                                    source="ticketRegistrar"
                                    fullWidth
                                />
                                <BooleanInput
                                    label="resources.users.fields.ticket_admin"
                                    source="ticketAdmin"
                                    fullWidth
                                />

                                <Separator />
                                <Typography variant="h6" gutterBottom>
                                    {translate('resources.organizations.suppliers')}
                                </Typography>
                                <BooleanInput
                                    label="resources.users.fields.supplier_validator"
                                    source="supplierValidator"
                                    fullWidth
                                />
                                <BooleanInput
                                    label="resources.users.fields.supplierPaymentValidator"
                                    source="supplierPaymentValidator"
                                    fullWidth
                                />

                                <Separator />
                                <Typography variant="h6" gutterBottom>
                                    {translate('resources.organizations.purchases')}
                                </Typography>
                                <BooleanInput
                                    label="resources.users.fields.org_admon_obra"
                                    source="purchaseAdmonObra"
                                    fullWidth
                                />
                                <BooleanInput
                                    label="resources.users.fields.purchase_admin"
                                    source="purchaseAdmin"
                                    fullWidth
                                />
                                <BooleanInput
                                    label="resources.users.fields.foreman"
                                    source="foreman"
                                    fullWidth
                                />

                                <Separator />
                                <Typography variant="h6" gutterBottom>
                                    {translate('resources.organizations.issues')}
                                </Typography>
                                <BooleanInput
                                    label="resources.users.fields.issue_manager"
                                    source="issueManager"
                                    fullWidth
                                />
                                <Separator />
                                <Typography variant="h6">
                                    {translate('resources.complaints.config')}
                                    <BooleanInput
                                        label={translate('resources.users.fields.complaintManager')}
                                        source="complaintManager"
                                        fullWidth
                                    />
                                </Typography>
                                
                                <Separator />
                                <Typography variant="h6">
                                    {translate('resources.users.fields.blocked')} {formData.blocked ? translate('resources.users.active') : '' }
                                    <BooleanInput
                                        label={formData.blocked ? translate('resources.users.unblock') : translate('resources.users.block')}
                                        source="blocked"
                                        fullWidth
                                    />
                                </Typography>
                            </>)
                            if (role && role.type === 'supplier')
                            return (
                                <>
                                    {/* <ReferenceInput
                                        source="supplier._id"
                                        reference="suppliers"
                                        label="resources.suppliers.fields.supplier"
                                        allowEmpty={false}
                                        fullWidth
                                    >
                                        <AutocompleteInput
                                            optionText={choice =>
                                                choice && choice.id ? `${choice.name} | ${choice.business}` : ""
                                            }
                                        />
                                    </ReferenceInput> */}
                                    <Link
                                        to={`/suppliers/${formData.supplier.id}`}
                                        className={classes.link}
                                        key="supplier-link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {translate('pos.supplierLink')}
                                    </Link>
                                </>
                            )
                        else return <></>
                        }
                      }
                  </FormDataConsumer>
              </SimpleForm>
          </div>
      );
    } else {
        return (<></>);
    }

};

export default UserEdit;
