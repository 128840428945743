import React from 'react';
import {
    Create,
    FormTab,
    TabbedForm,
    ImageInput,
    ImageField,
    required,
    Toolbar,
    TextInput,
    NumberInput
} from 'react-admin';

import SaveAndUploadButton from './SaveAndUploadButton';

const CustomToolbar = props => (
    <Toolbar {...props} >
        <SaveAndUploadButton redirect="organizations" />
    </Toolbar>
);

const OrganizationCreate = ({permissions, ...props}) => {

    return (
        <Create {...props}>
            <TabbedForm toolbar={<CustomToolbar />} >
                <FormTab label="resources.organizations.tabs.avatar">
                    <ImageInput source="avatar" accept="image/*">
                        <ImageField source="avatar" title="title" />
                    </ImageInput>
                </FormTab>
                <FormTab label="resources.organizations.tabs.details" path="details">
                    <NumberInput source="nameNumber" validate={required()} />
                    <TextInput source="name" validate={required()} />
                    <NumberInput source="officeNumber" validate={required()} />
                    <TextInput source="office" validate={required()} />
                    <TextInput source="email" validate={required()} />
                    <NumberInput source="purchaseLessThan" validate={required()} />
                    <NumberInput source="purchaseMoreThan" validate={required()} />
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default OrganizationCreate;
