import React from 'react';
import {
    AutocompleteInput,
    DateInput,
    Filter,
    ReferenceInput,
    SelectInput,
    useTranslate,
    usePermissions,
    TextInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core';


const useFilterStyles = makeStyles({
    status: { width: 150 },
});

const InvoiceFilter = props => {
    const classes = useFilterStyles();
    const translate = useTranslate();
    const permissions = usePermissions();

    if (permissions.loaded && ((permissions.permissions.role.type === 'manager') || (permissions.permissions.role.type === 'validator'))) {
        return <Filter {...props}>
            <TextInput source="q" label="resources.invoices.filters.number" alwaysOn />
            <SelectInput
                source="status"
                choices={[
                    { id: 'accepted', name: translate("pos.invoice_status.accepted") },
                    { id: 'pending', name: translate("pos.invoice_status.pending") },
                    { id: 'rejected', name: translate("pos.invoice_status.rejected") },
                    { id: 'accounted', name: translate("pos.invoice_status.accounted") },
                    { id: 'delivery_note_pending', name: translate("pos.invoice_status.delivery_note_pending") },
                    { id: 'rejectadmin', name: translate("pos.invoice_status.rejectadmin") },
                    { id: 'payed', name: translate("pos.invoice_status.payed") },
                ]}
                className={classes.status}
            />
            <SelectInput
                source="type"
                label="resources.invoices.fields.type"
                choices={[
                    { id: 'order', name: translate("resources.invoices.type.order") },
                    { id: 'budget', name: translate("resources.invoices.type.budget") },
                    { id: 'contract', name: translate("resources.invoices.type.contract") },
                    { id: 'payment', name: translate("resources.invoices.type.payment") },
                    { id: 'orderless', name: translate("resources.invoices.type.orderless") },
                ]}
                className={classes.status}
            />
            <ReferenceInput
                source="supplier"
                reference="suppliers"
                label={translate("resources.suppliers.fields.supplier")}
            >
                <AutocompleteInput
                    optionText={choice =>
                        choice && choice.id ? `${choice.business}` : ""
                    }
                />
            </ReferenceInput>
            <ReferenceInput
                source="budget"
                reference="budgets"
                label={translate("resources.budgets.fields.budget")}
            >
                <AutocompleteInput optionText="description" />
            </ReferenceInput>
            <ReferenceInput
                source="organization"
                reference="organizations"
                label={translate("resources.organizations.fields.organization")}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                source="user"
                reference="users"
                label={translate("resources.users.fields.user")}
                filterToQuery={ () => {
                    return ({ invoiceValidator: true });
                } }
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <DateInput source="date_gte" />
            <DateInput source="date_lte" />
        </Filter>
    } else {
        return <Filter {...props}>
            <TextInput source="q" label="resources.invoices.filters.number" alwaysOn />
            <SelectInput
                source="status"
                choices={[
                    { id: 'accepted', name: translate("pos.invoice_status.accepted") },
                    { id: 'pending', name: translate("pos.invoice_status.pending") },
                    { id: 'rejected', name: translate("pos.invoice_status.rejected") },
                    { id: 'accounted', name: translate("pos.invoice_status.accounted") },
                    { id: 'delivery_note_pending', name: translate("pos.invoice_status.delivery_note_pending") },
                    { id: 'rejectadmin', name: translate("pos.invoice_status.rejectadmin") },
                    { id: 'payed', name: translate("pos.invoice_status.payed") },
                ]}
                className={classes.status}
            />
            <SelectInput
                source="type"
                label="resources.invoices.fields.type"
                choices={[
                    { id: 'order', name: translate("resources.invoices.type.order") },
                    { id: 'budget', name: translate("resources.invoices.type.budget") },
                    { id: 'contract', name: translate("resources.invoices.type.contract") },
                    { id: 'payment', name: translate("resources.invoices.type.payment") },
                    { id: 'orderless', name: translate("resources.invoices.type.orderless") },
                ]}
                className={classes.status}
            />
            <DateInput source="date_gte" />
            <DateInput source="date_lte" />
        </Filter>;
    }
};

export default InvoiceFilter;
