import React from 'react';
import { ReferenceField } from 'react-admin';

import FullNameField from '../components/FullNameField';

const SupplierReferenceField = props => (
    <ReferenceField source="supplier.id" reference="suppliers" {...props}>
        <FullNameField />
    </ReferenceField>
);

SupplierReferenceField.defaultProps = {
    source: 'supplier.id',
    label: 'resources.suppliers.fields.supplier',
    addLabel: true,
};

export default SupplierReferenceField;
