import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import {
    Select
} from 'final-form-material-ui';
import axios from 'axios';
import {
    useTranslate,
    useNotify,
    Confirm,
    useRedirect,
    Link
} from 'react-admin';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import SecurityIcon from '@material-ui/icons/Security';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';

import { lightTheme } from '../layout/themes';
import URL from '../URL';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'url(/img/fondo1.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 200,
        maxWidth: 800,
        marginTop: '3em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
        padding: '10px'
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    select: {
        marginTop: '1em',
        width: '100%'
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    return: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'right',
        color: theme.palette.grey[500],
        padding: '10px'
    },
}));

const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const ComplaintCreate = ({permissions, ...props}) => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const [open, setOpen] = useState(false);
    const [id, setId] = useState();
    const redirect = useRedirect();
    const [selectedDate, setSelectedDate] = React.useState(new Date());

    const categories = [
        { id: 'gestion', name: translate("resources.complaints.categories.gestion") },
        { id: 'robo', name: translate("resources.complaints.categories.robo") },
        { id: 'acoso', name: translate("resources.complaints.categories.acoso") },
        { id: 'trato', name: translate("resources.complaints.categories.trato") },
        { id: 'informacion', name: translate("resources.complaints.categories.informacion") },
        { id: 'fraude', name: translate("resources.complaints.categories.fraude") },
        { id: 'other', name: translate("resources.complaints.categories.other") },
    ];

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const validateComplaint = values => {
        const errors = {};     
        return errors;
    };

    const sendComplaint = data => {
        setLoading(true);

        // Request API.
        axios.post(`${URL}/complaints`, {
              description: data.description,
              category: data.category,
              date: selectedDate
          })
          .then(({ data }) => {
              setLoading(false);
              notify(
                  `resources.complaints.notification.created_success`,
                  'info',
                  {},
                  false
              );
              setId(data.id);
              setOpen(true);
          })
          .catch(error => {
              setLoading(false);
              notify(
                  typeof error === 'string'
                      ? error
                      : typeof error === 'undefined' || !error.message
                      ? 'resources.complaints.notification.created_error'
                      : error.message,
                  'warning'
              );
          });
    };
    
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        setOpen(false);
        redirect(`/complaint/${id}`)
    };

    return (
    <Form
        onSubmit={sendComplaint}
        validate={validateComplaint}
        render={({ handleSubmit: sendComplaint }) => (
            <form onSubmit={sendComplaint} noValidate>
                <div className={classes.main}>
                    <Card className={classes.card}>
                        <Link
                            to="/complaint-find"
                            className={classes.link}
                            key={id}
                        >
                            <div className={classes.avatar}>
                                <Avatar className={classes.icon}>
                                    <SecurityIcon />
                                </Avatar>
                            </div>
                        </Link>
                        <div className={classes.hint}>
                            {translate('resources.complaints.public.title')}
                        </div>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    name="category"
                                    label={translate('resources.complaints.fields.category')}
                                    formControlProps={{className: classes.select}}
                                    component={Select}
                                >
                                {
                                    categories.map(c => (<MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>))
                                }
                                </Field>
                            </div>
                            <div className={classes.input}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="Cuándo sucedió"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="description"
                                    component={renderInput}
                                    label={translate('resources.complaints.fields.description')}
                                    disabled={loading}
                                    multiline
                                    minRows={10}
                                    maxRows={10}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="involved"
                                    component={renderInput}
                                    label={translate('resources.complaints.fields.involved')}
                                    disabled={loading}
                                    multiline
                                    minRows={6}
                                    maxRows={6}
                                />
                            </div>
                        </div>
                        <CardActions className={classes.actions}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={loading}
                                className={classes.button}
                                fullWidth
                            >
                                {translate('pos.submit')}
                            </Button>
                            <Confirm
                                isOpen={open}
                                title={translate('resources.complaints.public.copy')}
                                content={`ID: ${id}`}
                                onConfirm={handleConfirm}
                                onClose={handleDialogClose}
                            />
                        </CardActions>
                        <div className={classes.return}>
                            <Link
                                to="/complaint-find"
                                className={classes.link}
                                key={id}
                            >
                                {translate('pos.return')}
                            </Link>
                        </div>
                    </Card>
                </div>
            </form>
        )}
    />)
};

ComplaintCreate.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in ResetPassword won't get
// the right theme
const ComplaintCreateWithTheme = props => (
    <ThemeProvider theme={createTheme(lightTheme)}>
        <ComplaintCreate {...props} />
    </ThemeProvider>
);

export default ComplaintCreateWithTheme;
