import React from 'react';
import { Datagrid, TextField, DateField, BooleanField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

// import StarRatingField from '../components/StarRatingField';
import SupplierStatusField from './SupplierStatusField';
import SupplierPaymentField from './SupplierPaymentField';
import OrganizationReferenceField from '../organizations/OrganizationReferenceField';

import rowStyle from '../components/rowStyle';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const SupplierListDesktop = props => {
    const classes = useListStyles();
    return (
        <Datagrid
            rowClick="edit"
            rowStyle={rowStyle}
            classes={{ headerRow: classes.headerRow }}
            optimized
            {...props}
        >
            {/* <TextField source="name" /> */}
            <TextField source="business" />
            <TextField source="email" />
            <TextField source="city" />
            <TextField source="taxid" />
            <SupplierPaymentField source="payment" />
            <TextField source="days" />
            {/* <TextField source="payment" /> */}
            <TextField source="employee" />
            <OrganizationReferenceField link={false} />
            {/*<StarRatingField />*/}
            <DateField source="createdAt" />
            <SupplierStatusField source="status" />
            <BooleanField source="paymentMethodFinal" />
        </Datagrid>
    );
};

export default SupplierListDesktop;
