import React from 'react';
import { Datagrid, NumberField, TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import rowStyle from '../components/rowStyle';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const OrganizationListDesktop = props => {
    const classes = useListStyles();
    return (
        <Datagrid
            rowClick="edit"
            rowStyle={rowStyle}
            classes={{ headerRow: classes.headerRow }}
            optimized
            {...props}
        >
            <NumberField source="nameNumber" />
            <TextField source="name" />
            <NumberField source="officeNumber" />
            <TextField source="office" />
            <TextField source="email" />
        </Datagrid>
    );
};

export default OrganizationListDesktop;
