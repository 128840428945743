import React, { /*Fragment*/ } from 'react';
import MuiToolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

// import { SaveButton } from 'react-admin';

const useStyles = makeStyles({
    root: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const AlbaranEditToolbar = ({
    handleSubmitWithRedirect,
    invalid,
    record,    
    saving,
    permissions
}) => {
    const classes = useStyles();

    return (
        <MuiToolbar className={classes.root}>
            {/* <SaveButton
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                invalid={invalid}
                saving={saving}
                redirect="list"
                submitOnEnter={true}
                label='resources.albaranes.action.comment'
            /> */}
        </MuiToolbar>
    );

};

export default AlbaranEditToolbar;
