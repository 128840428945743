import React from 'react';
import { useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ThumbUp from '@material-ui/icons/ThumbUp';
import { useTranslate, useUpdate, useNotify, useRedirect } from 'react-admin';
import axios from 'axios';

import URL from '../URL';

/**
 * This custom button demonstrate using useUpdate to update data
 */
const AcceptButton = ({ record, resource, action }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirectTo = useRedirect();
    const formState = useFormState();

    const registerUser = async () => {
        return new Promise((resolve, reject) => {
            const password = Math.random().toString(36).slice(-8);
            // console.log(`user:${record.email} password:${password}`);
            axios
                .post(`${URL}/auth/local/register`, {
                    username: record.email,
                    email: record.email,
                    name: record.name,
                    password,
                    supplier: record._id,
                })
                .then(response => {
                    notify(
                        `resources.users.notification.created_success`,
                        'info',
                        {},
                        true
                    );
                    // set user
                    // setUser(response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    notify(
                        `pos.email_exists`,
                        'warning'
                    );
                    resolve();
                });
        });
    }

    const [approve, { loading }] = useUpdate(
        resource,
        record.id,
        {
            id: record.id,
            status: action === 'payment_confirm' ? 'pending' : 'accepted',
            organization: formState.values.organization,
            type: formState.values.type,
            name: formState.values.name,
            phone: formState.values.phone,
            business: formState.values.business,
            taxid: formState.values.taxid,
            city: formState.values.city,
            province: formState.values.province,
            address: formState.values.address,
            zipcode: formState.values.zipcode,
            country: formState.values.country,
            payment: formState.values.payment,
            days: formState.values.days,
            bic: formState.values.bic,
            swift: formState.values.swift,
            paymentMethodFinal: formState.values.paymentMethodFinal,
            paymentMethodComment: formState.values.paymentMethodComment,
            comment: formState.values.comment,
            purchaseFamily: formState.values.purchaseFamily
        },
        record,
        {
            undoable: true,
            onSuccess: () => {
                notify(
                    `resources.${resource}.notification.approved_success`,
                    'info',
                    {},
                    true
                );
                redirectTo(`/${resource}`);
            },
            onFailure: () => {
                notify(
                    `resources.${resource}.notification.approved_error`,
                    'warning'
                );
            },
        }
    );


    if (record) {
        if (record.status === 'pending' ) {
            return <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={async () => {
                    if (resource === 'suppliers') {
                        try {
                            const { user } = await registerUser();
                            // await getSupplierRoleId();
                            // await setSupplierRole(user);
                            // console.log(user);
                            if (user) {
                                record.status = 'accepted';
                                approve();
                            }
                        } catch (e) { console.error(e) }
                    } else {
                        approve();
                    }
                }}
                disabled={loading}
            >
                <ThumbUp
                    color="primary"
                    style={{ paddingRight: '0.5em', color: 'green' }}
                />
                {translate(`resources.${resource}.action.accept`)}
            </Button>
        } else if (record.status === 'payment_confirm') {
            return <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={async () => {
                    approve();
                }}
                disabled={loading}
            >
                <ThumbUp
                    color="primary"
                    style={{ paddingRight: '0.5em', color: 'green' }}
                />
                {translate(`resources.${resource}.action.acceptPaymentMethod`)}
            </Button>
        } else {
            return <></>;
        }
    } else {
        return <></>;
    }
};

AcceptButton.propTypes = {
    record: PropTypes.object,
};

export default AcceptButton;
