import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import TextField from '@material-ui/core/TextField';
// import Link from '@material-ui/core/Link';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Notification, useTranslate, useNotify } from 'react-admin';

import { lightTheme } from '../layout/themes';

import URL from '../URL';


const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        // background: 'url(https://source.unsplash.com/random/1600x900)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 400,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    select: {
        marginTop: '1em',
        width: '100%'
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
}));


const UploadUsersCsv = ({ location }) => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const [csv, setCsv] = useState();
    // const user = JSON.parse(localStorage.getItem('user'));

    const selectFile = (event) => {
        setCsv(event.target.files[0]);
    }

    const uploadCsv = async (event) => {
        setLoading(true);

        let formData = new FormData();
        formData.append("file", csv);

        return new Promise((resolve, reject) => {
            axios
                .put(`${URL}/upload-users-csv/data`, formData, {
                    headers: {
                        'Content-Type': "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                })
                .then(response => {
                    notify(
                        'pos.csvok',
                        'info',
                        {},
                        false
                    );
                    setLoading(false);
                })
                .catch(error => {
                    notify(
                        `pos.upload_failed`,
                        'warning'
                    );
                    setLoading(false);
                    resolve();
                });
        });
    }

    // render
    return (
        <form noValidate>
            <div className={classes.main}>
                <Card className={classes.card}>
                    <h2 className={classes.avatar}>
                        {translate('resources.users.uploadcsv')}
                    </h2>
                    <div className={classes.form}>
                        <div className={classes.hint}>
                            {translate('resources.users.csvhelp')}
                        </div>
                        <hr></hr>
                        <pre>
                            Apellidos y Nombre Trabajador,Empresa,Correo Electrónico
                        </pre>
                        <pre>
                            "APELLIDOS, NOMBRE",Empresa,correo@electronico.com
                        </pre>
                        <hr></hr>
                        <small>
                          {translate('resources.users.csvhelp1')}
                          :<b> {translate('resources.users.csvhelp2')}</b>
                        </small>
                    </div>
                    <div className={classes.form}>
                        <div className={classes.input}>
                        <input
                            id="csv"
                            name="csv"
                            type="file"
                            disabled={loading}
                            onChange={selectFile}
                            accept=".csv"
                        />
                        </div>
                    </div>
                    <CardActions className={classes.actions}>
                        <Button
                            className="btn-choose"
                            variant="outlined"
                            component="span"
                            disabled={!csv || loading}
                            onClick={uploadCsv}
                        >
                            {
                                loading ? translate('resources.users.importing')
                                : translate('resources.users.uploadcsvb')
                            }
                        </Button>
                    </CardActions>
                </Card>
                <Notification />
            </div>
        </form>
    );
};

UploadUsersCsv.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in UploadUsersCsv won't get
// the right theme
const UploadUsersCsvWithTheme = props => (
    <ThemeProvider theme={createTheme(lightTheme)}>
        <UploadUsersCsv {...props} />
    </ThemeProvider>
);

export default UploadUsersCsvWithTheme;
