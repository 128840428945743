import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const PurchaseAmountReferenceField = props => {
  if (!props.record) return <></>;
  if (props.record && props.record.offers) {
    if (props.record.offers.length === 0) {
        return <></>;
    }
    if (props.record.offers.length === 1) {
        return <ReferenceField source="id" reference="offers" record={props.record.offers[0]}>
            <TextField source="amount"/>
        </ReferenceField>
    }
    const preference = props.record.preference || "offer1";
    const strIndex = preference.split('offer')[1];
    const index = parseInt(strIndex) - 1;
    
    return <ReferenceField source="id" reference="offers" record={props.record.offers[index]}>
        <TextField source="amount"/>
    </ReferenceField>
  }
  return (
    props.record && props.record.offers && props.record.offers.length === 1 ?
        <ReferenceField source="id" reference="offers" record={props.record.offers[0]}>
            <TextField source="amount"/>
        </ReferenceField>
    : <>-</>
)};

PurchaseAmountReferenceField.defaultProps = {
    source: 'offers',
    label: 'resources.offers.fields.amount',
    addLabel: true,
};

export default PurchaseAmountReferenceField;
