import React, { Fragment, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { List, downloadCSV, /* usePermissions */ } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { Route, useHistory } from 'react-router-dom';
import { Drawer, useMediaQuery, makeStyles } from '@material-ui/core';
// import BulkAcceptButton from './BulkAcceptButton';
// import BulkRejectButton from './BulkRejectButton';
// import BulkPayButton from './BulkPayButton';
import axios from 'axios';

import URL from '../URL';

import InvoiceListMobile from './InvoiceListMobile';
import InvoiceListDesktop from './InvoiceListDesktop';
import InvoiceFilter from './InvoiceFilter';
import InvoiceEdit from './InvoiceEdit';
// import InvoiceShow from './InvoiceShow';

// const InvoicesBulkActionButtons = (props) => {
//     const user = usePermissions();
//     console.log(props);
//     if (user && user.permissions && user.permissions.invoiceValidator) {
//         return (
//             <Fragment>
//                 <BulkAcceptButton {...props} />
//                 <BulkRejectButton {...props} />
//             </Fragment>
//         );
//     }
//     if (user && user.permissions && user.permissions.invoicePayer) {
//         return (
//             <Fragment>
//                 <BulkPayButton {...props} />
//             </Fragment>
//         );
//     }
//     return (<></>);
// };


const exporter = invoices => {
    const types = {
        budget: "Presupuesto",
        contract: "Contrato",
        order: "Pedido",
        payment: "Abono",
        orderless: "Sin pedido"
    }
    const invoicesForExport = invoices.map(invoice => {
        const invoiceForExport = {
            fecha_factura: new Date(invoice.date).toLocaleDateString(),
            fecha_registro: new Date(invoice.createdAt).toLocaleDateString(),
            numero: invoice.number, 
            proveedor: invoice.supplier.business,
            organizacion: invoice.supplier.name,
            importe: invoice.amount,
            estado: invoice.status,
            tipo: types[invoice.type],
            encargado: invoice.user.name
        };
        return invoiceForExport;
    });
    jsonExport(invoicesForExport, {
        // order fields in the export
        headers: ['fecha_factura', 'fecha_registro','numero', 'proveedor', 'organizacion', 'importe', 'estado', 'tipo', 'encargado']
    }, (err, csv) => {
        downloadCSV(csv, 'invoices');
    });
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        // marginRight: 400,
    },
    drawerPaper: {
        zIndex: 100,
    },
}));

const InvoiceList = ({ permissions, ...props }) => {
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const history = useHistory();
    const [budgets, setBudgets] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [fetch, setFetch] = useState();

    const handleClose = useCallback(() => {
        history.push('/invoices');
    }, [history]);

    const fetchContracts = useCallback(() => {
      // Request supplier
      axios.get(`${URL}/contracts/`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      }).then((response) => {
          const contracts = response.data
            .filter(c => c.valid && c.signed && c.type !== 'admon');

          setContracts(
              contracts
                .map(c => {
                    return {key: c.id, id: c.id, name: c.description};
            })
          );
        });
    }, []);

    const fetchSupplier = useCallback((supplierId) => {
      // flag fetch
      setFetch(true);
      // Request supplier
      axios.get(`${URL}/suppliers/${supplierId}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      }).then(async (response) => {
          // filter the budgets linked to a contract
          const contractBudgets = contracts.map(c => c.budget);
          setBudgets(
            response.data.budgets
                .filter(c => !contractBudgets.includes(c.id))
                .map(b => {
                    return {key: b.id, id: b.id, name: b.description};
            })
          );
        });
    }, [setFetch, contracts]);

    useEffect(() => {
        if (permissions && permissions.role.type === 'supplier') {
          if (!fetch) {
            fetchContracts();
            fetchSupplier(permissions.supplier._id);
          }
        }
    }, [permissions, fetchSupplier, fetchContracts, fetch]);

    return (
        <div className={classes.root}>
            <Route path="/invoices/:id">
                {({ match }) => {
                    const isMatch = !!(
                        match &&
                        match.params &&
                        match.params.id !== 'create' &&
                        permissions
                    );
                    if (permissions && (permissions.role.type !== 'supplier' || budgets.length || contracts.length)) {
                      return (
                        <Fragment>
                            <List
                                {...props}
                                className={classnames(classes.list, {
                                    [classes.listWithDrawer]: isMatch,
                                })}
                                bulkActionButtons={false}
                                filters={<InvoiceFilter />}
                                filter={
                                    permissions.role.type === 'manager' ? 
                                        {} : 
                                    permissions.role.type === 'supplier' ?
                                        { 'supplier': permissions.supplier.id } :
                                    permissions.role.type === 'validator' ?
                                        { 'user': permissions.id } :
                                        { 'user': 'None'}
                                }
                                perPage={25}
                                sort={{ field: 'createdAt', order: 'DESC' }}
                                exporter={exporter}
                            >
                                {isXSmall ? (
                                    <InvoiceListMobile permissions={permissions} />
                                ) : (
                                    <InvoiceListDesktop permissions={permissions} />
                                )}
                            </List>
                            <Drawer
                                variant="persistent"
                                open={isMatch}
                                anchor="right"
                                onClose={handleClose}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                                {isMatch ? (
                                    <InvoiceEdit
                                        id={match.params.id}
                                        onCancel={handleClose}
                                        permissions={permissions}
                                        {...props}
                                    />
                                ) : null}
                            </Drawer>
                        </Fragment>
                    );
                  } else {
                    return (<></>);
                  }
                }}
            </Route>
        </div>
    );
};

export default InvoiceList;
