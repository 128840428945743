import React from 'react';
import { useTranslate } from 'react-admin';

const InvoiceTypeField = props => {
    const translate = useTranslate();

    if (!props.record) return <></>;

    if (props.record.type === 'order') {
        return <>{`🧾 ${translate('resources.invoices.type.order')}`}</>;
    } else if (props.record.type === 'budget') {
        return <>{`📓 ${translate('resources.invoices.type.budget')}`}</>;
    } else if (props.record.type === 'contract') {
        return <>{`⚖️ ${translate('resources.invoices.type.contract')}`}</>;
    } else if (props.record.type === 'orderless') {
        return <>{`🗒️${translate('resources.invoices.type.orderless')}`}</>;
    } else if (props.record.type === 'payment') {
        return <>{`💰${translate('resources.invoices.type.payment')}`}</>;
    } else {
      return <>{`💰${translate('pos.unknown')}`}</>;
    }
};

InvoiceTypeField.defaultProps = {
    source: 'invoice',
    label: 'resources.invoices.fields.type',
    addLabel: true,
};

export default InvoiceTypeField;
