import React, { Fragment, useCallback } from 'react';
import classnames from 'classnames';
import { List, CardActions } from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import { Drawer, useMediaQuery, makeStyles } from '@material-ui/core';

import AnnouncementListMobile from './AnnouncementListMobile';
import AnnouncementListDesktop from './AnnouncementListDesktop';
import AnnouncementFilter from './AnnouncementFilter';
import AnnouncementEdit from './AnnouncementEdit';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        // marginRight: 400,
    },
    drawerPaper: {
        zIndex: 100,
    },
}));

const NoneActions = props => (
    <CardActions />
);

const AnnouncementList = ({ permissions, ...props }) => {
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const history = useHistory();

    const handleClose = useCallback(() => {
        history.push('/announcements');
    }, [history]);


    return (
        <div className={classes.root}>
            <Route path="/announcements/:id">
                {({ match }) => {
                    const isMatch = !!(
                        match &&
                        match.params &&
                        match.params.id !== 'create' &&
                        permissions
                    );
                    if (permissions) {
                      return (
                        permissions.role.type === 'manager' ?
                            <Fragment>
                                <List
                                    {...props}
                                    className={classnames(classes.list, {
                                        [classes.listWithDrawer]: isMatch,
                                    })}
                                    filters={<AnnouncementFilter />}
                                    perPage={25}
                                    sort={{ field: 'date', order: 'DESC' }}
                                >
                                    {isXSmall ? (
                                        <AnnouncementListMobile permissions={permissions} />
                                    ) : (
                                        <AnnouncementListDesktop permissions={permissions} />
                                    )}
                                </List>
                                <Drawer
                                    variant="persistent"
                                    open={isMatch}
                                    anchor="right"
                                    onClose={handleClose}
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                >
                                    {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                                    {isMatch ? (
                                        <AnnouncementEdit
                                            id={match.params.id}
                                            onCancel={handleClose}
                                            permissions={permissions}
                                            {...props}
                                        />
                                    ) : null}
                                </Drawer>
                            </Fragment>
                        :
                        <Fragment>
                            <List
                                {...props}
                                className={classnames(classes.list, {
                                    [classes.listWithDrawer]: isMatch,
                                })}
                                filters={<AnnouncementFilter />}
                                filter={
                                    {'category': permissions.role.type}
                                }
                                actions={<NoneActions />}
                                perPage={25}
                                sort={{ field: 'createdAt', order: 'DESC' }}
                            >
                                {isXSmall ? (
                                    <AnnouncementListMobile permissions={permissions} />
                                ) : (
                                    <AnnouncementListDesktop permissions={permissions} />
                                )}
                            </List>
                            <Drawer
                                variant="persistent"
                                open={isMatch}
                                anchor="right"
                                onClose={handleClose}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                                {isMatch ? (
                                    <AnnouncementEdit
                                        id={match.params.id}
                                        onCancel={handleClose}
                                        permissions={permissions}
                                        {...props}
                                    />
                                ) : null}
                            </Drawer>
                        </Fragment>
                    );
                  } else {
                    return (<></>);
                  }
                }}
            </Route>
        </div>
    );
};

export default AnnouncementList;
