import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {
    useTranslate,
    useNotify,
    Notification,
    Link
} from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import SecurityIcon from '@material-ui/icons/Security';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import {
    Typography,
    Card,
    CardActions,
    CardContent
} from '@material-ui/core';
import { lightTheme } from '../layout/themes';
import URL from '../URL';

const useStyles = makeStyles(theme => ({
    comment: {
        minWidth: 275,
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'url(/img/fondo1.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 200,
        maxWidth: 800,
        marginTop: '3em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        marginBottom: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
        padding: '10px'
    },
    id: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[800],
        padding: '10px',
        fontStyle: 'italic'
    },
    status: {
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[800],
        padding: '10px'
    },
    description: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'left',
        color: theme.palette.grey[1000],
        padding: '10px'
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    select: {
        marginTop: '1em',
        width: '100%'
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    bold: {
        fontWeight: 'bold',
        paddingLeft: '10px'
    },
    italic: {
        fontWeight: 'italic'
    },
    table: {
        marginLeft: '1em',
        marginRight: '1em',
        border: "1px solid #ddd",
        width: '94%'
    },
    return: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'right',
        color: theme.palette.grey[500],
        padding: '10px'
    }
}));

const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const ComplaintView = ({permissions, ...props}) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    let location = useLocation();

    const id = location?.pathname.split('/')[2] || ''

    useEffect(() => {
        setLoading(true);
        // Request API.
        axios.get(`${URL}/complaints/${id}`)
          .then(({ data }) => {
            setLoading(false);
            setData(data);
          })
          .catch(error => {
              setLoading(false);
              notify('resources.complaints.public.findError', 'warning');
          });
      }, [id, notify]);

    const comment = ({comment}) => {
        setLoading(true);
        // Request API.
        axios.post(`${URL}/complaint-comments`, {
              complaintId: data.id,
              comment
          })
          .then(() => {
              notify(
                  `pos.comment_sent`,
                  'info',
                  {},
                  false
              );
              setTimeout(() => window.location.reload(), 2000)
          })
          .catch(error => {
              setLoading(false);
              notify(
                  typeof error === 'string'
                      ? error
                      : typeof error === 'undefined' || !error.message
                      ? 'resources.issues.notification.comment_error'
                      : error.message,
                  'warning'
              );
          });
    };

    function createMarkup(content) {
        return {__html: content };
    }

    return (
        <Form
        onSubmit={comment}
        render={({ handleSubmit: comment }) => (
        <form onSubmit={comment} noValidate>
            <div className={classes.main}>
                <Card className={classes.card}>
                    <Link
                        to="/complaint-find"
                        className={classes.link}
                        key={id}
                    >
                        <div className={classes.avatar}>
                            <Avatar className={classes.icon}>
                                <SecurityIcon />
                            </Avatar>
                        </div>
                    </Link>
                    <div className={classes.hint}>
                        {translate('resources.complaints.public.title')}
                    </div>

                    <div className={classes.id}>
                        ~{translate('resources.complaints.public.data')}~
                    </div>

                    <div className={classes.tableContainer}>
                        <table className={classes.table}>
                            <tr>
                                <td>ID</td>
                                <td className={classes.bold}>{id}</td>
                            </tr>
                            <tr>
                                <td>{translate('resources.complaints.fields.status')}</td>
                                <td className={classes.bold}>
                                    {translate(`resources.complaints.status.${data?.status}`)}
                                    {data?.status === 'new' ? ' 🆕' : data?.status === 'in_progress' ? ' ⚙️' : ' ✅'}
                                </td>
                            </tr>
                            <tr>
                                <td>{translate('resources.complaints.fields.createdAt')}</td>
                                <td className={classes.bold}>{new Date(data?.createdAt).toLocaleDateString()}</td>
                            </tr>
                            <tr>
                                <td>{translate('resources.complaints.fields.was')}</td>
                                <td className={classes.bold}>{new Date(data?.date).toLocaleDateString()}</td>
                            </tr>
                        </table> 
                    </div>
                    <div className={classes.id}>
                        ~{translate('resources.complaints.fields.description')}~
                    </div>
                    <div className={classes.description}>
                        {data?.description}
                    </div>
                    {
                        data?.involved ? <>
                            <div className={classes.id}>
                                ~{translate('resources.complaints.fields.involved')}~
                            </div>
                            <div className={classes.description}>
                                {data?.involved}
                            </div>
                        </> : <></>
                    }
                    <div className={classes.id}>
                        ~{translate('resources.complaints.fields.comments')}~
                    </div>
                    {
                        data?.comments?.sort((a, b) => a.createdAt < b.createdAt).map(comment => (
                            <Card className={classes.comment} key={comment.id}>
                                <CardContent>
                                    <Typography variant="h6" component="h2">
                                        { comment.author ? translate('resources.complaints.public.comite') : translate('resources.complaints.public.user') }
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                        { (new Date(comment.createdAt)).toLocaleString() }
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        <div dangerouslySetInnerHTML={createMarkup(comment.comment)}></div>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))
                    }
                    {
                        data?.status !== 'resolved' &&
                        <>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        name="comment"
                                        component={renderInput}
                                        label={translate('resources.complaints.fields.comment')}
                                        disabled={loading}
                                        multiline
                                        minRows={6}
                                        maxRows={6}
                                    />
                                </div>
                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    disabled={loading}
                                    className={classes.button}
                                >
                                    {translate('resources.issues.action.comment')}
                                </Button>
                            </CardActions>
                        </>
                    }
                    <div className={classes.return}>
                        <Link
                            to="/complaint-find"
                            className={classes.link}
                            key={id}
                        >
                            {translate('pos.return')}
                        </Link>
                    </div>
                </Card>
                <Notification />
            </div>
        </form>
      )}
    />)
};

ComplaintView.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in ResetPassword won't get
// the right theme
const ComplaintViewWithTheme = props => (
    <ThemeProvider theme={createTheme(lightTheme)}>
        <ComplaintView {...props} />
    </ThemeProvider>
);

export default ComplaintViewWithTheme;
