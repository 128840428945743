import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Create,
    TextInput,
    SelectInput,
    SimpleForm,
    FileInput,
    FileField,
    useTranslate,
    FormDataConsumer,
    Toolbar,
} from 'react-admin';

import SaveAndUploadButton from './SaveAndUploadButton';
import URL from '../URL';

const CustomToolbar = props => (
    <Toolbar {...props} >
        <SaveAndUploadButton redirect="albaranes" />
    </Toolbar>
);

const AlbaranCreate = ({permissions, ...props}) => {
    const translate = useTranslate();
    const [organizations, setOrganizations] = useState();
    const [organization, setOrganization] = useState();

    const validateAlbaran = values => {
        const errors = {};

        if (!values.attachments) {
            errors.attachments = translate('ra.validation.required');
        }
        if (!values.ot) {
            errors.ot = translate('ra.validation.required');
        }
        if (!values.organization) {
            errors.organization = translate('ra.validation.required');
        }

        return errors;
    };

    useEffect(() => {
      axios.get(`${URL}/organizations/`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      })
      .then((response) => {
        setOrganizations(response.data.map(o => ({
            key: o._id, id: o._id, name: `${o.name} - ${o.office}`,
            nameNumber: o.nameNumber, officeNumber: o.officeNumber })
        ));
        
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.organization) {
            setOrganization(user.organization._id);
        }
      })
      .catch(error => {
        alert(error.message)
      });
    }, []);

    return <Create {...props}>
        <SimpleForm
            toolbar={<CustomToolbar />}
            validate={validateAlbaran}
        >
            <SelectInput
                label="resources.organizations.fields.organization"
                source="organization"
                optionText="name"
                initialValue={organization}
                choices={organizations}
                fullWidth
                required
            />
            <TextInput source="ot" fullWidth required/>
            <TextInput source="description" fullWidth />
            <FileInput
                source="attachments"
                accept="application/pdf, image/*"
                multiple
                required
            >
                <FileField source="attachments" title="title" required/>
            </FileInput>
            <FormDataConsumer>
                {
                    ({ formData, ...rest }) => {
                        if (formData.attachment)
                            return <i>{translate('pos.upload_remove_help')}</i>
                        else return <></>

                    }
                }
            </FormDataConsumer>
        </SimpleForm>
    </Create>

};

export default AlbaranCreate;
