import React, { useState, useCallback, useEffect } from 'react';
import { Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import RichTextInput from 'ra-input-rich-text';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom'

import {
    useTranslate,
    Notification,
    useNotify,
    Title,
    usePermissions,
    useRedirect
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import URL from '../URL';

const useStyles = makeStyles({
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
    input: { marginTop: '1em' },
});

const CommentEdit = ({...props}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const [loading, setLoading] = useState(true);
    const [comment, setComment] = useState();
    const notify = useNotify();
    const redirectTo = useRedirect();

    const params = useParams();
    const commentId = params.id;

    const fetchComment = useCallback((commentId) => {
        axios.get(`${URL}/comments/${commentId}`, {
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
        }).then( (response) => {
            setLoading(false);
            setComment(response.data);
        })
      }, []);

    useEffect(() => {
        fetchComment(commentId);
    }, [fetchComment, commentId]);

    const handleSubmit = data => {
        setLoading(true);

        axios.put(`${URL}/comments/${commentId}`, {
            comment: data.comment,
        },
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(response => {
            setTimeout(() => {
                setLoading(false);
                redirectTo('/issues');
            }, 500);
        })
        .catch(error => {
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                    ? 'ra.auth.sign_in_error'
                    : error.message,
                'warning'
            );
        });
    };

    const validate = values => {
        const errors = {};
        if (!values.comment) {
            errors.comment = translate('ra.validation.required');
        }
        return errors;
    };

    return (
        <Form
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={{
            comment: comment && comment.comment
        }}
        render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
                <Title title={permissions ? permissions.name : ''} />
                <div className={classes.main}>
                    <Card className={classes.card}>

                        <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                                {translate('pos.edit_comment')}
                                <br />
                            </Typography>
                            <div className={classes.input}>
                              <RichTextInput
                                  source="comment"
                                  label={translate('resources.issues.fields.comment')}
                                  multiline
                                  fullWidth
                              />
                            </div>
                        </CardContent>
                        <CardActions className={classes.actions}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                className={classes.button}
                                fullWidth
                            >
                                {loading && (
                                    <CircularProgress
                                        size={25}
                                        thickness={2}
                                    />
                                )}
                                {translate('pos.save')}
                            </Button>
                        </CardActions>
                    </Card>
                    <Notification />
                </div>
            </form>
        )}
    />
    );
};

export default CommentEdit;
