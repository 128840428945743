import React, { useState, useCallback } from 'react';
import axios from 'axios';
import {
    useEditController,
    useTranslate,
    TextInput,
    NumberInput,
    SimpleForm,
    DateField,
    DateInput,
    TextField,
    ReferenceInput,
    AutocompleteInput,
    BooleanInput,
    SelectInput,
    useRefresh
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';

import BudgetReferenceField from '../budgets/BudgetReferenceField';
import OtReferenceField from '../budgets/OtReferenceField';
import ContractReferenceField from '../contracts/ContractReferenceField';
import ItemReferenceField from '../contracts/ItemReferenceField';
import SupplierReferenceField from '../suppliers/SupplierReferenceField';
import OrderReferenceField from '../orders/OrderReferenceField';
import InvoiceEditToolbar from './InvoiceEditToolbar';
import CustomImageField from './CustomImageField';
import UserReferenceField from '../users/UserReferenceField';
import InvoiceStatusField from './InvoiceStatusField';
import PurchaseReferenceField from '../purchases/PurchaseReferenceField';
import URL from '../URL';

const Separator = () => <Box pt="1em" />;

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
}));

const InvoiceEdit = ({ onCancel, permissions, ...props }) => {
    const classes = useStyles();
    const controllerProps = useEditController(props);
    const translate = useTranslate();
    const [changed, setChanged] = useState();
    const refresh = useRefresh();

    const updateInvoice = useCallback( async (status, id) => {
        axios.put(`${URL}/invoices/${id}`, { status }, { headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}})
        .then(() => {
                refresh();
            }).catch(error => console.log);
    }, [refresh]);

    if (!controllerProps.record) {
        return null;
    }

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6">
                    {
                        controllerProps.record.purchase ? 
                        <>
                            {translate('resources.invoices.detail_purchase')}
                        </> :
                        <>
                            {translate('resources.invoices.detail')}
                        </>
                    }
                </Typography>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <SimpleForm
                className={classes.form}
                basePath={controllerProps.basePath}
                record={controllerProps.record}
                save={controllerProps.save}
                version={controllerProps.version}
                redirect="list"
                resource="invoices"
                toolbar={<InvoiceEditToolbar permissions={permissions} changed={changed}/>}
            >
                <DateField source="createdAt" />
                {
                    permissions.role.type === 'validator' || permissions.role.type === 'manager' ? 
                    <SelectInput
                        source="status"
                        label="resources.invoices.fields.status"
                        fullWidth
                        choices={
                            ["pending","accepted","rejected","accounted","delivery_note_pending","rejectadmin","payed"].map((type, index) => {
                                return {key: type, id: type, type: type,
                                    name: translate(`pos.invoice_status.${type}`)}
                            })
                        }
                        onChange={e => updateInvoice(e.target.value, controllerProps.record.id)}
                    /> : <InvoiceStatusField source="status" />
                }
                {
                    (permissions.role.type === 'manager' || permissions.role.type === 'validator') && controllerProps.record.purchase ?
                      <PurchaseReferenceField />
                    : <></>
                }
                <Separator />
                {
                    (controllerProps.record.status === 'pending' || controllerProps.record.status === 'delivery_note_pending') && (permissions.role.type === 'validator' || permissions.role.type === 'manager') ?
                        <>
                        <ReferenceInput
                            source="organization._id"
                            reference="organizations"
                            label="resources.organizations.fields.organization"
                            formClassName={classes.inlineField}
                            fullWidth
                        >
                            <AutocompleteInput
                                optionText={choice =>
                                    choice && choice.id ? `${choice.name}` : ""
                                }
                            />
                        </ReferenceInput>
                        <ReferenceInput
                            source="user._id"
                            reference="users"
                            label="resources.users.fields.user"
                            filterToQuery={ () => {
                                return ({ invoiceValidator: true });
                              } }
                            perPage={1000}
                            formClassName={classes.inlineField}
                            onChange={(e) => {setChanged(true)}}
                            fullWidth
                        >
                            <AutocompleteInput
                                optionText={choice =>
                                    choice && choice.id ? `${choice.name}` : ""
                                }
                            />
                        </ReferenceInput>
                        </>
                    : <></>
                }
                {
                    permissions.role.type === 'manager' || permissions.role.type === 'validator' ?
                      <SupplierReferenceField link={true} />
                    : <></>
                }
                {
                    (controllerProps.record.status === 'accepted' || controllerProps.record.status === 'accounted') && permissions.role.type === 'manager' ?
                        <DateField source="validationDate" formClassName={classes.inlineField} />
                    : <></>
                }
                {
                    (controllerProps.record.status === 'accepted' || controllerProps.record.status === 'accounted') && permissions.role.type === 'manager' ?
                        <UserReferenceField source="validator._id"
                            label={translate('resources.invoices.fields.validator')}
                            link={false}
                        />
                    : <></>
                }
                {
                    controllerProps.record.status === 'accounted' ?
                        <DateField source="accountDate"/>
                    : <></>
                }
                {
                    controllerProps.record.status === 'accounted' && permissions.role.type === 'manager' ?
                        <UserReferenceField source="accounter._id"
                            label={translate('resources.invoices.fields.accounter')}
                            link={false}
                        />
                    : <></>
                }
                {
                  controllerProps.record.budget ?
                      <BudgetReferenceField link={false} /> :
                          controllerProps.record.contract ?
                              <ContractReferenceField formClassName={classes.inlineField} link={true} /> :
                              controllerProps.record.order ? <OrderReferenceField formClassName={classes.inlineField} link={false} /> :
                      <></>
                }
                {
                    permissions.role.type === 'manager' || permissions.role.type === 'validator' ?
                        controllerProps.record.order || controllerProps.record.type === 'orderless' ?
                            <TextInput source="deliveryNote" fullWidth />
                        : <></>
                    : <></>
                }
                {
                    permissions.role.type === 'supplier' ?
                        controllerProps.record.deliveryNote ?
                            <TextField source="deliveryNote" formClassName={classes.inlineField} fullWidth />
                        : <></>
                    : <></>
                }
                {
                    controllerProps.record.status === 'pending' || controllerProps.record.status === 'delivery_note_pending'
                    ? <NumberInput source="amount" onChange={(e) => setChanged(true)} fullWidth />
                    : <TextField source="amount" formClassName={classes.inlineField} fullWidth />
                }
                {
                    controllerProps.record.status === 'pending' || controllerProps.record.status === 'delivery_note_pending'
                    ? <DateInput source="date" onChange={(e) => setChanged(true)} fullWidth />
                    : <DateField source="date" fullWidth />
                }
                {
                    controllerProps.record.status === 'pending' || controllerProps.record.status === 'delivery_note_pending'
                    ? <TextInput source="number" onChange={(e) => setChanged(true)} fullWidth />
                    : <TextField source="number" formClassName={classes.inlineField} fullWidth />
                }
                {
                    controllerProps.record.ot && (controllerProps.record.status === 'pending' || controllerProps.record.status === 'delivery_note_pending')
                    ? <TextInput source="ot" onChange={(e) => setChanged(true)} fullWidth />
                    : <TextField source="ot" formClassName={classes.inlineField} fullWidth />
                }
                <></>
                {
                    permissions.role.type === 'manager' || permissions.role.type === 'validator' ?
                        controllerProps.record.budget ?
                            <OtReferenceField link={false} /> :
                                controllerProps.record.contract ?
                                    <ItemReferenceField link={false} />
                                : <></>
                    : <></>
                }
                {
                    permissions.role.type === 'manager' || permissions.role.type === 'validator' ?
                    <>
                        {/*<NumberInput
                          source="rating"
                          label={translate('resources.invoices.fields.rating')}
                          inputProps={{
                             step: 1,
                             min: 1,
                             max: 5
                           }}
                        />*/}
                        <TextInput
                            source="comment"
                            label={translate('resources.invoices.fields.comment')}
                            maxRows={25}
                            multiline
                            fullWidth
                        />
                    </>
                    : <></>
                }
                {
                    permissions.role.type === 'supplier' && (controllerProps.record.status === 'rejected' || controllerProps.record.status === 'rejectadmin') ?
                        <TextField source="comment" formClassName={classes.inlineField} />
                    : <></>
                }
                {
                    controllerProps.record.status === 'accepted'
                        ?
                            <BooleanInput label={translate('resources.invoices.fields.notifySupplier')} source="notifySupplier" />
                        :
                            <></>

                }
                {
                    controllerProps.record.status === 'payed' && controllerProps.record.paymentBank
                        ?
                            <TextField source="paymentBank" formClassName={classes.inlineField} fullWidth />
                        :
                            <></>

                }
                <CustomImageField source="images" />
            </SimpleForm>
        </div>
    );
};

export default InvoiceEdit;
