import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const ItemReferenceField = props => (
    <ReferenceField
        label="Budget"
        source="contract.id"
        reference="contracts"
        {...props}
    >
        <TextField source="item" />
    </ReferenceField>
);

ItemReferenceField.defaultProps = {
    source: 'contract.id',
    label: 'resources.contracts.fields.item',
    addLabel: true,
};

export default ItemReferenceField;
