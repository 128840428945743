import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';

const IssueStatusField = ({ source, record = {} }) => {
    const translate = useTranslate();
    
    const statusIcon = (status) => {
        switch (status) {
            case 'new':
                return '🆕';
            case 'in_progress':
                return '👷';
            case 'resolved':
                return '✅';
            default:
                break;
        }
    }
    
    return (
        <span>{ translate(`resources.issues.status.${record[source]}`) } { statusIcon(record.status) }</span>
      );
};

IssueStatusField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default IssueStatusField;
