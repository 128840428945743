import React, { useState } from 'react';
import axios from 'axios';
import {
    Create,
    TextInput,
    SelectInput,
    SimpleForm,
    FileInput,
    FileField,
    useTranslate,
    FormDataConsumer,
    Toolbar
} from 'react-admin';

import SaveAndUploadButton from './SaveAndUploadButton';
import URL from '../URL';

const CustomToolbar = props => (
    <Toolbar {...props} >
        <SaveAndUploadButton redirect="invoices" />
    </Toolbar>
);

const IssueCreate = ({permissions, ...props}) => {
    const translate = useTranslate();
    const [organizations, setOrganizations] = useState();

    const categories = [
        { id: 'Order', name: translate('resources.issues.categories.Order') },
        { id: 'InvoiceRejected', name: translate('resources.issues.categories.InvoiceRejected') },
        { id: 'PaymetNotReceived', name: translate('resources.issues.categories.PaymetNotReceived') },
        { id: 'Technical', name: translate('resources.issues.categories.Technical') },
        { id: 'Other', name: translate('resources.issues.categories.Other') },
    ];

    const validateIssue = values => {
        const errors = {};     
        return errors;
    };

    const fetchOrganizations = async () => {
        setOrganizations([]);
        // Request organizations
        const response = await axios.get(`${URL}/organizations/`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
          });
          const _organizations = response.data.map(o => {
            return {
                    key: o.id, id: o.id, name: `${o.name} - ${o.office}`,
                    nameNumber: o.nameNumber, officeNumber: o.officeNumber
                };
            });
          setOrganizations(_organizations);
          return _organizations;
    };

    if (organizations === undefined) {
        fetchOrganizations();
    };

    return <Create {...props}>
        <SimpleForm
            toolbar={<CustomToolbar />}
            validate={validateIssue}
        >
            <SelectInput
                label="resources.organizations.fields.organization"
                source="organization._id"
                optionText="name"
                choices={organizations || []}
                fullWidth
            />
            <SelectInput
                label="resources.issues.fields.category"
                source="category"
                choices={categories}
                fullWidth
            />
            <TextInput source="description" fullWidth />
            <FileInput
                source="attachments"
                accept="application/pdf, image/*"
                multiple
            >
                <FileField source="attachments" title="title" />
            </FileInput>
            <FormDataConsumer>
                {
                    ({ formData, ...rest }) => {
                        if (formData.attachment)
                            return <i>{translate('pos.upload_remove_help')}</i>
                        else return <></>

                    }
                }
            </FormDataConsumer>
        </SimpleForm>
    </Create>

};

export default IssueCreate;
