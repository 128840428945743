import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AvatarField from './AvatarField';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
}));

const FullNameField = ({ record = {}, size }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <AvatarField
                className={classes.avatar}
                record={record}
                size={size}
            />
            {
                record.number ? record.number : 
                    record.business ? record.business :
                        record.name || record.description
            }
            {
                record.office ? ` - ${record.office}` :  ''
            }
        </div>
    );
};


FullNameField.defaultProps = {
    source: 'name',
    label: 'resources.suppliers.fields.name',
};

export default FullNameField;
