import React, { useCallback } from 'react';
import axios from 'axios';
import {
    useEditController,
    useTranslate,
    SimpleForm,
    TextField,
    BooleanInput,
    BooleanField,
    useNotify,
    useRefresh
} from 'react-admin';
import {
    Typography,
    IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import AlbaranEditToolbar from './AlbaranEditToolbar';
import CustomImageField from '../invoices/CustomImageField';
import UserReferenceField from '../users/UserReferenceField';
import OrganizationReferenceField from '../organizations/OrganizationReferenceField';

import URL from '../URL';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
    card: {
        margin: '0 0 0em 0em',
        width: '100%',
    },
    cardHeader: {
        alignItems: 'flex-start',
    },
    clamp: {
        // display: '-webkit-box',
        // '-webkit-line-clamp': 3,
        // '-webkit-box-orient': 'vertical',
        // overflow: 'hidden',
    },
}));

const AlbaranEdit = ({ onCancel, permissions, ...props }) => {
    const classes = useStyles();
    const controllerProps = useEditController(props);
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();

    const updateAlbaran = useCallback((checked, id) => {
        // notify('Actualizando', 'info', {}, true);
        axios.put(`${URL}/albaranes/${id}`, { checked }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then(() => {
            refresh();
        }).catch(error => { notify(`Falló la actualización`,); });
    }, [notify, refresh]);

    if (!controllerProps.record) {
        return null;
    }

    const record = controllerProps.record;

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6">
                    {
                        record.checked ? 
                            <>{translate('resources.invoices.detail')}</> :
                            <>{translate('resources.albaranes.notseen')}</>
                    }
                </Typography>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <SimpleForm
                className={classes.form}
                basePath={controllerProps.basePath}
                record={record}
                save={controllerProps.save}
                version={controllerProps.version}
                redirect="list"
                resource="albaranes"
                toolbar={<AlbaranEditToolbar permissions={permissions}/>}
            >
                {
                    permissions && (permissions.purchaseAdmin || permissions.purchaseAdmonObra) ? 
                    <BooleanInput
                        source="checked"
                        onChange={checked => updateAlbaran(checked, controllerProps.record.id)}
                    /> : <></>
                }
                {
                    permissions && !(permissions.purchaseAdmin || permissions.purchaseAdmonObra) && controllerProps.record.checked ? 
                        <BooleanField source="checked" /> : <></>
                }
                <TextField source="ot" />
                <TextField source="description" />
                <UserReferenceField source="user.id"
                    label={translate('resources.budgets.fields.user')}
                    link={permissions && permissions.role.type === 'manager' ? true : false}
                />
                <OrganizationReferenceField source="organization.id"
                    label={translate('resources.organizations.fields.organization')}
                    link={permissions && permissions.role.type === 'manager' ? true : false}
                />
                { record.attachments.length ? <CustomImageField source="attachments" /> : <></> }
            </SimpleForm>
        </div>
    );
};


export default AlbaranEdit;
