import React, { useState, useCallback, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import FullNameField from '../components/FullNameField';

import {
    useTranslate,
    Notification,
    useNotify,
    Title,
    usePermissions,
    useLogout,
    // FileInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import URL from '../URL';

const useStyles = makeStyles(theme => ({
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
    input: { marginTop: '1em' },
    avatar: { marginRight: theme.spacing(1) },
}));

const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        value={inputProps.value}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const renderSwitch = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
    <Switch
        checked={inputProps.value}
        {...inputProps}
        {...props}
    />
);

const ProfileEdit = ({...props}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const logout = useLogout();
    const { permissions } = usePermissions();
    const [loading, setLoading] = useState(true);
    const notify = useNotify();
    const [supplier, setSupplier] = useState();
    const [user, setUser] = useState();
    const [advanced, setAdvanced] = useState(true);

    const handleAdvanced = (event) => {
      setAdvanced(event.target.checked);
    };
    /*
    const upload = async (data) => {
        return new Promise((resolve, reject) => {
            // create an HTML standar Form Data
            const formData = new FormData();
            // append image
            formData.append('files', data.avatar);
            formData.append('ref', "user");
            formData.append('refId', permissions.id);
            // formData.append('source', "users");
            formData.append('field', "avatar");

            // make the request
            axios
                .post(`${URL}/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                })
                .then(response => {
                    notify(
                        'ra.notification.created',
                        'info',
                        {},
                        true
                    );
                    setLoading(false);
                    logout();
                })
                .catch(error => {
                    notify(
                        `pos.upload_failed`,
                        'warning'
                    );
                    setLoading(false);
                    resolve();
                });
        });
    }
    */

    const handleSubmit = data => {
        setLoading(true);
        axios.put(`${URL}/users/${permissions.id}`, {
            name: data.name,
            email: data.email,
            autoTicketMaxAmount: data.autoTicketMaxAmount || 0,
            enableTicketNotifications: data.enableTicketNotifications || false,
            autoCardCompany: data.autoCardCompany || 0,
            autoCardPersonal: data.autoCardPersonal || 0,
            autoCardCepsa: data.autoCardCepsa || 0,
            autoInvoiceHotel: data.autoInvoiceHotel || 0,
            autoCompanyInvoice: data.autoCompanyInvoice || 0,
            autoOther: data.autoOther || 0
        },
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(response => {
            setLoading(false);
            notify(
                'pos.uploading',
                'info',
                {},
                true
            );

            // upload(data)
            //   .then(result => console.log(result))
            //   .catch(error => console.log(error));
            logout();
        })
        .catch(error => {
            setLoading(false);
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                    ? 'ra.auth.sign_in_error'
                    : error.message,
                'warning'
            );
        });
    };

    const validate = values => {
        const errors = {};
        if (!values.name) {
            errors.name = translate('ra.validation.required');
        }
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }
        return errors;
    };

    const handleSubmitSupplier = data => {
        setLoading(true);
        axios.put(`${URL}/suppliers/${supplier.id}`, {
            business: data.business,
            email: data.email,
            taxid: data.taxid,
            bic: data.bic,
            swift: data.swift,
        },
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(response => {
            setLoading(false);
            notify(translate('pos.changed'), 'info');
        })
        .catch(error => {
            setLoading(false);
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                    ? 'ra.auth.sign_in_error'
                    : error.message,
                'warning'
            );
        });
    };

    const validateSupplier = values => {
        const errors = {};
        if (!values.business) {
            errors.business = translate('ra.validation.required');
        }
        if (!values.taxid) {
            errors.taxid = translate('ra.validation.required');
        }
        if (!values.bic) {
            errors.bic = translate('ra.validation.required');
        }
        if (!values.swift) {
            errors.swift = translate('ra.validation.required');
        }
        return errors;
    };

    const fetchSupplier = useCallback((supplierId) => {
        axios.get(`${URL}/suppliers/${supplierId}`, {
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
        }).then( (response) => {
            setSupplier(response.data);
            setLoading(false);
        })
      }, []);

      const fetchMe = useCallback((permissions) => {
        axios.get(`${URL}/users/me`, {
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
        }).then( (response) => {
            setUser(response.data);
            if (!permissions.supplier) {
                setLoading(false);
            }
        })
      }, []);

    useEffect(() => {
        if (permissions && permissions.supplier && !supplier) {
            fetchSupplier(permissions.supplier.id);
        }
        if (permissions && !user) {
            fetchMe(permissions);
        }
    }, [supplier, permissions, fetchSupplier, fetchMe, user]);

    return (
        <>
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            initialValues={{
                name: permissions ? permissions.name : '',
                email: permissions ? permissions.email : '',
                autoTicketMaxAmount: permissions ? permissions.autoTicketMaxAmount : 0,
                enableTicketNotifications: permissions ? permissions.enableTicketNotifications : false,
                autoCardCompany: permissions ? permissions.autoCardCompany : 0,
                autoCardPersonal: permissions ? permissions.autoCardPersonal : 0,
                autoCardCepsa: permissions ? permissions.autoCardCepsa : 0,
                autoInvoiceHotel: permissions ? permissions.autoInvoiceHotel : 0,
                autoCompanyInvoice: permissions ? permissions.autoCompanyInvoice : 0,
                autoOther: permissions ? permissions.autoOther : 0,
                business: supplier ? supplier.business : '',
                taxid: supplier ? supplier.taxid : '',
                bic: supplier ? supplier.bic : '',
                swift: supplier ? supplier.swift : '',
            }}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Title title={permissions ? permissions.name : ''} />
                    <div className={classes.main}>
                        <Card className={classes.card}>

                            <CardContent>
                                <Typography gutterBottom variant="h6" component="p">
                                    {translate('pos.profile')}
                                </Typography>

                                <Typography gutterBottom variant="h5" component="div">
                                    { user ? <FullNameField record={user} /> : <></> }
                                </Typography>
                                <Typography gutterBottom variant="h6" component="p">
                                    {permissions ? translate(`pos.roles.${permissions.role.type}`) : ''}
                                    <small>
                                        { permissions && permissions.supplierValidator ? ` · 👮 ${translate(`resources.users.fields.supplier_validator`)} ·` : ''}
                                        { permissions && permissions.invoiceCreator ? ` 📤 ${translate(`resources.users.fields.invoice_creator`)} ·` : ''}
                                        { permissions && permissions.invoiceValidator ? ` 🧾 ${translate(`resources.users.fields.invoice_validator`)} ·` : ''}
                                        { permissions && permissions.orderValidator ? ` 📝 ${translate(`resources.users.fields.order_validator`)} ·` : ''}
                                        { permissions && permissions.ticketValidator ? ` 🎫 ${translate(`resources.users.fields.ticket_validator`)} ·` : ''}
                                        { permissions && permissions.ticketAdmin ? ` 🔏 ${translate(`resources.users.fields.ticket_admin`)} ·` : ''}
                                        { permissions && permissions.issueManager ? ` 🛰 ${translate(`resources.users.fields.issue_manager`)} ·` : ''}
                                    </small>
                                </Typography>
                                {/*<div className={classes.input}>
                                    <Field
                                        name="avatar"
                                        component={FileInput}
                                        label={translate('resources.users.fields.avatar')}
                                        disabled={loading}
                                    />
                                </div>*/}
                                <div className={classes.input}>
                                    <Field
                                        name="name"
                                        component={renderInput}
                                        label={translate('resources.users.fields.name')}
                                        disabled={permissions ? permissions.role.type !== 'supplier' ? true : loading : true}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        name="email"
                                        component={renderInput}
                                        label={translate('resources.users.fields.email')}
                                        disabled={loading}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <FormControlLabel
                                        control={
                                            <Field
                                                type="checkbox"
                                                name="enableTicketNotifications"
                                                component={renderSwitch}
                                                disabled={loading}
                                            />
                                        }
                                        label={translate('resources.users.fields.enableTicketNotifications')}
                                    />
                                </div>


                                {
                                    permissions && permissions.ticketValidator ?
                                        <div className={classes.input}>
                                            <Typography gutterBottom variant="h5" component="p">
                                                {translate('pos.autoTicketValidation')}
                                            </Typography>
                                            <Typography gutterBottom variant="h6" component="p">
                                                {translate('resources.users.byDefault')}
                                            </Typography>
                                            <Field
                                                name="autoTicketMaxAmount"
                                                component={renderInput}
                                                label={translate('resources.users.fields.autoTicketMaxAmount')}
                                                disabled={loading}
                                            />
                                            <p></p>

                                            <label>{translate('pos.advanced')}</label>

                                            <Switch
                                                name="advanced"
                                                checked={advanced}
                                                onChange={handleAdvanced}
                                            />
                                            {
                                              advanced ?
                                              <>
                                                  <Typography gutterBottom variant="h6" component="p">
                                                      {translate('resources.users.byPaymentMethod')}
                                                  </Typography>
                                                  <Field
                                                      name="autoCardCompany"
                                                      component={renderInput}
                                                      label={translate('resources.users.fields.autoCardCompany')}
                                                      disabled={loading}
                                                  />
                                                  <Field
                                                      name="autoCardPersonal"
                                                      component={renderInput}
                                                      label={translate('resources.users.fields.autoCardPersonal')}
                                                      disabled={loading}
                                                  />
                                                  <Field
                                                      name="autoCardCepsa"
                                                      component={renderInput}
                                                      label={translate('resources.users.fields.autoCardCepsa')}
                                                      disabled={loading}
                                                  />
                                                  <Field
                                                      name="autoInvoiceHotel"
                                                      component={renderInput}
                                                      label={translate('resources.users.fields.autoInvoiceHotel')}
                                                      disabled={loading}
                                                  />
                                                  <p></p>
                                                  <Typography gutterBottom variant="h6" component="p">
                                                      {translate('resources.users.byExpenseType')}
                                                  </Typography>
                                                  <Field
                                                      name="autoCompanyInvoice"
                                                      component={renderInput}
                                                      label={translate('resources.users.fields.autoCompanyInvoice')}
                                                      disabled={loading}
                                                  />
                                                  <Field
                                                      name="autoOther"
                                                      component={renderInput}
                                                      label={translate('resources.users.fields.autoOther')}
                                                      disabled={loading}
                                                  />
                                              </>
                                              : <></>
                                            }
                                        </div> : <></>
                                }
                            </CardContent>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    className={classes.button}
                                >
                                    {loading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    {translate('pos.save')}
                                </Button>
                            </CardActions>
                        </Card>
                        <Notification />
                    </div>
                </form>
            )}
        />
        {
            supplier ?
                <Form
                onSubmit={handleSubmitSupplier}
                validate={validateSupplier}
                initialValues={{
                    business: supplier ? supplier.business : '',
                    email: supplier ? supplier.email : '',
                    taxid: supplier ? supplier.taxid : '',
                    bic: supplier ? supplier.bic : '',
                    swift: supplier ? supplier.swift : '',
                }}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Title title={permissions ? permissions.name : ''} />
                        <div className={classes.main}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {translate('pos.supplierProfile')}
                                    </Typography>
                                    <div className={classes.input}>
                                        <Field
                                            name="business"
                                            component={renderInput}
                                            label={translate('resources.suppliers.fields.business')}
                                            disabled={loading}
                                        />
                                    </div>
                                    <div className={classes.input}>
                                        <Field
                                            name="email"
                                            component={renderInput}
                                            label={translate('resources.suppliers.fields.email')}
                                            disabled={loading}
                                        />
                                    </div>
                                    <div className={classes.input}>
                                        <Field
                                            name="taxid"
                                            component={renderInput}
                                            label={translate('resources.suppliers.fields.taxid')}
                                            disabled={loading}
                                        />
                                    </div>
                                    <div className={classes.input}>
                                        <Field
                                            name="bic"
                                            component={renderInput}
                                            label={translate('resources.suppliers.fields.bic')}
                                            disabled={loading}
                                        />
                                    </div>
                                    <div className={classes.input}>
                                        <Field
                                            name="swift"
                                            component={renderInput}
                                            label={translate('resources.suppliers.fields.swift')}
                                            disabled={loading}
                                        />
                                    </div>

                                    <Typography variant="body1" component="p">
                                        <small>{translate('resources.suppliers.fields.payment')}</small>
                                        <br />
                                        {
                                            supplier.payment ?
                                                <>
                                                    {
                                                        `${translate(`resources.suppliers.payments.${supplier.payment}`)}
                                                        ${translate('pos.to')}
                                                        ${supplier.days} ${translate('resources.suppliers.fields.days')}`
                                                    }
                                                </>
                                            : <>{translate('pos.nopayment')}</>
                                        }
                                    </Typography>
                                </CardContent>

                                <CardActions className={classes.actions}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disabled={loading}
                                        className={classes.button}
                                    >
                                        {loading && (
                                            <CircularProgress
                                                size={25}
                                                thickness={2}
                                            />
                                        )}
                                        {translate('pos.save')}
                                    </Button>
                                </CardActions>
                            </Card>
                            <Notification />
                        </div>
                    </form>
                )}
            />
            : <></>
        }
        </>
    );
};

export default ProfileEdit;
