import React from 'react';
import {
    DateInput,
    Filter,
    SelectInput,
    useTranslate
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useFilterStyles = makeStyles({
    status: { width: 150 },
});

const ComplaintFilter = props => {
    const classes = useFilterStyles();
    const translate = useTranslate();

    return (
        <Filter {...props}>
            {/* <SearchInput source="q" alwaysOn /> */}
            <SelectInput
                source="status"
                choices={[
                    { id: 'new', name: translate("resources.complaints.status.new") },
                    { id: 'in_progress', name: translate("resources.complaints.status.in_progress") },
                    { id: 'resolved', name: translate("resources.complaints.status.resolved") },
                ]}
                className={classes.status}
            />
            <SelectInput
                source="category"
                choices={[
                    { id: 'conducta', name: translate("resources.complaints.categories.conducta") },
                    { id: 'gestion', name: translate("resources.complaints.categories.gestion") },
                    { id: 'robo', name: translate("resources.complaints.categories.robo") },
                    { id: 'acoso', name: translate("resources.complaints.categories.acoso") },
                    { id: 'trato', name: translate("resources.complaints.categories.trato") },
                    { id: 'informacion', name: translate("resources.complaints.categories.informacion") },
                    { id: 'fraude', name: translate("resources.complaints.categories.fraude") },
                    { id: 'other', name: translate("resources.complaints.categories.other") },
                ]}
                className={classes.status}
            />
            <DateInput source="date_gte" />
            <DateInput source="date_lte" />
        </Filter>
    );
};

export default ComplaintFilter;
