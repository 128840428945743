import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'react-admin';

import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles({
    root: {
        width: '100vw',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    pending: {
        color: orange[500]
    },
    accepted: {
        color: grey[500]
    },
    rejected: {
        color: red[500]
    },
    payroll: {
        color: blue[500]
    },
    telematel: {
        color: grey[500]
    },
    paid: {
        color: blue[500]
    },
    inline: {
        display: 'inline',
    },
});

const TicketMobileList = ({ basePath, data, ids, loading, total, permissions }) => {
    const classes = useStyles();    

    return (
        (loading || total > 0) && (
            <List className={classes.root}>
                {ids.map(id => (
                    <Link
                        to={linkToRecord(basePath, id)}
                        className={classes.link}
                        key={id}
                    >
                        <ListItem button>
                            <ListItemAvatar className={classes[`${data[id].status}`]}>
                                <Brightness1Icon />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Fragment>
                                        {data[id].employee}
                                    </Fragment>
                                }
                                secondary={`${(new Date(data[id].date)).toLocaleDateString()} - ${data[id].amount} EUR`}
                                secondaryTypographyProps={{ noWrap: true }}
                            />
                        </ListItem>
                    </Link>
                ))}
            </List>
        )
    );
};

TicketMobileList.propTypes = {
    basePath: PropTypes.string,
    data: PropTypes.object,
    hasBulkActions: PropTypes.bool.isRequired,
    ids: PropTypes.array,
    leftAvatar: PropTypes.func,
    leftIcon: PropTypes.func,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
        .isRequired,
    onToggleItem: PropTypes.func,
    primaryText: PropTypes.func,
    rightAvatar: PropTypes.func,
    rightIcon: PropTypes.func,
    secondaryText: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    tertiaryText: PropTypes.func,
};

TicketMobileList.defaultProps = {
    link: 'edit',
    hasBulkActions: false,
    selectedIds: [],
};

export default TicketMobileList;
