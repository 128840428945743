import React, { useState, useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { SaveButton, useRedirect, useNotify, usePermissions } from 'react-admin';
import axios from 'axios';

import URL from '../URL';

const SaveAndUploadButton = props => {
    const [loading, setLoading] = useState(false);
    const redirectTo = useRedirect();
    const notify = useNotify();
    // get the form state
    const formState = useFormState();
    const { permissions } = usePermissions();
    const approvedById = permissions && permissions.id;

    const handleClick = useCallback( async () => {
        setLoading(true);
        if (formState.valid) {
            const role = await new Promise((resolve) => {
                    axios.get(`${URL}/users-permissions/roles/${formState.values.role.id}`, {
                      headers: {
                          'Authorization': `Bearer ${localStorage.getItem('token')}`
                      },
                    })
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        resolve();
                    });
            });

            if (role.role.type === 'supplier') {
                // create a supplier profile
                await new Promise((resolve) => {
                    axios.post(`${URL}/suppliers`, {
                          organization: formState.values.organization,
                          approvedBy: approvedById,
                          email: formState.values.email,
                          name: formState.values.name,
                          bcUserId: formState.values.bcUserId || "",
                          phone: formState.values.phone || "",
                          business: formState.values.business || "",
                          taxid: formState.values.taxid || "",
                          address: formState.values.address || "",
                          city: formState.values.city.toUpperCase() || "",
                          province: formState.values.province ? formState.values.province.toUpperCase() : "",
                          zipcode: formState.values.zipcode || "",
                          country: formState.values.country.toUpperCase() || "",
                          type: formState.values.type,
                          employee: formState.values.employee || "",
                          payment: formState.values.payment || "transfer",
                          days: formState.values.days || "0",
                          bic: formState.values.bic || "ESXX",
                          swift: formState.values.swift || "",
                          terms: true,
                          status: "payment_confirm",
                      },
                      {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        },
                      })
                      .then(response => {
                        notify(
                            `resources.users.notification.supplier_created_success`,
                            'info',
                            {},
                            true
                        );
                        resolve(response.data);
                      })
                      .catch(error => {
                        notify(
                            `resources.users.notification.supplier_created_error`,
                            'warning'
                        );
                        resolve();
                      });
                });
                setLoading(false);
                redirectTo('/suppliers');
            } else {
                const user = await new Promise((resolve) => {
                    const password = Math.random().toString(36).slice(-8);
                    axios
                        .post(`${URL}/auth/local/register`, {
                            password,
                            username: formState.values.email,
                            name: formState.values.name,
                            email: formState.values.email,
                            organization: formState.values.organization,
                            taxid: formState.values.taxid,
                            ticketValidatorUserId: formState.values.ticketValidatorUserId,
                            invoiceValidator: formState.values.invoiceValidator,
                            invoicePayer: formState.values.invoicePayer,
                            orderValidator: formState.values.orderValidator,
                            ticketValidator: formState.values.ticketValidator,
                            ticketPayer: formState.values.ticketPayer,
                            ticketRegistrar: formState.values.ticketRegistrar,
                            ticketAdmin: formState.values.ticketAdmin,
                            supplierValidator: formState.values.supplierValidator,
                            issueManager: formState.values.issueManager,
                            invoiceCreator: formState.values.invoiceCreator,
                            complaintManager: formState.values.complaintManager
                        })
                        .then(response => {
                            notify(
                                `resources.users.notification.created_success`,
                                'info',
                                {},
                                true
                            );
                            resolve(response.data);
                        })
                        .catch(error => {
                            notify(
                                `resources.users.notification.created_error`,
                                'warning'
                            );
                            resolve();
                        });
                });
    
                if (user) {
                    return new Promise((resolve) => {
                        const query = {
                            role: role.role.id,
                        };
                        axios
                            .put(`${URL}/users/${user.user.id}`, query,
                            {
                                headers: {
                                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                                },
                            })
                            .then(response => {
                                notify(
                                    `resources.users.notification.role_created_success`,
                                    'info',
                                    {},
                                    true
                                );
                                setLoading(false);
                                redirectTo('/users');
                            })
                            .catch(error => {
                                notify(
                                    `resources.users.notification.role_created_error`,
                                    'warning'
                                );
                                setLoading(false);
                                resolve();
                            });
                    });
                }
            }
        }
    }, [
        formState,
        notify,
        redirectTo,
        approvedById
    ]);

    return <SaveButton {...props} saving={loading} handleSubmitWithRedirect={handleClick} />;
};


export default SaveAndUploadButton;
