import React, { useState, useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { SaveButton, useCreate, useRedirect, useNotify } from 'react-admin';
import axios from 'axios';

import URL from '../URL';

const SaveAndUploadButton = props => {
    const [loading, setLoading] = useState(false);
    const redirectTo = useRedirect();
    const notify = useNotify();
    // get the form state
    const formState = useFormState();

    const upload = async (recordId) => {
        return new Promise((resolve, reject) => {
            // create an HTML standar Form Data
            const formData = new FormData();
            // append images
            Array.from(formState.values.files).forEach(file => {
                formData.append('files', file.rawFile);
            });
            formData.append('ref', "contract");
            formData.append('refId', recordId);
            // formData.append('source', "contracts");
            formData.append('field', "files");

            // make the request
            axios
                .post(`${URL}/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                })
                .then(response => {
                    notify(
                        'ra.notification.created',
                        'info',
                        {},
                        true
                    );
                    setLoading(false);
                })
                .catch(error => {
                    notify(
                        `pos.upload_failed`,
                        'warning'
                    );
                    setLoading(false);
                    resolve();
                });
        });
    }

    const [ create ] = useCreate(
      'contracts',
      { ...formState.values },
      {
          undoable: false,
          onSuccess: ({ data }) => {
              if (!formState.values.files) {
                // files are not required
                setLoading(false);
                redirectTo(`/contracts/${data.id}`);
              } else {
                notify(
                    'pos.uploading',
                    'info',
                    {},
                    true
                );
                upload(data.id);
              }
          },
          onFailure: () => {
              notify(
                  'resources.contracts.notification.created_error',
                  'warning'
              );
          },
      }
    );

    const handleClick = useCallback( () => {
        if (formState.valid) {
            setLoading(true);
            create();
        }
    }, [
        formState.valid,
        create,
    ]);

    return <SaveButton {...props} saving={loading} handleSubmitWithRedirect={handleClick} />;
};


export default SaveAndUploadButton;
